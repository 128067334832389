import FlexiDialog from "../FlexiDialog/index.js";
import React, { useState } from "react";
import FlexiForm, { makeExternalFormSubmit, Fields } from "../FlexiForm";
import Button from "@material-ui/core/Button";

const formId = "FlexiForm-UploadDialog-FileUploadField";
const submitForm = makeExternalFormSubmit(formId);

const UploadDialog = ({
  open,
  title,
  onClose,
  onSubmit,
  files,
  onError,
  children,
  onDeleteFile,
  getDeleteUrl,
  getDownloadUrl,
  dialogPlaceholderText,
  dialogPlaceholderImage,
  immediateUpload
}) => {
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);

  return (
    <FlexiDialog
      size={"small"}
      open={open}
      onClose={onClose}
      title={title}
      dialogActions={
        <Button onClick={submitForm} disabled={uploadButtonDisabled}>
          {immediateUpload ? "Mentés" : "Kiválasztás"}
        </Button>
      }
    >
      {children}
      <FlexiForm
        id={formId}
        FinalFormFormProps={{
          onSubmit: (...args) => {
            onClose();
            return onSubmit(...args);
          },
          initialValues: {
            files
          }
        }}
        render={({ values }) => {
          if (values.files && values.files.length) {
            const hasFile = values.files.find(item => item instanceof File);
            setUploadButtonDisabled(!hasFile);
          }
          return (
            <Fields
              config={[
                [
                  {
                    fieldType: "fileUpload",
                    name: "files",
                    uploadButtonText: "Fájl hozzáadása",
                    immediateUpload: false,
                    onDeleteFile,
                    onError,
                    getDeleteUrl,
                    getDownloadUrl,
                    placeholderImage: dialogPlaceholderImage,
                    placeholderText: dialogPlaceholderText
                  }
                ]
              ]}
            />
          );
        }}
      />
    </FlexiDialog>
  );
};

export default UploadDialog;
