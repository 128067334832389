import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

const makeLongTextColumnDefinitionConfig = ({ lines, tooltip }) => ({
  cellClass: ({ value }) =>
    value !== "-" && "cell-wrap-text multi-line left-cell",
  cellStyle: {
    display: "flex !important"
  },
  cellRendererFramework: ({ value }) => (
    <Tooltip title={tooltip === true && value !== "-" ? value : ""}>
      <span
        style={{
          display: "-webkit-box",
          "-webkit-line-clamp": lines.toString(),
          "-webkit-box-orient": "vertical",
          overflow: "hidden"
        }}
      >
        {value}
      </span>
    </Tooltip>
  )
});

export default makeLongTextColumnDefinitionConfig;
