import React, { useEffect, useState } from 'react';
import { Dialog, Box } from "@material-ui/core";
import FlexiTypography from "../FlexiTypography";
import styled from "styled-components";

export const Loader = styled(props => (
    <div {...props}>
        <div id="download-preloader">
            <div id="download-loader" />
        </div>
    </div>
))`
  && {
    transform: scale(-0.5);
    height: 100px;
  }

  #download-preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  #download-loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #0045de;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  #download-loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #0045de;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }
  #download-loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #0045de;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

let timeoutId;
const LoadingDialog = ({ loading }) => {
    const [open, setOpen] = useState(true);

    useEffect(
        () => {
            if (loading === false) {
                timeoutId = setTimeout(() => {
                    setOpen(false);
                }, 500);
            } else {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                    timeoutId = null;
                }
                setOpen(true)
            }
        },
        [loading]
    );

    return (
        <Dialog open={open}>
            <Box p={2} style={{ minWidth: 300, minHeight: 180 }}>
                <Loader />
                <FlexiTypography align={"center"} variant="h5">
                    Az oldal töltődik!
                </FlexiTypography>
                <FlexiTypography align={"center"} variant="body2">
                    Kis türelmet kérünk!
                </FlexiTypography>
            </Box>
        </Dialog>
    );
};

export default LoadingDialog;