import {createBreakpoint, createMap} from 'styled-components-breakpoint';

const breakpoints = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xlg: 1441,
    xl: 1920,
};

export const breakpoint = createBreakpoint(breakpoints);
export const map = createMap(breakpoints);