import React from "react";

import {
  FormControl,
  FormControlLabel,
  Switch as MuiSwitch,
} from "@material-ui/core";
import { Field, useField } from "react-final-form";
import FormHelperText from "@material-ui/core/FormHelperText";
import FlexiTypography from "../../FlexiTypography/index.js";

function ErrorMessage({ showError, meta, formHelperTextProps, helperText }) {
  if (showError) {
    return (
      <FormHelperText {...formHelperTextProps}>
        {meta.error || meta.submitError}
      </FormHelperText>
    );
  } else if (helperText) {
    return (
      <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>
    );
  } else {
    return <></>;
  }
}

const showErrorOnChange = ({
  meta: { submitError, dirtySinceLastSubmit, error, touched, modified },
}) =>
  !!(
    ((submitError && !dirtySinceLastSubmit) || error) &&
    (touched || modified)
  );

const useFieldForErrors = (name) => useField(name);

export function SwitchField(props) {
  const {
    disabled,
    icon,
    name,
    data,
    value,
    description,
    label,
    required,
    helperText,
    fieldProps,
    formControlProps,
    formGroupProps,
    formLabelProps,
    formControlLabelProps,
    formHelperTextProps,
    showError = showErrorOnChange,
    style,
    ...restSwitches
  } = props;

  const field = useFieldForErrors(name);
  const isError = showError(field);

  return (
    <FormControl
      required={required}
      error={isError}
      style={style}
      {...formControlProps}
    >
      <FormControlLabel
        label={label}
        name={name}
        value={value}
        disabled={disabled}
        control={
          <div>
            <Field
              type="checkbox"
              name={name}
              render={({
                input: { name, value, onChange, checked, ...restInput },
              }) => (
                <MuiSwitch
                  name={name}
                  value={Boolean(parseInt(value, 10))}
                  onChange={(e) => {
                    console.log(e.target.checked)
                    onChange(e.target.checked ? "1" : "0")
                  }}
                  checked={value === "1"}
                  disabled={disabled}
                  required={required}
                  inputProps={{ required, ...restInput }}
                  {...restSwitches}
                  icon={icon}
                  size={"medium"}
                />
              )}
              {...fieldProps}
            />
          </div>
        }
        {...formControlLabelProps}
      />
      <FlexiTypography style={{ paddingLeft: "46px" }}>
        {description}
      </FlexiTypography>
      <ErrorMessage
        showError={isError}
        meta={field.meta}
        formHelperTextProps={formHelperTextProps}
        helperText={helperText}
      />
    </FormControl>
  );
}
