/**
 * @render react
 * @name FlexiDocViewer
 * @description kgutfutfzt
 * @example
 *<FlexiDocViewer imgURL={"http://placehold.it/250x320"} documentURL={"https://flexibill.hu"} documentName={"filename.pdf"}/>
 */

import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import FlexiTypography from "../FlexiTypography/index.js";
import Grid from "@material-ui/core/Grid";
import { breakpoint } from "../utils/breakpoints";

const TextWrap = styled.div`
  && {
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    div {
      padding: 10px;
      display: block;
      width: 220px;
    }
  }
`;

const ImgWrap = styled.div`
  background-color: #ffffff;
  width: 100%;
  border-bottom: 1px solid #bebebe;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  ${breakpoint("xs", "sm")`
      height: 100%;
      border-bottom: none;
    `};
  img {
    width: 100%;
    max-width: 230px;
    object-fit: contain;
    ${breakpoint("xs", "sm")`
        width: 100%;
        height: 48px;
        max-width: 230px;
        object-fit: contain;
        display: block;
    `};
  }
`;

const FlexiDocViewer = props => {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Paper style={{ lineHeight: "1" }}>
        <Grid
          container
          justify={"space-between"}
          alignItems={"center"}
          flex-wrap={"noWrap"}
        >
          <Grid item xs={2} sm={12}>
            <ImgWrap>
              <img src={props.imgURL} />
            </ImgWrap>
          </Grid>
          <Grid item xs={10} sm={12}>
            <Grid container>
              <Grid item xs={9} sm={6} md={8}>
                <TextWrap>
                  <div>
                    <FlexiTypography noWrap>
                      {props.documentName}
                    </FlexiTypography>
                  </div>
                </TextWrap>
              </Grid>
              <Grid
                item
                xs={3}
                sm={6}
                md={4}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <IconButton onClick={props.onDownloadClick}>
                  <GetAppIcon fontSize={"small"} />
                </IconButton>
                <IconButton onClick={props.onShowClick}>
                  <VisibilityIcon fontSize={"small"} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

FlexiDocViewer.propTypes = {
  imgURL: PropTypes.string,
  documentName: PropTypes.string,
  onDownloadClick: PropTypes.func,
  onShowClick: PropTypes.func
};

export default FlexiDocViewer;
