import { Checkbox, Radio } from "final-form-material-ui";
import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BaseField from "./BaseField";
import withCheckboxErrorMessage from "../withCheckboxErrorMessage";
import { withStyles } from "@material-ui/core";
import FlexiTypography from "../../FlexiTypography/index.js";

const blueRadioStyles = {
  root: {
    span: {
      fontSize: "12px"
    }
  },
  checked: {
    "&$checked": {
      color: "#0045de"
    }
  },
  disabled: {
    "&$disabled": {
      color: "#cccccc"
    }
  }
};

const BlueRadio = withCheckboxErrorMessage(withStyles(blueRadioStyles)(Radio));

const RadioField = ({ label, name, value, disabled }) => {
  return (
    <FormControlLabel
      control={
        <BaseField
          type="radio"
          name={name}
          value={value}
          component={BlueRadio}
          disabled={disabled}
        />
      }
      label={<FlexiTypography>{label}</FlexiTypography>}
    />
  );
};

export default RadioField;
