import { defineMessages } from "react-intl.macro";
import React, { useCallback, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { InvoiceStatusIcon } from "../InvoicePage/InvoiceStatusIcons";
import {
  useInvoiceGroup,
  useSignFactoringNotification
} from "../../hooks/useApi";
import FactorNotificationViewer from "../../components/SignFactoringContractDialog/FactorNotificationViewer";
import FlexiFileUploadWithPreview from "../../components/FlexiFileUploadWithPreview";
import { useIntl } from "react-intl";
import usePopupState from "../../hooks/usePopupState";
import Box from "@material-ui/core/Box";
import {
  useErrorOccurredNotification,
  useSaveSuccessNotification
} from "../../hooks/useNotificationState";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import FlexiPageLayout from "../../FlexiComponents/FlexiPageLayout";
import { withRouter } from "react-router-dom";
import FlexiLinearProgress from "../../components/FlexiLinearProgress";
import SignHelperBox from "./SignHelperBox";
import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import imageSrc from "../../images/smile.svg";
import Button from "@material-ui/core/Button";
import useRedirect from "../../hooks/useRedirect";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FlexiLink from "../../FlexiComponents/FlexiLink";
import SaveIcon from "@material-ui/icons/Save";
import PublishIcon from "@material-ui/icons/Publish";
import { isWidthDown } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import LoadingDialog from "../../FlexiComponents/FlexiLoader";
import makeStyles from "@material-ui/core/styles/makeStyles";
import closedSvg from "../../images/redesignImages/Rejected.svg";

const messages = defineMessages({
  pageTitle: {
    id: "pageTitle.factorNotificationPage",
    defaultMessage: "Faktorálási értesítők"
  },
  uploadFactorNotificationDialogTitle: {
    id: "title.uploadFactorNotificationDialogTitle",
    defaultMessage: "Faktorálási értesítő feltöltése"
  },
  goToDigitalSignDialogTitle: {
    id: "label.goToDigitalSignDialogTitle",
    defaultMessage: "Faktorálási értesítő aláírása"
  },
  goToDigitalSignDialogDescription: {
    id: "text.goToDigitalSignDialogTitle",
    defaultMessage:
      "A dokumentumot hitelesítheted a Digitális aláírás funkciónk segítségével. Így időt spórolhatsz meg, hiszen nem kell a kinyomtatott és aláírt formátumot feltöltened, mégis ugyanúgy hiteles, mintha kézzel írtad volna alá!"
  },
  goToDigitalSignButtonTitle: {
    id: "label.goToDigitalSignButtonTitle",
    defaultMessage: "TOVÁBB AZ ALÁÍRÁSRA"
  },
  successSignDialogTitle: {
    id: "label.successSignDialogTitle",
    defaultMessage: "Sikeres aláírás!"
  },
  successSignDialogDescription: {
    id: "text.successSignDialogDescription",
    defaultMessage:
      "A dokumentumra rákerült az aláírásod! Ezzel megtetted az utolsó lépést, \n" +
      "ami a faktoráláshoz szükséges! A következő lépéseket bízd munkatársainkra, addig is a számláid státuszát a Számla listában nyomon követheted!"
  },
  successSignDialogButtonText: {
    id: "label.successSignDialogButtonText",
    defaultMessage: "RENDBEN"
  },
  toInvoicesButtonText: {
    id: "toInvoicesButtonText",
    defaultMessage: "Számlák megtekintése"
  }
});

const useStyles = makeStyles({
  green: {
    background: "#8BC34A !important",
    "&:hover": { background: "#6a923c !important" }
  }
});

export const Header = withWidth()(
  ({ name, status, groupId, id, isCustomer, width }) => {
    const intl = useIntl();
    const classes = useStyles();

    const { data } = useInvoiceGroup(id);
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{ paddingTop: "10px", paddingBottom: "20px" }}
        >
          {isWidthDown("md", width) ? (
            <Paper style={{ padding: "12px 0px" }}>
              <Box ml={2} mr={2}>
                <Grid
                  container
                  direction={"column"}
                  style={{ alignItems: "flex-start" }}
                  spacing={2}
                  justify={"space-between"}
                >
                  {groupId && (
                    <Grid
                      item
                      style={{
                        display: "inline-flex",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        variant={"body1"}
                        style={{
                          fontSize: "12px",
                          color: "#9B9B9B",
                          lineHeight: "15px",
                          paddingRight: "10px"
                        }}
                      >
                        Számlacsoport:&nbsp;
                      </Typography>
                      <Typography
                        variant={"h3"}
                        style={{
                          fontSize: "14px",
                          color: "#4a4a4a",
                          lineHeight: "15px"
                        }}
                      >
                        {groupId}
                      </Typography>
                    </Grid>
                  )}
                  {name && (
                    <Grid
                      item
                      style={{
                        display: "inline-flex",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        variant={"body1"}
                        style={{
                          fontSize: "12px",
                          color: "#9B9B9B",
                          lineHeight: "15px",
                          paddingRight: "10px"
                        }}
                      >
                        {isCustomer ? "Szállító" : "Vevő"}:&nbsp;
                      </Typography>
                      <Typography
                        variant={"h3"}
                        style={{
                          fontSize: "14px",
                          color: "#4a4a4a",
                          lineHeight: "15px"
                        }}
                      >
                        {name}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item>
                    <Grid container alignItems={"center"}>
                      <Typography
                        variant={"body1"}
                        style={{
                          fontSize: "12px",
                          color: "#9B9B9B",
                          lineHeight: "15px",
                          paddingRight: "10px"
                        }}
                      >
                        Státusz:&nbsp;
                      </Typography>
                      <InvoiceStatusIcon status={status} chip />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          ) : (
            <Paper style={{ maxHeight: "42px" }}>
              <Box ml={2} mr={2}>
                <Grid
                  container
                  style={{ maxHeight: "42px", alignItems: "center" }}
                  spacing={2}
                  justify={"space-between"}
                >
                  {groupId && (
                    <Grid
                      item
                      style={{
                        display: "inline-flex",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        variant={"body1"}
                        style={{
                          fontSize: "12px",
                          color: "#9B9B9B",
                          lineHeight: "15px",
                          paddingRight: "10px"
                        }}
                      >
                        Számlacsoport:&nbsp;
                      </Typography>
                      <Typography
                        variant={"h3"}
                        style={{
                          fontSize: "14px",
                          color: "#4a4a4a",
                          lineHeight: "15px"
                        }}
                      >
                        {groupId}
                      </Typography>
                    </Grid>
                  )}
                  {name && (
                    <Grid
                      item
                      style={{
                        display: "inline-flex",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        variant={"body1"}
                        style={{
                          fontSize: "12px",
                          color: "#9B9B9B",
                          lineHeight: "15px",
                          paddingRight: "10px"
                        }}
                      >
                        {isCustomer ? "Szállító" : "Vevő"}:&nbsp;
                      </Typography>
                      <Typography
                        variant={"h3"}
                        style={{
                          fontSize: "14px",
                          color: "#4a4a4a",
                          lineHeight: "15px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          width: "400px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {name}
                      </Typography>
                    </Grid>
                  )}
                  {status && !data?.closed && (
                    <Grid item>
                      <Grid container alignItems={"center"}>
                        <Typography
                          variant={"body1"}
                          style={{
                            fontSize: "12px",
                            color: "#9B9B9B",
                            lineHeight: "15px",
                            paddingRight: "10px"
                          }}
                        >
                          Státusz:&nbsp;
                        </Typography>
                        <InvoiceStatusIcon withText status={status} chip />
                      </Grid>
                    </Grid>
                  )}
                  {data?.closed && (
                    <Grid item>
                      <Grid container alignItems={"center"}>
                        <Typography
                          variant={"body1"}
                          style={{
                            fontSize: "12px",
                            color: "#9B9B9B",
                            lineHeight: "15px",
                            paddingRight: "10px"
                          }}
                        >
                          Státusz:&nbsp;
                        </Typography>
                        <img src={closedSvg} />
                        <Typography
                          style={{ paddingLeft: "10px" }}
                          variant={"h6"}
                        >
                          Lezárt
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Paper>
          )}
        </Grid>
        {!isCustomer && (
          <Grid item xs style={{ marginTop: "20px" }}>
            <FlexiLink
              to={`/app/invoice-group/${id}`}
              style={{ textDecoration: "none" }}
            >
              <Button fullWidth={isWidthDown("xs", width)}>
                <VisibilityIcon style={{ paddingRight: "12px" }} />
                {intl.formatMessage(messages.toInvoicesButtonText)}
              </Button>
            </FlexiLink>
          </Grid>
        )}
      </Grid>
    );
  }
);

const GoToDigitalSignDialog = ({ open, onSignDigitallyClick, onClose }) => {
  const intl = useIntl();

  return (
    <FlexiDialog
      title={intl.formatMessage(messages.goToDigitalSignDialogTitle)}
      open={open}
      onClose={onClose}
      dialogActions={
        <Button style={{ margin: "auto" }} onClick={onSignDigitallyClick}>
          {intl.formatMessage(messages.goToDigitalSignButtonTitle)}
        </Button>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <img
            alt={"imageSrc"}
            src={imageSrc}
            style={{ width: "40px", height: "40px" }}
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography>
            {intl.formatMessage(messages.goToDigitalSignDialogDescription)}
          </Typography>
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};

const SuccessDigitalSignDialog = ({ open, onOKButtonClick, onClose }) => {
  const intl = useIntl();

  return (
    <FlexiDialog
      title={intl.formatMessage(messages.successSignDialogTitle)}
      open={open}
      onClose={onClose}
      dialogActions={
        <Button style={{ margin: "auto" }} onClick={onOKButtonClick}>
          {intl.formatMessage(messages.successSignDialogButtonText)}
        </Button>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <img alt={"imageSrc"} src={imageSrc} />
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography>
            {intl.formatMessage(messages.successSignDialogDescription)}
          </Typography>
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};

const urlParams = new URLSearchParams(window.location.search);
const successSign = urlParams.get("successSign");

export const FactorNotificationPageContent = ({
  onBackButtonClick,
  invoiceGroup,
  reloadInvoiceGroup,
  signFactorNotificationOptions = {},
  suppressPaddingTop,
  backButton = true,
  fullWidth,
  type,
  title,
  onSignedUploadSuccess,
  suppressReloadAfterSignedUpload,
  renderExtra,
  onDownloadClick
}) => {
  const isCustomer = type === "customer";
  const signFactorNotification = useSignFactoringNotification(
    invoiceGroup,
    signFactorNotificationOptions
  );

  const intl = useIntl();
  const [uploadDialogShow, showUploadDialog, hideUploadDialog] = usePopupState(
    "FactorNotificationUpload"
  );
  const [
    digitalSignDialogShow,
    showDigitalSignDialog,
    hideDigitalSignDialog
  ] = usePopupState("GoToDigitalSignDialog");
  const [
    successDigitalSignDialogShow,
    showSuccessDigitalSign,
    hideSuccessDigitalSign
  ] = usePopupState("SuccessDigitalSignDialog", Boolean(successSign));
  const notifySuccess = useSaveSuccessNotification();
  const notifyError = useErrorOccurredNotification();

  const signedDocumentBySupplier =
    invoiceGroup?.financeNotification?.documentSignedBySupplier;

  const signedDocumentByCustomer =
    invoiceGroup?.financeNotification?.documentSignedByCustomer;

  const documentGeneratedForSupplier =
    invoiceGroup?.financeNotification.documentGeneratedForSupplier;

  const uploadSignedButton = {
    props: {
      children: "Aláírt fájl feltöltése",
      onClick: showUploadDialog
    },
    mobile: {
      placement: "fab",
      tooltip: "Aláírt fájl feltöltése",
      icon: <PublishIcon />
    }
  };

  // const signDigitallyButton = {
  //   props: {
  //     children: "ALÁÍRÁS",
  //     onClick: showDigitalSignDialog
  //   },
  //   mobile: {
  //     placement: "fab",
  //     tooltip: "Aláírás",
  //     icon: <CreateIcon />
  //   }
  // };

  const uploadEnabled = isCustomer
    ? !signedDocumentByCustomer
    : !signedDocumentBySupplier;

  const rightSideComponents = [
    {
      props: {
        children: "Letöltés",
        onClick: () =>
          onDownloadClick
            ? onDownloadClick(signFactorNotification)
            : signFactorNotification.downloadDocument(
                signedDocumentBySupplier ||
                  documentGeneratedForSupplier ||
                  invoiceGroup?.financeNotification?.document
              )
      },
      mobile: {
        placement: "fab",
        tooltip: "Letöltés",
        icon: <SaveIcon />
      }
    },
    uploadEnabled && uploadSignedButton
    // uploadEnabled && signDigitallyButton
  ].filter(item => item);
  const [redirectEl, redirect] = useRedirect();

  useEffect(() => {
    if (signFactorNotification.error) {
      redirect("/app/invoice");
      notifyError({ text: "Hiba! Nem található faktorértesítő!" });
    }
  }, [signFactorNotification.error]);

  return (
    redirectEl || (
      <div>
        <LoadingDialog loading={signFactorNotification.loading} />
        <FlexiPageLayout
          pay
          title={title || intl.formatMessage(messages.pageTitle)}
          rightSideComponents={rightSideComponents}
          backButton={backButton}
          onBackButtonClick={onBackButtonClick}
          loading={signFactorNotification.loading}
        >
          {signFactorNotification.loading && <FlexiLinearProgress />}
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {invoiceGroup?.id && (
                <Header
                  id={invoiceGroup?.id}
                  groupId={invoiceGroup?.faktoriId}
                  name={
                    !isCustomer
                      ? invoiceGroup?.agreement?.customerName
                      : invoiceGroup?.agreement?.supplierName
                  }
                  status={
                    isCustomer
                      ? invoiceGroup?.statusForCustomer
                      : invoiceGroup?.status
                  }
                  isCustomer={isCustomer}
                />
              )}
              {renderExtra && renderExtra()}
            </Grid>

            <Grid item xs={12} lg={6}>
              {invoiceGroup && (
                <SignHelperBox
                  customer={isCustomer}
                  signed={Boolean(
                    isCustomer
                      ? signedDocumentByCustomer
                      : signedDocumentBySupplier
                  )}
                  onDownloadButtonClick={() =>
                    onDownloadClick
                      ? onDownloadClick(signFactorNotification)
                      : signFactorNotification.downloadDocument(
                          documentGeneratedForSupplier
                        )
                  }
                  onBackToTheListClick={() => redirect("/app/invoice")}
                  type={type}
                />
              )}
            </Grid>
            <Grid item xs={12} lg={6}>
              <FactorNotificationViewer
                originalNotificationBase64Image={
                  signFactorNotification.originalNotificationBase64Image
                }
                signedNotificationDocument={
                  signFactorNotification.signedNotificationDocument
                }
              />
            </Grid>
          </Grid>
          <FlexiDialog
            open={uploadDialogShow}
            title={intl.formatMessage(
              messages.uploadFactorNotificationDialogTitle
            )}
            onClose={hideUploadDialog}
          >
            <Box p={1}>
              <FlexiFileUploadWithPreview
                onUploadButtonClick={async file => {
                  const {
                    data: uploadResponse,
                    error
                  } = await signFactorNotification.uploadSignedDocument(file);

                  if (uploadResponse) {
                    hideUploadDialog();
                    notifySuccess({ text: "Sikeres feltöltés!" });
                    if (onSignedUploadSuccess) {
                      onSignedUploadSuccess();
                    }
                    if (!suppressReloadAfterSignedUpload) {
                      reloadInvoiceGroup();
                    }
                  } else {
                    notifyError(selectErrorNotificationMessage(error));
                  }
                }}
              />
            </Box>
          </FlexiDialog>
          <GoToDigitalSignDialog
            open={digitalSignDialogShow}
            onClose={hideDigitalSignDialog}
            onSignDigitallyClick={async () => {
              const secretLink = isCustomer
                ? invoiceGroup?.financeNotification
                    ?.eszerzodesSecretLinkForCustomer
                : invoiceGroup?.financeNotification
                    ?.eszerzodesSecretLinkForSupplier;

              if (!secretLink) {
                const {
                  data,
                  error
                } = await signFactorNotification.createDigitalSignSecretLink();

                if (data) {
                  await reloadInvoiceGroup();
                  const urlAfterRefresh = isCustomer
                    ? data?.financeNotification?.eszerzodesSecretLinkForCustomer
                    : data?.financeNotification
                        ?.eszerzodesSecretLinkForSupplier;

                  if (urlAfterRefresh) {
                    window.open(urlAfterRefresh);
                  }
                } else {
                  notifyError(selectErrorNotificationMessage(error));
                }
              } else {
                window.open(secretLink);
              }
            }}
          />
          <SuccessDigitalSignDialog
            open={successDigitalSignDialogShow}
            onClose={hideSuccessDigitalSign}
            onOKButtonClick={hideSuccessDigitalSign}
          />
        </FlexiPageLayout>
      </div>
    )
  );
};

const FactorNotificationPage = ({ match, history }) => {
  const goBack = useCallback(() => history.push("/app/invoice"), []);
  const { data, revalidate: reloadInvoiceGroup } = useInvoiceGroup(
    match.params.id
  );

  return (
    <FactorNotificationPageContent
      invoiceGroup={data}
      reloadInvoiceGroup={reloadInvoiceGroup}
      onBackButtonClick={goBack}
    />
  );
};

export default withRouter(withWidth()(FactorNotificationPage));
