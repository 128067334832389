import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { defineMessages } from "react-intl.macro";
import { useIntl } from "react-intl";
import notFound from "../../images/not-found.svg";
import FlexiLink from "../../FlexiComponents/FlexiLink";

const messages = defineMessages({
  notFoundTitle: {
    id: "label.notFoundPage.notFoundTitle",
    defaultMessage: "Mi is keressük, de nem találjuk!"
  },
  notFoundReason: {
    id: "label.notFoundPage.notFoundReason",
    defaultMessage: "Ennek a következő okai lehetnek:"
  },
  notFoundReason1: {
    id: "label.notFoundPage.notFoundReason1",
    defaultMessage:
      "Oldalunk megújult, és a keresett oldal címe megváltozott, vagy megszűnt."
  },
  notFoundReason2: {
    id: "label.notFoundPage.notFoundReason2",
    defaultMessage: "Elgépelted az oldal címát. Ellenőrizd hogy jól írtad-e."
  },
  notFoundReason3: {
    id: "label.notFoundPage.notFoundReason3",
    defaultMessage: "Hibás címről érkeztél"
  },
  notFoundReason4: {
    id: "label.notFoundPage.notFoundReason4",
    defaultMessage:
      "Hiba lépett fel az oldal működésében, kijavításán már biztosan dolgozunk."
  },
  backToTheDashboard: {
    id: "label.backToTheDashboard",
    defaultMessage: "Vissza a főoldalra"
  }
});

const marginBottoms = {
  marginBottom: "40px"
};

const NotFoundPage = () => {
  const intl = useIntl();

  return (
    <Grid container alignItems="center" direction="column">
      <Grid container justify="center" style={{ marginTop: "40px" }}>
        <Grid item>
          <img src={notFound} alt="Not Found" />
        </Grid>
      </Grid>
      <Grid item style={marginBottoms}>
        <Typography variant="h2">404</Typography>
      </Grid>

      <Grid item style={marginBottoms}>
        <Typography variant="h5">
          {intl.formatMessage(messages.notFoundTitle)}
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant="h6">
          {intl.formatMessage(messages.notFoundReason)}
        </Typography>
        <ul>
          {[...Array(4).keys()].map(i => (
            <li key={i}>
              <Typography variant="body1">
                {intl.formatMessage(messages[`notFoundReason${i + 1}`])}
              </Typography>
            </li>
          ))}
        </ul>
      </Grid>
      <FlexiLink to={"/"}>
        {intl.formatMessage(messages.backToTheDashboard)}
      </FlexiLink>
    </Grid>
  );
};

export default NotFoundPage;
