import React, { useMemo } from "react";
import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import { makeExternalFormSubmit } from "../../utils/makeExternalFormSubmit";
import Button from "@material-ui/core/Button";
import { Form } from "react-final-form";
import { getFlexipayUrl } from "../../utils/urlHelpers";
import Grid from "@material-ui/core/Grid";
import { Fields } from "../../FlexiComponents/FlexiForm";
import { useMutator, useProfileData } from "../../hooks/useApi";
import {
  useErrorOccurredNotification,
  useSaveSuccessNotification
} from "../../hooks/useNotificationState";
import { minLength } from "../../utils/validators";

const PasswordChangeDialog = ({ open, onClose }) => {
  const formId = "passwordChangeForm";
  const onSubmit = makeExternalFormSubmit(formId);
  const passwordFields = useMemo(() => {
    return [
      [
        {
          fieldType: "text",
          type: "password",
          name: `current_password`,
          label: "Jelenlegi jelszó",
          required: true
        }
      ],
      [
        {
          fieldType: "text",
          type: "password",
          name: `plainPassword.first`,
          label: "Új jelszó",
          required: true,
          validate: minLength(8)
        }
      ],
      [
        {
          fieldType: "text",
          type: "password",
          name: `plainPassword.second`,
          label: "Új jelszó ismét",
          required: true,
          validate: minLength(8)
        }
      ]
    ];
  }, []);
  const [post, postLoading] = useMutator("POST");
  const notifyError = useErrorOccurredNotification();
  const notifySuccessSave = useSaveSuccessNotification();
  const { profileId } = useProfileData();
  return (
    <FlexiDialog
      dialogActions={
        <Grid container style={{ justifyContent: "flex-start" }}>
          <Grid item>
            <Button onClick={onSubmit}>Mentés</Button>
          </Grid>
          <Grid item>
            <Button onClick={onClose}>Mégse</Button>
          </Grid>
        </Grid>
      }
      open={open}
      onClose={onClose}
      title={"Jelszó módosítása"}
    >
      <Form
        validate={values => {
          const errors = {};
          if (values?.plainPassword?.first !== values?.plainPassword?.second) {
            errors.plainPassword = {
              first: "A két jelszónak egyeznie kell !",
              second: "A két jelszónak egyeznie kell !"
            };
          }
          return errors;
        }}
        onSubmit={async values => {
          const { data, error } = await post(
            getFlexipayUrl(`/password/${profileId}/change`),
            values
          );
          if (error) {
            notifyError(error);
          }
          if (data) {
            notifySuccessSave();
          }
        }}
        render={({ handleSubmit }) => {
          return (
            <form id={formId} onSubmit={handleSubmit}>
              <Grid item xs={12}>
                <Fields config={passwordFields} />
              </Grid>
            </form>
          );
        }}
      />
    </FlexiDialog>
  );
};
export default PasswordChangeDialog;
