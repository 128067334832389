import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import newFeature from "../../images/redesignImages/Group 48.svg";
import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import { FormattedHTMLMessage } from "react-intl";
import { defineMessages } from "react-intl.macro";
import billNav from "../../images/bill/10_bill_nav.svg";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { getFlexipayUrl, getUrlWithCompanyId } from "../../utils/urlHelpers";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import {
  useEmailSentNotification,
  useErrorOccurredNotification,
  useNavSyncNotification
} from "../../hooks/useNotificationState";
import { useMutator } from "../../hooks/useApi";

const messages = defineMessages({
  popupText1: {
    id: "navInfoBar.popupText1",
    defaultMessage: `A folyósítás folyamata a számlák adatainak rögzítésével kezdődik. Eddig ezt manuális adatrögzítéssel, vagy fájlból való feltöltéssel tehette meg. Mi már <b>jobb megoldást</b> is tudunk kínálni Önnek - megérkezett a NAVbot!`
  },
  popupText2: {
    id: "navInfoBar.popupText2",
    defaultMessage: `Mivel a belföldi partnerek felé kiállított számlákról kötelező adatot szolgáltatni a NAV felé, így a két rendszer <b>összekötését követően</b> ezeket a <b>számlaadatokat automatikusan be tudjuk tölteni</b> a NAVbot segítségével. Ezzel nem csak a munkaerő költségét csökkentjük, de az emberi mulasztásból eredő hibákat is meg tudjuk szüntetni!`
  },
  popupText3: {
    id: "navInfoBar.popupText3",
    defaultMessage: `Természetesen segítünk a két rendszer összekötésében is! A <b>NAV adatkapcsolat</b> oldalon részletes útmutatót talál.`
  },
  popupText4: {
    id: "navInfoBar.popupText4",
    defaultMessage: `A NAV Online Számla rendszeréből lehívott számlákat a FlexiBill <b>Számlák</b> oldalán, az <b>Előkészített számlák</b> listában találja majd meg. Itt Önnek még lehetősége nyílik az adatokat ellenőrizni, dokumentumot csatolni, vagy akár törölni is a számlát! Ugyanitt Ön kiválaszthatja, hogy melyik számlákra és mikor kér finanszírozást.`
  },
  popupText5: {
    id: "navInfoBar.popupText5",
    defaultMessage: `Fontos, hogy a számlák betöltése jelenleg csak <b>forint alapú faktorszerződéssel rendelkező vevők</b> esetében lehetséges!`
  },
  infoBarText: {
    id: "navInfoBar.infoBarText",
    defaultMessage: `Szinkronizáld számláid a NAV rendszeréből!`
  },
  infoBarTextMobile: {
    id: "navInfoBar.infoBarTextMobile",
    defaultMessage: `Szinkronizálja számláit <br>a NAV rendszeréből!`
  },
  buttonText: {
    id: "navInfoBar.buttonText",
    defaultMessage: `Hogy működik?`
  },
  popupButtonText1: {
    id: "navInfoBar.popupButtonText1",
    defaultMessage: `Nem szeretném`
  },
  popupButtonText2: {
    id: "navInfoBar.popupButtonText2",
    defaultMessage: `Megcsinálom az összekötést`
  }
});

const useStyles = makeStyles({
  navMobileButton: {
    background: "transparent",
    "&:hover": { background: "transparent !important" }
  },
  navButton: {
    backgroundColor: "#2227AC !important",
    "&:hover": { backgroundColor: "#02BE6F !important" }
  },
  navButtonWrapper: {
    "& button": {
      backgroundColor: "#2227AC !important",
      "&:hover": { backgroundColor: "#02BE6F !important" }
    }
  }
});

const NavInfoBar = ({ onClose, width, navConnected, refreshList }) => {
  const [navInfoDialogOpen, setNavInfoDialogOpen] = useState(null);
  const navInfoHandler = () => {
    onClose();
    setNavInfoDialogOpen(false);
    localStorage.setItem("navInfoBar", "false");
  };
  const isMobile = isWidthDown("sm", width);
  const classes = useStyles();
  const [get] = useMutator("GET");
  const notifyNavSync = useNavSyncNotification();
  const notifyError = useErrorOccurredNotification();
  return (
    <div>
      <Paper
        style={{
          backgroundColor: "#DFE7FB",
          height: "60px",
          paddingLeft: "40px",
          paddingRight: "10px",
          marginBottom: "30px",
          paddingBottom: 0,
          paddingTop: 0
        }}
      >
        <Grid container align={"center"}>
          <Grid
            item
            style={{
              paddingTop: "12px",
              paddingBottom: "12px",
              paddingRight: "24px"
            }}
          >
            <img alt={"newFeature"} src={newFeature} />
          </Grid>
          {isMobile ? (
            <Grid item style={{ textAlign: "justify", alignSelf: "center" }}>
              <Typography variant={"body2"}>
                <FormattedHTMLMessage {...messages.infoBarTextMobile} />
              </Typography>
            </Grid>
          ) : (
            <Grid item style={{ paddingTop: "19px", paddingBottom: "19px" }}>
              <Typography variant={"body2"}>
                <FormattedHTMLMessage {...messages.infoBarText} />
              </Typography>
            </Grid>
          )}
          <Grid item style={{ flexGrow: 1 }} />

          {isMobile ? (
            <IconButton
              className={classes.navMobileButton}
              size={"small"}
              onClick={() => setNavInfoDialogOpen(true)}
              style={{
                border: "#2227AC 1px solid",
                alignSelf: "center"
              }}
            >
              <InfoIcon style={{ fill: "#2227AC" }} />
            </IconButton>
          ) : (
            <Grid
              item
              style={{ paddingTop: "12px", paddingBottom: "12px" }}
              className={classes.navButtonWrapper}
            >
              {navConnected ? (
                <Button
                  color={"primary"}
                  className={`${classes.navButton}`}
                  onClick={() => {
                    get(getUrlWithCompanyId("/invoice/fetch/nav")).then(
                      ({ data, error }) => {
                        if (data) {
                          refreshList();
                          notifyNavSync();
                        } else {
                          notifyError(selectErrorNotificationMessage(error));
                        }
                      }
                    );
                  }}
                  style={{
                    minWidth: isMobile ? "160px" : "200px",
                    backgroundColor: "#2227AC !important"
                  }}
                >
                  Szinkronizálok!
                </Button>
              ) : (
                <Button
                  color={"primary"}
                  className={`${classes.navButton}`}
                  onClick={() => setNavInfoDialogOpen(true)}
                  style={{
                    minWidth: isMobile ? "160px" : "200px",
                    backgroundColor: "#2227AC !important"
                  }}
                >
                  <FormattedHTMLMessage {...messages.buttonText} />
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Paper>
      <FlexiDialog
        size={"large"}
        imgWidth={"220px"}
        imgSrc={billNav}
        onClose={() => setNavInfoDialogOpen(null)}
        open={Boolean(navInfoDialogOpen)}
        title={"Hogyan kerülnek át a számlák a NAV rendszeréből?"}
        dialogActions={
          <div style={{ width: "100%" }}>
            <Grid container justify={"center"}>
              {isMobile ? (
                <Grid container>
                  <Grid item>
                    <Button
                      variant={"text"}
                      onClick={navInfoHandler}
                      outlined
                      style={{ border: "none", height: "32px" }}
                    >
                      <Typography fontSize={12} style={{ color: "#394AB8" }}>
                        <FormattedHTMLMessage {...messages.popupButtonText1} />
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button to={"app/profile/nav-data"}>
                      <FormattedHTMLMessage {...messages.popupButtonText2} />
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <div>
                  <Button href={"profile/nav-data"}>
                    <FormattedHTMLMessage {...messages.popupButtonText2} />
                  </Button>
                  <Button onClick={navInfoHandler} variant={"text"}>
                    <FormattedHTMLMessage {...messages.popupButtonText1} />
                  </Button>
                </div>
              )}
            </Grid>
          </div>
        }
      >
        {isMobile ? (
          <Grid container>
            <Grid item style={{ paddingBottom: "20px" }}>
              <Typography style={{ textAlign: "center" }}>
                <FormattedHTMLMessage {...messages.popupText1} />
              </Typography>
            </Grid>
            <Grid item style={{ paddingBottom: "20px" }}>
              <Typography style={{ textAlign: "center" }}>
                <FormattedHTMLMessage {...messages.popupText2} />
              </Typography>
            </Grid>
            <Grid item style={{ paddingBottom: "20px" }}>
              <Typography style={{ textAlign: "center" }}>
                <FormattedHTMLMessage {...messages.popupText3} />
              </Typography>
            </Grid>
            <Grid item style={{ paddingBottom: "20px" }}>
              <Typography style={{ textAlign: "center" }}>
                <FormattedHTMLMessage {...messages.popupText4} />
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ textAlign: "center" }}>
                <FormattedHTMLMessage {...messages.popupText5} />
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item style={{ paddingBottom: "20px" }}>
              <Typography>
                <FormattedHTMLMessage {...messages.popupText1} />
              </Typography>
            </Grid>
            <Grid item style={{ paddingBottom: "20px" }}>
              <Typography>
                <FormattedHTMLMessage {...messages.popupText2} />
              </Typography>
            </Grid>
            <Grid item style={{ paddingBottom: "20px" }}>
              <Typography>
                <FormattedHTMLMessage {...messages.popupText3} />
              </Typography>
            </Grid>
            <Grid item style={{ paddingBottom: "20px" }}>
              <Typography>
                <FormattedHTMLMessage {...messages.popupText4} />
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <FormattedHTMLMessage {...messages.popupText5} />
              </Typography>
            </Grid>
          </Grid>
        )}
      </FlexiDialog>
    </div>
  );
};

export default withWidth()(NavInfoBar);
