import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React from "react";
import styled from "styled-components";
import { Checkbox } from "@material-ui/core";

const SortLabel = styled.span`
  && {
    width: 100%;
    color: #4a4a4a;
    line-height: 16px;
    display: block;
    font-family: "Nunito-Regular", Arial;
    font-size: 16px;
    transform: translate(0px, 1.5px) scale(0.75);
    transform-origin: left top;
    text-transform: uppercase;
  }
`;

const getRenderedCell = (col, params) =>
  col.colDef.cellRendererFramework({
    value: params.api.getValue(col.colId, params.node),
    data: params.data
  });

const makeFullWidthCellRenderer = ({ spacing }) => params => {
  const allColumns = params.columnApi.getAllColumns();
  const basicColumns = allColumns.filter(
    col => !col.colDef.menu && !col.colDef.statusIndicator
  );
  const menuItemCol = allColumns.find(col => col.colDef.menu);
  const statusIndicatorCol = allColumns.find(col => col.colDef.statusIndicator);
  const els = basicColumns.map(col => {
    if (col.colDef.cellRendererFramework) {
      return getRenderedCell(col, params);
    }

    if (col.colDef.valueFormatter) {
      return col.colDef.valueFormatter({
        value: params.api.getValue(col.colId, params.node),
        data: params.data
      });
    }

    return params.api.getValue(col.colId, params.node);
  });
  const hasSelection =
    params &&
    params.agGridReact &&
    params.agGridReact.props &&
    params.agGridReact.props.rowSelection === "multiple" &&
    allColumns.reduce(
      (hasSelection, column) =>
        Boolean(column && column.colDef && column.colDef.checkboxSelection) ||
        hasSelection,
      false
    );

  let selected = false;

  if (hasSelection) {
    const selectedRows = params.api.getSelectedRows();
    selected = selectedRows.reduce(
      (selected, data) => data.id === params.node.data.id || selected,
      false
    );
  }

  return (
    <Paper
      style={{
        paddingTop: hasSelection ? "52px" : "10px",
        paddingBottom: "15px",
        paddingLeft: statusIndicatorCol ? "22px" : "16px",
        paddingRight: menuItemCol ? "52px" : "16px",
        position: "relative",
        marginLeft: "3px",
        marginRight: "3px"
      }}
      square
      elevation={0}
    >
      {statusIndicatorCol && getRenderedCell(statusIndicatorCol, params)}
      <Grid container spacing={spacing} style={{ position: "relative" }}>
        {els.map((item, i) => (
          <Grid
            item
            xs={basicColumns[i].colDef.mobileCellXs || 6}
            style={{ paddingBottom: 13 }}
            key={`ag-grid-full-width-cell-renderer-row-${i}`}
          >
            <Grid container direction="column">
              <Grid item xs={12} style={{ whiteSpace: "normal" }}>
                <SortLabel>{basicColumns[i].colDef.headerName}</SortLabel>
              </Grid>
              <Grid item xs={12} style={{ whiteSpace: "normal" }}>
                {item}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      {menuItemCol && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            margin: "0.7rem"
          }}
        >
          {getRenderedCell(menuItemCol, params)}
        </div>
      )}
      {hasSelection && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            margin: "0.3rem"
          }}
        >
          <Checkbox
            onChange={(e, checked) => {
              params.node.setSelected(checked);
              params.api.refreshView();
            }}
            checked={selected}
          />
        </div>
      )}
    </Paper>
  );
};

export default makeFullWidthCellRenderer;
