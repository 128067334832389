import styled from "styled-components";
import { breakpoint } from "../utils/breakpoints";

export const LogoWrap = styled.div`
  && {
    position: relative;
    z-index: -1;
    display: block;
    text-align: center;

    ${breakpoint('xs', 'md')`
      z-index: 0;
    `};

    img {
      display: block;
    }

    a {
      display: block;
      padding: 10px 9px 15px 5px;
    }
  }
`;