import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import FlexiPageLayout from "../../FlexiComponents/FlexiPageLayout";

const PageLayout = ({ titleMessage, children, ...rest }) => {
  return (
    <FlexiPageLayout title={<FormattedMessage {...titleMessage} />} {...rest}>
      {children}
    </FlexiPageLayout>
  );
};

export default PageLayout;

PageLayout.propTypes = {
  backButton: PropTypes.any,
  backUrl: PropTypes.string,
  children: PropTypes.any.isRequired,
  disabledBottomMargin: PropTypes.bool,
  disabledTopMargin: PropTypes.bool,
  history: PropTypes.any,
  loading: PropTypes.bool,
  rightSideComponents: PropTypes.array,
  suppressTourButton: PropTypes.bool,
  titleMessage: PropTypes.any,
  width: PropTypes.any
};
