import {
  getStoredSsoToken,
  resetAuthData,
  resetToken
} from "../../utils/authentication";
import { useLoginPageData } from "../../hooks/useApi";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import LoadingDialog from "../../FlexiComponents/FlexiLoader";
import { useErrorOccurredNotification } from "../../hooks/useNotificationState";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import { useStoreData } from "../../hooks/useDataState";

const searchParams = new URLSearchParams(window.location.search);
const kulcsSoftSsoLoginToken = searchParams.get("id_token");
const error = searchParams.get("error");
const errorDescription = searchParams.get("error_description");
const KulcsSoftSsoPage = () => {
  const ssoToken = getStoredSsoToken();
  const [doLogin, loading] = useLoginPageData();
  const { push: redirect } = useHistory();
  const notifyError = useErrorOccurredNotification();
  const storeData = useStoreData();

  useEffect(() => {
    resetToken();
    if (error) {
      notifyError({ text: errorDescription });
    } else {
      doLogin(
        kulcsSoftSsoLoginToken,
        "KulcsSoftIdToken",
        "/kulcssoft/sso/login"
      ).then(({ data, error }) => {
        storeData("kulcsSoft", true);

        if (data) {
          redirect("/app");
        } else {
          notifyError(selectErrorNotificationMessage(error));
        }
      });
    }
  }, []);

  return <LoadingDialog loading={true} />;
};

export default KulcsSoftSsoPage;
