/**
 * @render react
 * @name FlexiProgressBar
 * @description FlexiProgressBar
 * @example
 * <FlexiProgressBar />
 */

import PropTypes from "prop-types";
import React from "react";
import { LinearProgress } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import withPropsStyles from "../utils/withPropsStyle";

const styles = props => ({
  root: {
    flexGrow: 1
  },
  linearColorPrimary: {
    backgroundColor: "#DFDFF1"
  },
  linearBarColorPrimary: {
    backgroundColor: props.color || "#A7A8DC"
  }
});

const FlexiProgressBar = props => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Tooltip
        enterTouchDelay={0}
        title={props.tooltip && props.tooltip}
        aria-label={props.tooltip}
        placement="top"
        style={{
          width: 50
        }}
      >
        <LinearProgress
          value={props.percentage}
          variant={"buffer"}
          style={{
            height: 30,
            borderRadius: 3,
          }}
          classes={{
            colorPrimary: classes.linearColorPrimary,
            barColorPrimary: classes.linearBarColorPrimary
          }}
        />
      </Tooltip>
    </div>
  );
};

FlexiProgressBar.propTypes = {
  props: PropTypes.object
};

export default withPropsStyles(styles)(FlexiProgressBar);
