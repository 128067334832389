import React from "react";
import Typography from "@material-ui/core/Typography";
import withWidth from "@material-ui/core/withWidth";
import Grid from "@material-ui/core/Grid";
import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import { isWidthUp } from "@material-ui/core";
import FlexiFileUploader from "../../FlexiComponents/FlexiFileUploader";

const fileTypes = [
  { value: "certificate_of_completion", text: "Teljesítési igazolás" },
  { value: "invoice", text: "Számla" },
  {
    value: "invoice_and_certificate_of_completion",
    text: "Számla és teljesítési igazolás"
  }
];

const UploadAttachmentsDialog = props => {
  return (
    <FlexiDialog
      size={"small"}
      imgSrc={props.imgSrc}
      imgWidth={"80px"}
      open={props.open}
      title={props.title}
      PaperProps={{ className: "upload-attachments-dialog" }}
      onClose={props.onClose}
      dialogActions={
        <Grid
          item
          xs={12}
          style={{ position: "sticky", bottom: 0, display: "inline-flex" }}
        >
          <Grid container>
            {fileTypes.map(type => (
              <Grid item xs={12}>
                <FlexiFileUploader
                  title={type.text}
                  files={props.files
                    .filter(file => {
                      return file.type === type.value;
                    })
                    .map(({ file }) => file)}
                  onSubmit={values =>
                    props.onAddFile(
                      values.files.map(file => ({ file, type: type.value }))
                    )
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        alignItems={isWidthUp("sm", props.width) ? "flex-start" : "center"}
      >
        <Grid item xs style={{ paddingBottom: "20px", paddingTop: "10px" }}>
          <Typography variant="body1">
            Ahhoz, hogy tudj faktoráltatni, kérlek, töltsd fel a számlához
            tartozó számlaképet, valamint a teljesítés igazolást. Amennyiben
            több számlát töltöttél fel, a hozzájuk tartozó számlaképeket elég
            egy PDF-ben, összefűzve csatolnod.
          </Typography>
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};

export default withWidth()(UploadAttachmentsDialog);
