/**
 * @render react
 * @name FlexiTypography
 * @description FlexiTypography
 * @example
 * <FlexiTypography variant="h1">Text</FlexiTypography>
 <FlexiTypography variant="h2">Text</FlexiTypography>
 <FlexiTypography variant="h3">Text</FlexiTypography>
 <FlexiTypography variant="h4">Text</FlexiTypography>
 <FlexiTypography variant="h5">Text</FlexiTypography>
 <FlexiTypography variant="body1">Text</FlexiTypography>
 <FlexiTypography variant="body2">Text</FlexiTypography>
 */

import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { breakpoint } from '../utils/breakpoints';
import './fonts/fontFaces.css';

const renderTypography = ({bold, semiBold, italic, handWritten, fontSize, ...rest}) => {
    return <Typography {...rest} />
};


const FlexiTypography = styled(renderTypography).attrs({
    classes: {
        root: 'root',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        body2: 'body2',
        body1: 'body1',
        colorTextSecondary: 'textSecondary',
        colorSecondary: 'secondary',
    },
})`
  && {
    &.root {
      font-family: ${props =>
    props.handWritten
        ? "'IndieFlower', Helvetica, Arial, sans-serif !important;"
        : "'Nunito-Regular', Helvetica, Arial, sans-serif;"}
      color: #303030;
      font-weight: ${props =>
    props.bold ? 'bold' : props.semiBold ? '600' : 'inherit'};
      ${props => (props.italic ? 'font-style: italic;' : '')};
    }

    &.textSecondary {
      color: #4A4A4A;
    }

    &.secondary {
      color: white;
    }

    &.body2 {
      font-size: 16px;
    }

    &.body1 {
      font-size: 14px;
    }

    &.h6 {
      font-size: 20px;
      line-height: 1.16em;
    }

    &.h3 {
      font-family: 'BloggerSans-Bold';
      font-size: 18px;
    }    
    
    &.h5 {
      font-family: 'BloggerSans-Bold';
      font-size: 24px;
    }

    &.h2 {
      font-family: 'BloggerSans-Bold';
      font-size: 22px;
    }

    ${breakpoint('xs', 'md')`
      &.h4 {
        font-size: 20px;
      }
    `};
    
    &.h6 {
      font-size: 20px;
      line-height: 1.16em;
    }
    
    ${props => props.fontSize && `font-size: ${props.fontSize}px !important;`}
  }
`;

export default FlexiTypography;
