/**
 * @render react
 * @name Currency Formatter
 * @description This is a helper function for formatting currency.
 * @example
 *
 * import React from "react";
 * import { formatCurrency, CurrencyFormatter } from "@bit/balazs_tamas.flexi.flexi-currency-formatter";
 *
 * export default (
 * <div>
 *   <p>{formatCurrency(123456)}</p>
 *   <CurrencyFormatter currency="USD">123456</CurrencyFormatter>
 * </div>
 * )
 */

import React from "react";
import PropTypes from "prop-types";

export function formatCurrency(value, config = {}, locale) {
  const initConfig = {
    style: "currency",
    currency: config.currency || "HUF",
    minimumFractionDigits: config.currency !== "HUF" ? 2 : 0,
    maximumFractionDigits: config.currency !== "HUF" ? 2 : 0,
    currencyDisplay: "symbol",
    ...config
  };

  return Intl.NumberFormat(locale || "hu-HU", initConfig).format(value);
}

export const CurrencyFormatter = ({
  currencyDisplay,
  currency,
  locale,
  minimumFractionDigits,
  maximumFractionDigits,
  children
}) => {
  let resultNum = null;

  if (children && children.length) {
    resultNum = parseFloat(children.replace(/,/g, "."));
  }

  return Intl.NumberFormat(locale || "hu-HU", {
    style: "currency",
    currency: currency || "HUF",
    minimumFractionDigits: minimumFractionDigits || currency === "HUF" ? 0 : 2,
    maximumFractionDigits: maximumFractionDigits || currency === "HUF" ? 0 : 2,
    currencyDisplay: currencyDisplay || "symbol"
  }).format(resultNum);
};

CurrencyFormatter.propTypes = {
  currency: PropTypes.string,
  locale: PropTypes.string,
  minimumFractionDigits: PropTypes.bool,
  maximumFractionDigits: PropTypes.bool,
  currencyDisplay: PropTypes.string,
  children: PropTypes.any
};
