import PropTypes from "prop-types";
import React from "react";
import { Pie } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import Button from "@material-ui/core/Button";
import { Box, Typography } from "@material-ui/core";

const FlexiPieChart = ({ config, detailsButtonClick }) => {
  return (
    <Paper style={{ paddingLeft: "40px", paddingRight: "40px" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={"40px"}
      >
        <Typography variant="h4" style={{ whiteSpace: "nowrap" }}>
          <FormattedMessage {...messages.flexiPieChartTitle} />
        </Typography>
        <Button variant={"text"} onClick={detailsButtonClick}>
          Megtekintés
        </Button>
      </Box>
      <Grid container>
        <Grid item xs={6}>
          <div style={{ height: 200 }}>
            <Pie {...config} />
          </div>
        </Grid>
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          <Grid container spacing={2}>
            {config.data.labels.map((label, i) => (
              <Grid item key={`flexi-bar-chart-item-${i}`} xs={12}>
                <Box display={"flex"} style={{ gap: "1rem" }}>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "2px",
                      backgroundColor:
                        config.data.datasets[0].backgroundColor[i]
                    }}
                  />
                  <Typography>{label}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

FlexiPieChart.propTypes = {
  config: PropTypes.object,
  detailsButtonClick: PropTypes.func
};
export default FlexiPieChart;
