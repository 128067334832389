import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import { defineMessages } from "react-intl.macro";
import { useIntl } from "react-intl";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import React from "react";
import billSmile from "../../images/bill/4_bill_smile.svg";

const messages = defineMessages({
  title: {
    id: "dialogTitle.submuttedSuccess",
    defaultMessage: "Sikeres mentés!"
  },
  description: {
    id: "dialogDescription.submuttedSuccess",
    defaultMessage:
      "A számlák előkészítéséről e-mailben tájékoztatjuk az engedményezési végrehajtót, aki elindíthatja a faktorálás folyamatát!"
  },
  button: {
    id: "dialogTitle.button",
    defaultMessage: "Rendben"
  }
});

const SubmittedSuccessDialog = ({ open, onClose }) => {
  const intl = useIntl();

  return (
    <FlexiDialog
      size={"small"}
      open={open}
      onClose={onClose}
      title={intl.formatMessage(messages.title)}
      dialogActions={
        <Button style={{ margin: "auto" }} onClick={onClose}>
          {intl.formatMessage(messages.button)}
        </Button>
      }
      imgSrc={billSmile}
    >
      <Typography>{intl.formatMessage(messages.description)}</Typography>
    </FlexiDialog>
  );
};

export default SubmittedSuccessDialog;
