import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import React from "react";
import Typography from "@material-ui/core/Typography";
import SuccessImg from "../../images/smile.svg";
import Button from "@material-ui/core/Button";

const FactoringProcessStartedDialog = ({
  open,
  onClose,
  customerName,
  maxWidth
}) => {
  return (
    <FlexiDialog
      open={open}
      onClose={onClose}
      title={"Sikeresen elindította a folyamatot!"}
      maxWidth={maxWidth}
      dialogActions={<Button onClick={onClose}>Rendben</Button>}
      imgSrc={SuccessImg}
    >
      <Typography>{`Az ügy jelenleg vevői visszaigazolásra vár státuszba került és a "${customerName}" emailben kapott tájékoztatást a számlák visszaigazolásáról. A sikeres vevői visszaigazolásról emailben fogjuk értesíteni önt.`}</Typography>
    </FlexiDialog>
  );
};

export default FactoringProcessStartedDialog;
