/**
 *
 * AppBar
 *
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MUIAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { breakpoint } from "../utils/breakpoints.js";

const StyledAppBar = styled(
  ({
    disabledBottomMargin,
    disabledTopMargin,
    position = "static",
    ...rest
  }) => <MUIAppBar {...rest} position={position} />
).attrs({
  color: "inherit"
})`
  && {
    box-shadow: none;
    background-color: transparent;
    width: initial;
    padding-top: 9px;
    padding-bottom: 5px;

    ${breakpoint("xs", "md")`
        margin-left: 0;
        margin-right: 0;
        ${props => !props.disabledBottomMargin && "margin-bottom: 24px;"}
    `};

    ${breakpoint("md")`
        ${props => !props.disabledBottomMargin && "margin-bottom: 31px;"}
    `};

    && > div {
      padding: 0;
    }
  }
`;

const AppBar = ({ children, ...rest }) => (
  <StyledAppBar {...rest}>
    <Toolbar>{children}</Toolbar>
  </StyledAppBar>
);

AppBar.propTypes = {
  children: PropTypes.any.isRequired
};

export default AppBar;
