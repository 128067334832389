import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import billSadImg from "../../images/bill/3_bill_sad.svg";
import React, { useState } from "react";

const DeclinedDialog = ({ agreementClaim }) => {
  const [open, setOpen] = useState(true);

  return (
    <FlexiDialog
      title={"Kedves Ügyfelünk!"}
      open={open}
      onClose={() => setOpen(false)}
      imgSrc={billSadImg}
    >
      Körültekintően megvizsgáltuk faktorálási kérelmedet. Sajnálattal
      értesítünk, hogy az ügylet nem került jóváhagyásra. "
      {agreementClaim.customers.map(item => item.party.partyName).join(", ")}"
      vevőddel kapcsolatban nem fogunk tudni szerződni és faktorálást indítani.
    </FlexiDialog>
  );
};

export default DeclinedDialog;
