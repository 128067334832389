/**
 * @render react
 * @name FlexiLayout
 * @description FlexiLayout
 * @example
 *    <FlexiLayout/>
 */

import PropTypes from "prop-types";
import React from "react";
import { Hidden } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import FlexiCompanyChooser from "../FlexiCompanyChooser";
import FlexiNavbar from "./Navbar";
import Drawer from "./Drawer";
import { compose, withState } from "recompose";
import { IconButtonWrap } from "./IconButtonWrap";
import AppBar from "./AppBar";
import { LogoImg } from "./LogoImage";
import RouteWrapper from "./RouteWrapper";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid/Grid";
import LayoutContext from "./LayoutContext";

const RootWrapper = withStyles({
  root: {
    display: "flex"
  }
})(Grid);

const NavbarWrapper = withStyles({
  root: {
    width: "230px"
  }
})(Grid);

const HiddenWrap = withStyles({
  root: {
    height: "100%"
  }
})(Hidden);

const MobileWrap = withStyles(theme => {
  return {
    root: {
      [theme.breakpoints.up("sm")]: {
        height: "64px"
      },
      [theme.breakpoints.down("lg")]: {
        height: "64px"
      },
      height: "50px",
      marginLeft: "12px",
      display: "flex",
      alignItems: "center",
      img: {
        display: "block",
        padding: " 10px 9px 15px 5px",
        boxSizing: "content-box"
      }
    }
  };
})(Grid);

const WrapOuter = withStyles(theme => {
  return {
    root: {
      [theme.breakpoints.between("md", "md")]: {
        "&:before": {
          content: "",
          display: "block",
          width: "200px",
          height: "50px",
          position: "absolute",
          backgroundColor: props =>
            props.appbarbgcolor ? props.appbarbgcolor : "inherit"
        }
      },
      top: "60px",
      flex: "1 0 auto",
      height: "100%",
      display: "flex",
      outline: "none",
      position: "fixed",
      zIndex: "1000",
      overflowY:  "auto",
      flexDirection: "column"
    }
  };
})(Grid);

const Main = withStyles(theme => {
  return {
    root: {
      [theme.breakpoints.up("md")]: {
        paddingLeft: 230
      },
      width: "100%",
      flex: "1 1 100%",
      paddingLeft: 0,
      position: "relative"
    }
  };
})(Grid);

const FlexiLayout = ({
    beta,
  mobileOpen,
  setMobileOpen,
  children,
  companyChooserProps,
  navBarProps,
  homeLogoUrl,
  sidebarbgcolor,
  appbarbgcolor,
  logoProps,
  renderAppBarComponents,
  sponsorProps,
  menuItemBadges,
  salesProps
}) => {
  return (
    <LayoutContext.Provider value={{ menuItemBadges }}>
      <RootWrapper>
        {/*// @todo notik // @todo prompt*/}
        <Hidden mdUp>
          <Drawer
            style={{ top: 32, width: "230px" }}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onOpen={() => setMobileOpen(true)}
            onClose={() => setMobileOpen(false)}
            ModalProps={
              { keepMounted: true } // Better open performance on mobile.
            }
            sidebarbgcolor={sidebarbgcolor}
          >
            <NavbarWrapper>
              <MobileWrap>
                <IconButtonWrap
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={() => setMobileOpen(!mobileOpen)}
                  className="drawer-open-button"
                >
                  <MenuIcon style={{ color: "#fff" }} />
                </IconButtonWrap>
                <LogoImg {...logoProps} />
              </MobileWrap>
              <FlexiCompanyChooser {...companyChooserProps} />
              <FlexiNavbar
                salesProps={salesProps}
                {...navBarProps}
                sidebarbgcolor={sidebarbgcolor}
                sponsorProps={sponsorProps}
                onClose={() => setMobileOpen(false)}
              />
            </NavbarWrapper>
          </Drawer>
        </Hidden>
        <WrapOuter  appbarbgcolor={appbarbgcolor}>
          <HiddenWrap smDown implementation="css">
            <Drawer variant="permanent" sidebarbgcolor={sidebarbgcolor}>
              <NavbarWrapper>
                <FlexiNavbar
                  salesProps={salesProps}
                  {...navBarProps}
                  sidebarbgcolor={sidebarbgcolor}
                />
              </NavbarWrapper>
            </Drawer>
          </HiddenWrap>
        </WrapOuter>
        <Main>
          <Hidden smDown>
            <AppBar
                beta={beta}
              companyChooserProps={companyChooserProps}
              onMenuClick={() => setMobileOpen(!mobileOpen)}
              logoSrc={logoProps.src}
              homeLogoUrl={homeLogoUrl}
              appbarbgcolor={appbarbgcolor}
              logoProps={logoProps}
              renderAppBarComponents={renderAppBarComponents}
            />
          </Hidden>
          {/* todo loading  */}
          <RouteWrapper>
            <Hidden mdUp>
              <AppBar
                  beta={beta}
                companyChooserProps={companyChooserProps}
                isMobile
                onMenuClick={() => setMobileOpen(!mobileOpen)}
                logoSrc={logoProps.src}
                homeLogoUrl={homeLogoUrl}
                appbarbgcolor={appbarbgcolor}
                logoProps={logoProps}
                renderAppBarComponents={renderAppBarComponents}
              />
            </Hidden>
            {children}
          </RouteWrapper>
        </Main>
      </RootWrapper>
    </LayoutContext.Provider>
  );
};
const enhance = compose(withState("mobileOpen", "setMobileOpen", false));

FlexiLayout.propTypes = {
  mobileOpen: PropTypes.bool,
  setMobileOpen: PropTypes.func,
  children: PropTypes.any,
  companyChooserProps: PropTypes.object.isRequired,
  navBarProps: PropTypes.object.isRequired,
  homeLogoUrl: PropTypes.string,
  logoProps: PropTypes.object.isRequired
};
export default enhance(FlexiLayout);
