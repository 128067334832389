import React from "react";
import FlexiCustomerDialog from "../../components/FlexiCustomerDialog";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import portalBill from "../../images/redesignImages/portalBill.svg";
import chillBill from "../../images/redesignImages/chillBill.svg";
import { isWidthUp } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";

const ConfirmationDialog = ({
  open,
  onClose,
  onNextButtonClick,
  width,
  waiting,
  customer,
  customerProcessType
}) => {
  return (
    <FlexiCustomerDialog
      open={open}
      onClose={onClose}
      title={
        <Typography variant="h5" fontSize={24}>
          Ön minden számlát visszaigazolt!
        </Typography>
      }
      darkerBackdrop
      imgSrc={waiting ? chillBill : portalBill}
    >
      <Grid
        container
        direction={"column"}
        alignItems={isWidthUp("sm", width) ? "flex-start" : "center"}
      >
        <Grid item xs style={{ paddingBottom: "20px", paddingTop: "10px" }}>
          {customer ? (
            waiting ? (
              <Typography variant="body1">
                Minden számla várakoztatva van, így amennyiben változott a
                számlák státusza kattintson a “várakoztatva” státusz mellett
                lévő “X” ikonra és így el tudja végezni a visszaigazolást.
              </Typography>
            ) : Boolean(customerProcessType === "ONLINE") ? (
              <Typography variant="body1">
                Amennyiben nem kíván további adatokat módosítani, kérem,
                kattintson a Tovább gombra!
              </Typography>
            ) : (
              <Typography variant="body1">
                Amennyiben nem kíván további adatokat <br /> módosítani, kérem,
                kattintson a Tovább gombra a <br /> Faktorálási értesítő
                tudomásulvételének aláírásához!
              </Typography>
            )
          ) : waiting ? (
            <Typography variant="body1">
              Minden számla várakoztatva van, így amennyiben változott a számlák
              státusza kattints a “várakoztatva” státusz mellett lévő “X” ikonra
              és így el tudod végezni a visszaigazolást.
            </Typography>
          ) : (
            <Typography variant="body1">
              Amennyiben nem szeretnél további adatokat <br /> módosítani,
              kérlek, kattints a Tovább gombra a <br /> Faktorálási értesítő
              tudomásulvételének aláírásához!
            </Typography>
          )}
        </Grid>
        <Grid item xs>
          {!waiting && (
            <Button
              style={{
                backgroundColor: "#142595",
                width: "120px",
                minWidth: "120px",
                height: "32px",
                fontSize: "12px"
              }}
              onClick={() => {
                onNextButtonClick();
                onClose();
              }}
            >
              Tovább
            </Button>
          )}
        </Grid>
      </Grid>
    </FlexiCustomerDialog>
  );
};

export default withWidth()(ConfirmationDialog);
