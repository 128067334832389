import React, { useState } from "react";
import PropTypes from "prop-types";
import { Paper, Grid, Typography, Button, AppBar } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import UploadDialog from "./UploadDialog";
import IconButton from "@material-ui/core/IconButton";
import docUploadImage from "./docUpload.svg";
import document from "./document.svg";
import eyeIcon from "./eyeIcon.svg";

const FlexiFileUploader = ({
  index,
  title,
  rightComponent,
  files,
  onSubmit,
  onError,
  onDeleteFile,
  getUploadDialogContent,
  getDeleteUrl,
  getDownloadUrl,
  dialogPlaceholderImage = docUploadImage,
  dialogPlaceholderText = "Húzd ide a dokumentumot, vagy válaszd ki a “Fájl hozzáadása” gombra kattintva!",
  immediateUpload,
  oneLine,
  exclamation,
  checkMark,
 hasFiles = Boolean(files && files.length)
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
        <Paper>
          {oneLine ? (
            <Grid
              container
              style={{
                justifyContent: "space-between",
                minHeight: "72px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
              direction={"row"}
              alignItems={"center"}
              spacing={2}
            >
              <Grid item style={{ display: "inline-flex" }}>
                <img src={!hasFiles ? exclamation : checkMark} />
                <Grid
                  container
                  direction={"column"}
                  style={{ paddingLeft: "32px" }}
                >
                  <Grid item style={{ display: "inline-flex" }}>
                    <Typography variant={"h5"} style={{ marginRight: "8px" }}>
                      {title}
                    </Typography>
                    {rightComponent}
                  </Grid>
                  <Grid item>
                    {!hasFiles ? (
                      <Typography
                        variant={"body1"}
                        style={{ opacity: "0.5", color: "#111A29" }}
                      >
                        Még egyetlen dokumentum sincs feltöltve. Kattints a
                        gombra és válaszd ki a fájlt!
                      </Typography>
                    ) : (
                      <Typography
                        variant={"body1"}
                        style={{ opacity: "0.5", color: "#111A29" }}
                      >
                        Dokumentum feltöltve!
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ display: "inline-flex" }}>
                {hasFiles && (
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    borderRadius={"5px"}
                    bgcolor={"#DCF8EC"}
                    style={{ minWidth: "200px" }}
                  >
                    <img
                      src={document}
                      alt={"Document icon"}
                      style={{ marginLeft: "1rem", width: "17px" }}
                    />
                    <Typography>
                      {files.length}{" "}
                      {immediateUpload ? "fájl feltöltve" : "fájl kiválasztva"}
                    </Typography>
                    <IconButton
                      onClick={() => setDialogOpen(true)}
                      style={{ marginRight: "1rem" }}
                    >
                      <img src={eyeIcon} alt={"Eye icon"} />
                    </IconButton>
                  </Box>
                )}
                <Button
                  style={{ marginLeft: "16px" }}
                  variant={"outlined"}
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                  fullWidth
                >
                  <svg
                    width="17"
                    height="20"
                    viewBox="0 0 17 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: "10px" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17 12L8.5 0L0 12H2.61278V20H13.4092V12H17Z"
                      fill="#02BE6F"
                    />
                  </svg>
                  Feltöltés
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid
                item
                container
                alignItems={"center"}
                xs={12}
                style={{ gap: "0.5rem" }}
              >
                {(index === 0 || index) && (
                  <Typography variant={"h3"} color={"textPrimary"}>
                    {index < 9 ? `0${index + 1}` : index + 1}
                  </Typography>
                )}
                {rightComponent}
              </Grid>
              <Grid item xs={12}>
                <Box marginBottom={"8px"}>
                  <Typography variant={"h5"} style={{ fontSize: "17px" }}>
                    {title}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {!hasFiles ? (
                  <Typography
                    variant={"body1"}
                    style={{ opacity: "0.5", marginBottom: "8px" }}
                  >
                    Még egyetlen dokumentum sincs feltöltve. Kattints a gombra
                    és válaszd ki a fájlt!
                  </Typography>
                ) : (
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    borderRadius={"5px"}
                    bgcolor={"#DCF8EC"}
                    width={"100%"}
                    height={"52px"}
                    marginBottom={"12px"}
                  >
                    <img
                      src={document}
                      alt={"Document icon"}
                      style={{ marginLeft: "1rem", width: "17px" }}
                    />
                    <Typography>
                      {files.length}{" "}
                      {immediateUpload ? "fájl feltöltve" : "fájl kiválasztva"}
                    </Typography>
                    <IconButton
                      onClick={() => setDialogOpen(true)}
                      style={{ marginRight: "1rem" }}
                    >
                      <img src={eyeIcon} alt={"Eye icon"} />
                    </IconButton>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                  fullWidth
                >
                  <svg
                    width="17"
                    height="20"
                    viewBox="0 0 17 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: "10px" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17 12L8.5 0L0 12H2.61278V20H13.4092V12H17Z"
                      fill="#02BE6F"
                    />
                  </svg>
                  Feltöltés
                </Button>
              </Grid>
            </Grid>
          )}
          <UploadDialog
            open={dialogOpen}
            title={title}
            onClose={() => setDialogOpen(false)}
            onSubmit={onSubmit}
            files={files}
            onError={onError}
            onDeleteFile={onDeleteFile}
            getDeleteUrl={getDeleteUrl}
            getDownloadUrl={getDownloadUrl}
            dialogPlaceholderImage={dialogPlaceholderImage}
            dialogPlaceholderText={dialogPlaceholderText}
            immediateUpload={immediateUpload}
          >
            {getUploadDialogContent && getUploadDialogContent()}
          </UploadDialog>
        </Paper>
  );
};

export default FlexiFileUploader;

FlexiFileUploader.propTypes = {
  emptyButton: PropTypes.any,
  files: PropTypes.array,
  onDeleteFile: PropTypes.func,
  onError: PropTypes.func,
  onSubmit: PropTypes.func,
  rightComponent: PropTypes.any,
  successButton: PropTypes.string,
  successText: PropTypes.string,
  title: PropTypes.string,
  getUploadDialogContent: PropTypes.func,
  getDeleteUrl: PropTypes.func,
  getDownloadUrl: PropTypes.func,
};
