import React, { useState } from "react";
import PageLayout from "../../FlexiComponents/FlexiPageLayout";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import newUserIcon from "../../images/guide/icon_newUser.svg";
import existingUserIcon from "../../images/guide/icon_existingUser.svg";
import navConnectionIcon from "../../images/guide/icon_navConnection.svg";
import { defineMessages } from "react-intl.macro";
import FlexiNavConnectionHelpDialog from "../../FlexiComponents/FlexiNavConnectionDialog";
import ExistingUserGuideDialog from "./existingUserGuideDialog";
import NewUserGuideDialog from "./newUserGuideDialog";
import { useProfileData } from "../../hooks/useApi";

const messages = defineMessages({
  pageTitle: {
    id: "pageTitle.guidePage",
    defaultMessage: "Használati segéd"
  },
  newUsers: {
    id: "newUsers.cardTitle.guidePage",
    defaultMessage: "Új ügyfeleknek"
  },
  existingUsers: {
    id: "existingUsers.cardTitle.guidePage",
    defaultMessage: "Meglévő ügyfeleknek"
  },
  navConnection: {
    id: "navConnection.cardTitle.guidePage",
    defaultMessage: "NAV adatkapcsolat"
  }
});

const GuidePage = ({}) => {
  const { mainCompany } = useProfileData();
  const [newUserGuideOpen, setNewUserGuideOpen] = useState(false);
  const [existingUserGuideOpen, setExistingUserGuideOpen] = useState(false);
  const [navConnectionGuideOpen, setNavConnectionGuideOpen] = useState(false);
  const isNewUser = mainCompany?.flexipayOnboardingStatus !== "COMPLETED";
  return (
    <PageLayout title={"Használati segéd"}>
      <Grid container direction={"row"} spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper
            style={{
              width: "310px",
              height: "220px",
              display: "flex",
              cursor: "pointer"
            }}
            onClick={() => setNewUserGuideOpen(true)}
          >
            <div style={{ alignContent: "center", margin: "auto" }}>
              <img src={newUserIcon} /> <Typography>Új ügyfeleknek</Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            style={{
              width: "310px",
              height: "220px",
              display: "flex",
              cursor: "pointer",
              opacity: isNewUser ? 0.5 : 1,
              pointerEvents: isNewUser ? "none" : "auto"
            }}
            onClick={() => setExistingUserGuideOpen(true)}
          >
            <div style={{ alignContent: "center", margin: "auto" }}>
              <img src={existingUserIcon} />
              <Typography>Meglévő ügyfeleknek</Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            style={{
              width: "310px",
              height: "220px",
              display: "flex",
              cursor: "pointer",
              opacity: isNewUser ? 0.5 : 1,
              pointerEvents: isNewUser ? "none" : "auto"
            }}
            onClick={() => setNavConnectionGuideOpen(true)}
          >
            <div style={{ alignContent: "center", margin: "auto" }}>
              <img src={navConnectionIcon} />
              <Typography>NAV adatkapcsolat</Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <NewUserGuideDialog
        open={newUserGuideOpen}
        onClose={() => setNewUserGuideOpen(false)}
      />
      <ExistingUserGuideDialog
        open={existingUserGuideOpen}
        onClose={() => setExistingUserGuideOpen(false)}
      />
      <FlexiNavConnectionHelpDialog
        open={navConnectionGuideOpen}
        onClose={() => setNavConnectionGuideOpen(false)}
      />
    </PageLayout>
  );
};

export default GuidePage;
