import React, { useMemo, useState } from "react";
import Typography from "@material-ui/core/Typography";
import FlexiForm, { Fields } from "../../FlexiComponents/FlexiForm";
import FlexiInfoBar from "../../FlexiComponents/FlexiInfoBar";
import { FormattedHTMLMessage } from "react-intl";
import { useMutator, useProfileData } from "../../hooks/useApi";
import {
  useErrorOccurredNotification,
  useSaveSuccessNotification
} from "../../hooks/useNotificationState";
import payNav from "../../images/pay_nav.svg";
import PageLayout from "../../components/PageLayout";
import { defineMessages } from "react-intl.macro";
import { getUrlWithCompanyId } from "../../utils/urlHelpers";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Link from "../../FlexiComponents/FlexiLink";
import Paper from "@material-ui/core/Paper";
import usePopupState from "../../hooks/usePopupState";
import FlexiNavConnectionHelpDialog from "../../FlexiComponents/FlexiNavConnectionDialog";
import {
  ROLE_FLEXIPAY_INVOICE_MANAGER,
  ROLE_FLEXIPAY_SUPER_ADMIN
} from "../../roles";
import useHasPermission from "../../components/RoleProvider/useHasPermission";
import { Box, FormHelperText } from "@material-ui/core";
import infoIcon from "../../images/redesignImages/info-icon.svg";
import NavSyncDialog from "../../components/NavSyncDialog";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";

const messages = defineMessages({
  pageTitle: {
    id: "navData.pageTitle",
    defaultMessage: "NAV adatkapcsolat"
  },
  navText: {
    id: "label.navText",
    defaultMessage: `A FlexiBill-ben lehetőséged nyílik a számlaadatok rögzítésének automatizálására. Ehhez nincs más dolgod, mint létrehozni az adatkapcsolatot fiókod és a NAV Online Számla rendszere között, így automatikusan betöltjük azokat a számláidat, melyek:<br><br>
<ul><li>
olyan belföldi vevő(k) felé lettek kiállítva, akikkel faktorszerződésed van
</li><li>
adatai a jogszabálynak megfelelően beküldésre kerültek a NAV felé.</li></ul>`
  },
  navText3: {
    id: "label.navText3",
    defaultMessage: `A két rendszert a Technikai felhasználó adatainak megadásával kötheted össze. Részletes segédletet `
  },
  navTextLink: {
    id: "label.navTextLink",
    defaultMessage: `ide kattintva `
  },
  navText2: {
    id: "label.navText2",
    defaultMessage: `Elsőként Ügyfélkapu regisztrációval kell rendelkezned, ezen keresztül tudsz regisztrálni a NAV Online Számla oldalán egy “elsődleges felhasználót” és egy “technikai felhasználót”.<br><br>
Az elsődleges felhasználó létrehozása hitelesíted vállalkozásod a NAV felé, a technikai felhasználó segítségével pedig megkapod azokat a belépési adatokat, amelyekre szükséged van az adatok lekéréséhez.`
  },
  navTitle2: {
    id: "label.navTitle2",
    defaultMessage: `Hogyan köthetem össze a két rendszert?`
  },
  navTitle3: {
    id: "label.navTitle3",
    defaultMessage: `NAV szinkronizálás használata`
  },
  navTitle1: {
    id: "label.navTitle1",
    defaultMessage: `Miért érdemes létrehozni a NAV adatkapcsolatot?`
  },
  navText4: {
    id: "label.navText4",
    defaultMessage: `olvashatsz.`
  },
  navText5: {
    id: "label.navText5",
    defaultMessage: `Azon vevőidhez tartozó számláidat, akikkel van faktorszerződésed, automatikusan
letöltjük a NAV rendszeréből és megjelenítjük az Előkészített számlák között.
Az Előkészített számlák közül  kiválaszthatod, hogy mely számlákra
kérsz finanszírozást.`
  },
  navDataFormTitle: {
    id: "label.navDataFormTitle",
    defaultMessage: "Technikai felhasználó adatai"
  }
});

const NavDataPage = ({ navData, dialogOpen, onToggleFullScreenClick }) => {
  const [navDialogOpen, openNavDialog, hideNavDialog] = usePopupState(
    "NavDialog"
  );
  const [navSyncDialogOpen, setNavSyncDialogOpen] = useState(false);
  const [put, putLoading] = useMutator("PUT");
  const [get] = useMutator("GET");
  const notifyError = useErrorOccurredNotification();
  const notifySuccessSave = useSaveSuccessNotification();
  const { mainCompany, reloadCompany } = useProfileData();
  const hasPermission = useHasPermission();
  const isInvoiceManager = hasPermission([
    ROLE_FLEXIPAY_INVOICE_MANAGER,
    ROLE_FLEXIPAY_SUPER_ADMIN
  ]);
  const navSyncFields = useMemo(() => {
    return [
      [
        {
          fieldType: "checkbox",
          name: `loadNavOutgoingInvoicesToFlexiPay`,
          label: "Számlák lehívása a NAV Online számla rendszeréből",
          disabled: isInvoiceManager
        }
      ]
    ];
  }, []);
  const navDataFields1 = useMemo(() => {
    return [
      [
        {
          fieldType: "text",
          name: `userName`,
          label: "Technikai felhasználó felhasználóneve",
          required: true,
          disabled: isInvoiceManager
        }
      ],
      [
        {
          fieldType: "text",
          type: "password",
          name: `password`,
          label: "Texhnikai felhasználó jelszava",
          required: !mainCompany.isNavUserDataFilled,
          disabled: isInvoiceManager
        }
      ]
    ];
  }, [mainCompany]);
  const navDataFields2 = useMemo(() => {
    return [
      [
        {
          fieldType: "text",
          name: `xmlSignKey`,
          label: "XML aláírókulcs",
          required: true,
          disabled: isInvoiceManager
        }
      ],
      [
        {
          fieldType: "text",
          name: `xmlExchangeKey`,
          label: "XML cserekulcs",
          required: true,
          disabled: isInvoiceManager
        }
      ]
    ];
  }, []);
  return (
    <PageLayout
      pay
      insidePage
      suppressTourButton
      titleMessage={messages.pageTitle}
      loading={putLoading}
    >
      <Grid container>
        <Grid item xs={12} sm={6} style={{ paddingRight: "10px" }}>
          <Typography
            variant="h5"
            style={{ marginTop: "1rem", marginBottom: "16px" }}
          >
            <FormattedHTMLMessage {...messages.navTitle1} />
          </Typography>
          <Typography variant={"body2"}>
            <FormattedHTMLMessage {...messages.navText} />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{ paddingLeft: "10px" }}>
          <Typography
            variant="h5"
            style={{ marginTop: "1rem", marginBottom: "16px" }}
          >
            <FormattedHTMLMessage {...messages.navTitle2} />
          </Typography>
          <Typography variant={"body2"}>
            <FormattedHTMLMessage {...messages.navText2} />
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "24px" }}>
          <FlexiInfoBar info>
            <Typography variant={"body1"}>
              <FormattedHTMLMessage {...messages.navText3} />
              <Link
                style={{
                  fontWeight: "bold",
                  color: "#2227AC",
                  textDecoration: "none"
                }}
                onClick={openNavDialog}
              >
                <FormattedHTMLMessage {...messages.navTextLink} />
              </Link>
              <FormattedHTMLMessage {...messages.navText4} />
            </Typography>
          </FlexiInfoBar>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} style={{ marginTop: 16, marginBottom: 16 }}>
          <FlexiForm
            FinalFormFormProps={{
              initialValues: mainCompany?.navUserData,
              onSubmit: async values => {
                const { data, error } = await put(
                  getUrlWithCompanyId(`/settings/nav-user`),
                  values
                );
                if (error) {
                  notifyError(error);
                }
                if (data) {
                  notifySuccessSave();
                  await reloadCompany();
                }
              }
            }}
            render={() => {
              return (
                <>
                  <Paper
                    style={{
                      padding: "32px 40px 40px"
                    }}
                  >
                    <Typography variant="h5" style={{ marginBottom: 14 }}>
                      <FormattedHTMLMessage {...messages.navDataFormTitle} />
                    </Typography>
                    <Grid item xs={12}>
                      <Fields config={navDataFields1} />
                      {mainCompany.isNavUserDataFilled && (
                        <FormHelperText>
                          A jelszó biztonsági okokból nincs megjelenítve
                        </FormHelperText>
                      )}
                      <Fields config={navDataFields2} />
                    </Grid>
                    <Grid item style={{ marginTop: "32px" }}>
                      <Button disabled={isInvoiceManager} type={"submit"}>
                        Ellenőrzés és Mentés
                      </Button>
                    </Grid>
                  </Paper>
                </>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ marginTop: 16, marginBottom: 16 }}>
          <FlexiForm
            FinalFormFormProps={{
              initialValues: mainCompany?.defaultSettings,
              onSubmit: async values => {
                const { data, error } = await put(
                  getUrlWithCompanyId(`/settings/default-settings`),
                  values
                );
                if (error) {
                  notifyError(error);
                }
                if (data) {
                  notifySuccessSave();
                  await reloadCompany();
                }
              }
            }}
            render={({ values }) => {
              return (
                <Paper
                  style={{
                    padding: "32px 40px 40px"
                  }}
                >
                  <Grid container>
                    <Grid item style={{ display: "inline-flex" }}>
                      <img
                        alt={"payNav"}
                        src={payNav}
                        style={{ paddingRight: "10px", paddingLeft: "2px" }}
                      />
                      <Typography variant="h5">
                        <FormattedHTMLMessage {...messages.navTitle3} />
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ paddingBottom: "16px", paddingTop: "16px" }}
                    >
                      <Paper
                        elevation={0}
                        style={{
                          // border: "1px solid gray",
                          paddingLeft: "1rem",
                          backgroundColor: "rgb(244 247 254)",
                          height: "100%",
                          display: "flex"
                        }}
                      >
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          style={{ gap: "19px" }}
                        >
                          <img src={infoIcon} />
                          <Typography>
                            A rendszer 2 óránként frissít/szinkronizál.
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Fields config={navSyncFields} />
                    </Grid>
                    <Grid item style={{ paddingBottom: "32px" }}>
                      <Typography
                        variant={"body1"}
                        style={{ color: "#858585", marginLeft: "32px" }}
                      >
                        <FormattedHTMLMessage {...messages.navText5} />
                      </Typography>
                    </Grid>
                    {mainCompany?.defaultSettings
                      .loadNavOutgoingInvoicesToFlexiPay && (
                      <Grid item style={{ marginBottom: "32px" }}>
                        <Button
                          variant={"outlined"}
                          onClick={() => setNavSyncDialogOpen(true)}
                        >
                          Utolsó 30 nap szinkronizálása
                        </Button>
                      </Grid>
                    )}
                    <Grid item style={{ display: "inline-flex" }}>
                      <Button
                        style={{ marginRight: "16px" }}
                        disabled={isInvoiceManager}
                        type={"submit"}
                      >
                        Mentés
                      </Button>
                      {mainCompany?.defaultSettings
                        .loadNavOutgoingInvoicesToFlexiPay && (
                        <Button
                          onClick={() =>
                            get(getUrlWithCompanyId("/invoice/fetch/nav")).then(
                              ({ data, error }) => {
                                if (data) {
                                  notifySuccessSave({
                                    text: "A szinkronizálás elkezdődött!"
                                  });
                                } else {
                                  notifyError(
                                    selectErrorNotificationMessage(error)
                                  );
                                }
                              }
                            )
                          }
                        >
                          Frissítés
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              );
            }}
          />
        </Grid>
        <FlexiNavConnectionHelpDialog
          open={navDialogOpen}
          onClose={hideNavDialog}
        />
        <NavSyncDialog
          open={navSyncDialogOpen}
          onClose={() => setNavSyncDialogOpen(false)}
        />
      </Grid>
    </PageLayout>
  );
};

export default NavDataPage;
