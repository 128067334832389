import SimpleTextField from "./SimpleTextField";
import React, { useState } from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

const Password = props => {
  const [showPass, setShowPass] = useState(false);

  const adornment = showPass ? <VisibilityOffIcon /> : <VisibilityIcon />;

  return (
    <SimpleTextField
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPass(!showPass)}>
              {adornment}
            </IconButton>
          </InputAdornment>
        )
      }}
      type={showPass ? "text" : "password"}
      {...props}
    />
  );
};

export default Password;
