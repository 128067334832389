import React from "react";
import { compose, withState, withHandlers } from "recompose";
import PropTypes from "prop-types";
import styled from "styled-components";
import Typography from "../FlexiTypography/index.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";
import ExpandIcon from "@material-ui/icons/ExpandLess";
import Link from "../FlexiLink/index.js";
import expandArrow from "./Polygon 1arrow.svg";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { breakpoint } from "../utils/breakpoints";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { lighten } from "polished";

const Wrapper = styled.div`
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  ${breakpoint("xs", "md")`
    margin-left: 15px;
    margin-bottom: 20px;
    display: ${props => (props.hideOnMobile ? "none" : "flex")};
  `} &.margin-helper {
    margin-bottom: 0;
  }

  &.border {
    border-top: 1px solid grey;
  }

  a {
    text-decoration: none;

    &:focus {
      outline: 0;
    }

    &:visited,
    &:-webkit-any-link {
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
      color: #fff;
    }
  }

  svg {
    font-size: 28px;
  }
`;

const OuterWrapper = styled.div`
  && {
    ${breakpoint("md")`
       width: 300px;
    `} &:focus {
      outline: 0;
    }
  }

  && > div:nth-of-type(1) {
    height: 50px;
  }
`;

const Expander = styled(ExpandIcon)`
  && {
    color: white;
    width: 1.5rem;
    position: absolute;
    font-size: 27px;
    transform: rotate(${props => (props.expanded ? "0deg" : "180deg")});
    transition: transform 0.2s ease;
    bottom: ${props => (props.appbarcomponent ? "15px" : "-17px")};
    right: ${props => (props.appbarcomponent ? "0" : "calc(50% - 5px)")};
  }
`;

const Loader = styled(CircularProgress)`
  && {
    color: #fff;
    position: absolute;
    width: 34px !important;
    height: 34px !important;
  }
`;

/* eslint-disable */
const AvatarCircle = styled(Avatar)`
  && {
    width: ${props => (props.reduceheight ? "28px" : "34px")};
    height: ${props => (props.reduceheight ? "28px" : "34px")};
    svg {
      ${props => (props.logoPath ? "28px" : "font-size: 19px;")};
    }
  }
`;
/* eslint-enable */

const CompanyChooserMenuItem = styled(
  ({
    avatarSrc,
    icon,
    name,
    onClick,
    className,
    hideOnMobile,
    width,
    href
  }) => (
    <Link style={{ textDecoration: "none" }} to={href}>
      <Wrapper
        onClick={onClick}
        className={className}
        hideOnMobile={hideOnMobile}
        style={{
          padding: "8px 16px",
          alignItems: "center",
          marginLeft: isWidthDown("md", width) ? 0 : ""
        }}
      >
        {!icon ? (
          <img
            src={avatarSrc}
            style={{
              width: "28px",
              height: "28px",
              fontSize: "1rem"
            }}
          />
        ) : (
          icon
        )}
        <Typography
          variant="body1"
          color="secondary"
          style={{ color: "#02BE6F", marginLeft: 10, lineHeight: "1.1" }}
        >
          {name}
        </Typography>
      </Wrapper>
    </Link>
  )
)`
  cursor: pointer;
  padding: 0 1rem;
  border-radius: 0px;
  &:hover {
    background: ${props =>
      props.appbarbgcolor ? lighten(0.1, props.appbarbgcolor) : "grey"};
  }
`;

const ActiveAccount = styled.span`
  position: absolute;
  bottom: -2px;
  right: -3px;
  width: 10px;
  height: 10px;
  border-radius: 100% 100%;
  background-color: #8bc34a;
  border: 1px solid rgb(74, 74, 74);
`;

const CurrentCompany = ({ logoPath, avatarUrl, reduceheight, avatarSrc }) => (
  <Link to={avatarUrl} style={{ display: "flex", position: "relative" }}>
    <AvatarCircle src={logoPath || avatarSrc} reduceheight={reduceheight} />
    {reduceheight && <ActiveAccount />}
  </Link>
);

const FlexiCompanyChooser = ({
  username,
  avatarSrc,
  company,
  chooserOpen,
  companies,
  onCompanySelected,
  handleClick,
  handleAppsClick,
  popoverOnClick,
  toggleAppsOpen,
  appsIconShow,
  appsIconColor,
  anchorEl,
  updateAnchorEl,
  logoPath,
  appbarcomponent,
  appbarbgcolor,
  handleClickAway,
  avatarUrl,
  menuItems,
  newCompanyLink,
  width
}) => (
  <OuterWrapper role="button" tabIndex={0}>
    <Wrapper
      appbarcomponent={appbarcomponent.toString()}
      companies={companies}
      menuItems={menuItems}
    >
      {/* eslint-enable */}
      <CurrentCompany
        logoPath={logoPath}
        avatarUrl={avatarUrl}
        avatarSrc={avatarSrc}
      />
      {username || company ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: "20px",
            width: "100%"
          }}
          onClick={handleClick}
        >
          {company && (
            <Typography
              fontSize={12}
              variant="body1"
              style={{
                fontWeight: "bold",
                color: "#fff",
                lineHeight: "1.1",
                marginBottom: "2px",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                paddingRight: "24px"
              }}
            >
              {company}
            </Typography>
          )}
          {username && (
            <Link to="/profile-settings/profile-data">
              <Typography
                color="secondary"
                style={{
                  fontSize: "12px",
                  lineHeight: "1",
                  color: "#fff"
                }}
              >
                {username}
              </Typography>
            </Link>
          )}
          {chooserOpen ? (
            <ClickAwayListener
              onClickAway={handleClickAway}
              touchEvent={!isWidthDown("md", width)}
            >
              <Expander
                expandIcon={<img src={expandArrow} />}
                onClick={handleClick}
                expanded={chooserOpen ? chooserOpen.toString() : undefined}
                appbarcomponent={appbarcomponent.toString()}
              />
            </ClickAwayListener>
          ) : (
            <Expander
              onClick={handleClick}
              expanded={chooserOpen ? chooserOpen.toString() : undefined}
              appbarcomponent={appbarcomponent.toString()}
            />
          )}
        </div>
      ) : (
        <Loader />
      )}
    </Wrapper>
    <Collapse
      in={chooserOpen}
      style={{
        backgroundColor: appbarbgcolor || "#fff",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        marginTop: "16px",
        overflow: "scroll",
        maxHeight: "200px"
      }}
    >
      {companies.length > 0 &&
        companies.map((item, i) => {
          return (
            <CompanyChooserMenuItem
              key={`${item.id}-i`}
              avatarSrc={item.logoPath || avatarSrc}
              icon={item.icon}
              hideOnMobile={item.hideOnMobile}
              name={item.name}
              onClick={() => {
                onCompanySelected(item);
                handleClickAway();
              }}
              className={`margin-helper ${item.border && "border"}`}
              href={item.href}
            />
          );
        })}
      {newCompanyLink && (
        <Link to={newCompanyLink} style={{ textDecoration: "none" }}>
          <Typography
            variant="body1"
            style={{
              color: "#02BE6F",
              paddingLeft: "26px",
              lineHeight: "1.1",
              paddingTop: "15px",
              paddingBottom: "15px"
            }}
          >
            + vállalkozás hozzáadása
          </Typography>
        </Link>
      )}
      {menuItems.length > 0 &&
        menuItems.map((item, i) => {
          return (
            <CompanyChooserMenuItem
              key={`${item.id}-i`}
              icon={item.icon}
              hideOnMobile={item.hideOnMobile}
              name={item.name}
              className={`margin-helper ${item.border && "border"}`}
              onClick={() => onCompanySelected(item)}
              href={item.href}
            />
          );
        })}
    </Collapse>
  </OuterWrapper>
);

const enhance = compose(
  withState("chooserOpen", "setChooserOpen", false),
  withState("appsOpen", "setAppsOpen", false),
  withState("anchorEl", "updateAnchorEl", null),
  withHandlers({
    toggleChooser: ({
      companies,
      menuItems,
      setChooserOpen,
      chooserOpen
    }) => () => {
      (Boolean(companies.length) || Boolean(menuItems.length)) &&
        setChooserOpen(!chooserOpen);
    },
    toggleAppsOpen: ({ setAppsOpen, appsOpen }) => () => {
      setAppsOpen(!appsOpen);
    }
  }),
  withHandlers({
    handleClick: ({ toggleChooser }) => () => {
      toggleChooser();
    },
    handleClickAway: ({ setChooserOpen }) => () => {
      setChooserOpen(false);
    },
    handleAppsClick: ({ toggleAppsOpen, updateAnchorEl }) => e => {
      updateAnchorEl(e.target);
      toggleAppsOpen();
    }
  })
);

FlexiCompanyChooser.propTypes = {
  logoPath: PropTypes.string,
  username: PropTypes.string,
  company: PropTypes.string,
  chooserOpen: PropTypes.bool,
  handleClick: PropTypes.func,
  companies: PropTypes.array,
  onCompanySelected: PropTypes.func,
  appbarcomponent: PropTypes.bool,
  menuItems: PropTypes.array,
  newCompanyLink: PropTypes.string,
  avatarSrc: PropTypes.string
};

CurrentCompany.propTypes = {
  logoPath: PropTypes.string,
  reduceheight: PropTypes.bool,
  avatarSrc: PropTypes.string
};

export default enhance(FlexiCompanyChooser);
