import React from "react";
import { FormHelperText } from "@material-ui/core";

const withCheckboxErrorMessage = WrappedComponent => props => {
  const error = props.meta && props.meta.error;
  const touched = props.meta && props.meta.touched;
  const FormHelperTextProps = props.FormHelperTextProps || {};

  return (
    <>
      <WrappedComponent {...props} error={error} />
      {touched &&
        error && (
          <FormHelperText
            error
            {...FormHelperTextProps}
            style={{
              position: "absolute",
              bottom: "-12px",
              left: "42px"
            }}
          >
            {error}
          </FormHelperText>
        )}
    </>
  );
};
export default withCheckboxErrorMessage;
