import FlexiProgressBar from "../../FlexiComponents/FlexiProgressBar";
import { useIntl } from "react-intl";
import React from "react";
import { makeCurrencyValueFormatter } from "../../utils/makeCurrencyValueFormatter";
import { defineMessages } from "react-intl.macro";

import IconButton from "@material-ui/core/IconButton";
import { Box } from "@material-ui/core";
import FlexiExpandableRemoteList from "../../FlexiComponents/FlexiExpandableRemoteList";
import { useAgreements } from "../../hooks/useApi";
import { useHistory } from "react-router-dom";
import { getFlexipayUrl } from "../../utils/urlHelpers";

const columnMessages = defineMessages({
  supplierName: {
    id: "listColumnHeader.salesLimitList.supplierName",
    defaultMessage: "Szállító neve"
  },
  customerName: {
    id: "listColumnHeader.salesLimitList.customerName",
    defaultMessage: "Vevő neve"
  },
  limitAmount: {
    id: "listColumnHeader.salesLimitList.limitAmount",
    defaultMessage: "Limit"
  },
  type: {
    id: "listColumnHeader.salesLimitList.type",
    defaultMessage: "Típus"
  },
  bank: {
    id: "listColumnHeader.salesLimitList.bank",
    defaultMessage: "Bank"
  },
  limitUsage: {
    id: "listColumnHeader.salesLimitList.limitUsage",
    defaultMessage: "Kihasználtság"
  }
});

const makeUsageRenderer = intl => params => {
  const currencyFormatter = makeCurrencyValueFormatter(intl);
  const { push: redirect } = useHistory();
  return (
    <Box display={"flex"} mt={"8px"} width={"100%"}>
      <IconButton onClick={() => redirect("/app/limit-change-list")}>
        -
      </IconButton>
      <FlexiProgressBar
        tooltip={`${currencyFormatter(params)} a kihasználtság`}
        percentage={(params.data.limitUsed / params.data.limitAmount) * 100}
      />
      <IconButton onClick={() => redirect("/app/limit-change-list")}>
        +
      </IconButton>
    </Box>
  );
};

const SalesLimitList = ({ localItems, hideSearchBar }) => {
  const intl = useIntl();
  const { data: agreements, isValidating: loading } = useAgreements();
  const showNoData = !loading && !Boolean(agreements?.length);
  const showList = !loading && Boolean(agreements?.length);

  const getColumns = intl => {
    const currencyFormatter = makeCurrencyValueFormatter(intl);

    return [
      {
        headerName: intl.formatMessage(columnMessages.supplierName),
        field: "supplierName",
        sortable: true,
        autoHeight: true,
        resizable: true,
        mobileCellXs: 12,
        cellClass: "cell-wrap-text",
        filterConfig: {
          type: "text",
          collapsed: false,
          filterParam: "supplierName",
          order: 1
        }
      },
      {
        headerName: intl.formatMessage(columnMessages.customerName),
        field: "customerName",
        sortable: true,
        autoHeight: true,
        resizable: true,
        mobileCellXs: 12,
        filterConfig: {
          type: "text",
          collapsed: false,
          filterParam: "customerName",

          order: 2
        }
      },
      {
        headerName: intl.formatMessage(columnMessages.limitAmount),
        field: "limitAmount",
        valueFormatter: currencyFormatter,
        sortable: true,
        autoHeight: true,
        cellClass: "cell-wrap-text",
        mobileCellXs: 12
      },
      {
        headerName: intl.formatMessage(columnMessages.type),
        valueGetter: ({ data }) => data.limitAmount - data.limitUsed || null,
        valueFormatter: currencyFormatter,
        sortable: true,
        autoHeight: true,
        cellClass: "cell-wrap-text",
        mobileCellXs: 12,
        filterConfig: {
          type: "text",
          collapsed: false,
          filterParam: "type",

          order: 3
        }
      },
      {
        headerName: intl.formatMessage(columnMessages.bank),
        valueFormatter: currencyFormatter,
        sortable: true,
        autoHeight: true,
        cellClass: "cell-wrap-text",
        mobileCellXs: 12,
        filterConfig: {
          type: "text",
          filterParam: "bank",
          collapsed: false,
          order: 5
        }
      },
      {
        headerName: intl.formatMessage(columnMessages.limitUsage),
        field: "modifiedAt",
        cellRendererFramework: makeUsageRenderer(intl),
        sortable: true,
        autoHeight: true,
        mobileCellXs: 12
      }
    ];
  };

  const columns = getColumns(intl);

  return (
    <>
      <FlexiExpandableRemoteList
        title={"asd"}
        columnDefs={columns}
        url={getFlexipayUrl("/sales/agreement")}
        suppressExpand
        hideSearchBar={true}
        hidePager={false}
        advancedFilterEnabled
        rowsPerPageOptions={[50]}
        showMoreLimit={50}
      />
    </>
  );
};

export default SalesLimitList;
