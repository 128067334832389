import React from "react";
import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const StatusDescriptionDialog = ({
  title,
  open,
  onClose,
  statuses,
  renderTop
}) => {
  return (
    <FlexiDialog title={title} open={open} onClose={onClose} size={"small"}>
      {renderTop && renderTop()}
      {statuses.map(status => (
        <Box display={"flex"} mb={3} style={{ gap: 12 }}>
          <div>{status.icon}</div>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography variant={"h6"}>{status.text}</Typography>
            <Typography variant={"body2"}>{status.description}</Typography>
          </Box>
        </Box>
      ))}
    </FlexiDialog>
  );
};

export default StatusDescriptionDialog;
