import { composeValidators, required as requiredFn } from "../validators";
import { Field } from "react-final-form";
import React from "react";

const BaseField = ({ label, required, validate, ...rest }) => {
  const validators = [validate];

  if (required) {
    validators.push(requiredFn);
  }

  return (
    <Field
      FormHelperTextProps={{ classes: { error: "field-error" } }}
      label={required && label && label.length ? `${label}*` : label}
      validate={composeValidators(...validators)}
      {...rest}
    />
  );
};

export default BaseField;
