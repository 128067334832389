import React from "react";
import styled from "styled-components";
import FlexiDialog from "../FlexiDialog/index.js";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FlexiTypography from "../FlexiTypography";
import Box from "@material-ui/core/Box";

const Loader = styled(props => (
  <div {...props}>
    <div id="download-preloader">
      <div id="download-loader" />
    </div>
  </div>
))`
  && {
    transform: scale(-0.5);
    height: 100px;
  }

  #download-preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  #download-loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #9370db;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  #download-loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #ba55d3;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }
  #download-loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #ff00ff;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const DownloadDialog = ({
  open,
  onClose,
  title = "Számlák letöltése",
  downloadButtonText = "Letöltés",
  cancelButtonText = "Mégse",
  beforeDownloadDescription = "A számlák letöltése eltarthat egy ideig.",
  downloadingDescription = "A számlák letöltése folyamatban van.",
  downloading,
  onDownloadClick,
  onCancelClick
}) => {
  return (
    <FlexiDialog
      open={open}
      title={title}
      onClose={onClose}
      dialogActions={
        <Grid container spacing={1} justify={"flex-end"}>
          <Grid item>
            <Button onClick={onClose} variant={"text"}>
              {cancelButtonText}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onDownloadClick} disabled={downloading}>
              {downloadButtonText}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Box>
        {downloading && <Loader />}
        <FlexiTypography align={"center"}>
          {downloading ? downloadingDescription : beforeDownloadDescription}
        </FlexiTypography>
      </Box>
    </FlexiDialog>
  );
};

export default DownloadDialog;
