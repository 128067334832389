/**
 * @render react
 * @name FlexiLink
 * @description FlexiLink
 * @example
 * <BrowserRouter>
 <FlexiLink to={"url/to/page"}>Link text</FlexiLink>
 </BrowserRouter>
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const NavLink = styled(Link)`
  //text-decoration: none;
  font-size: 14px;
  color: #0045DE;
  &:focus {
    outline: ${props => props.disablefocus && 0};
  }
`;

const SimpleLink = styled.a`
  font-family: 'Nunito-Regular', Helvetica, Arial, sans-serif;
  font-size: 14px;
  //text-decoration: none;
  color: #0045DE;
  cursor: pointer;
  &:focus {
    outline: ${props => props.disablefocus && 0};
  }
`;

const FlexiLink = ({...props}) =>
    props.to ? (
        <NavLink {...props} />
    ) : (
        <SimpleLink {...props} />
    );

FlexiLink.propTypes = {
    disablefocus: PropTypes.bool,
    to: PropTypes.any,
    onClick: PropTypes.func,
};

export default FlexiLink;
