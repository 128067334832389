import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import { defineMessages } from "react-intl.macro";
import { useIntl } from "react-intl";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import React from "react";
import SuccessImg from "../../images/bill/bill_vigyor_80px.svg";

const messages = defineMessages({
  title: {
    id: "dialogTitle.factorProcessStarted",
    defaultMessage: "Köszönjük!"
  },
  description: {
    id: "dialogDescription.factorProcessStarted",
    defaultMessage: "A faktorálási folyamatot elindítottuk!"
  },
  button: {
    id: "dialogTitle.button",
    defaultMessage: "Rendben"
  }
});

/*
 *
 * Sok hasonló dialogunk van, ez az amikor nincs faktor ertesitö
 *
 *
 * */

const FactorProcessStartedDialog = ({ open, onClose }) => {
  const intl = useIntl();

  return (
    <FlexiDialog
      open={open}
      onClose={onClose}
      title={intl.formatMessage(messages.title)}
      dialogActions={
        <Button onClick={onClose} style={{ margin: "auto" }}>
          {intl.formatMessage(messages.button)}
        </Button>
      }
      imgSrc={SuccessImg}
      style={{ minWidth: 500 }}
    >
      <Typography>{intl.formatMessage(messages.description)}</Typography>
    </FlexiDialog>
  );
};

export default FactorProcessStartedDialog;
