import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
  Collapse,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Box from "@material-ui/core/Box";
import FlexiForm, { Fields, makeExternalFormSubmit } from "../FlexiForm";
import FlexiTypography from "../FlexiTypography/index.js";
import filterIcon from "./filter_alt-24px.svg";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";

const formId = "advancedFilter";
const submitForm = makeExternalFormSubmit(formId);

let formInstance;

const LAST_VALUES_KEY = "advancedFilterLastValues-";
const resetStoredFilterData = () => {
  Object.keys(window.localStorage)
    .filter(key => key.startsWith(LAST_VALUES_KEY))
    .forEach(key => window.localStorage.removeItem(key));
};

const mapKeys = (obj, mapper) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [mapper(value, key)]: value
    }),
    {}
  );

const getFilterKeyValueObject = values => {
  const getValueObject = data => {
    return Object.keys(data).reduce((acc, key) => {
      const item = data[key];

      // multiselect
      if (Array.isArray(item)) {
        acc[`filter[${key}][in]`] = item.join(",");
        return acc;
      } else if (typeof item === "object" && !item.to && !item.from) {
        return {
          ...acc,
          ...getValueObject(
            mapKeys(item, (value, itemKey) => `${key}.${itemKey}`)
          )
        };
      } else {
        if (item) {
          if (item.from) {
            acc[`filter[${key}][gte]`] = item.from;
          }

          if (item.to) {
            acc[`filter[${key}][lte]`] = item.to;
          }

          if (!item.from && !item.to) {
            acc[`filter[${key}][eq]`] = item;
          }
        } else {
          console.warn(
            `Missing value for "${key}" key in FlexiAdvancedFilter.`
          );
        }

        return acc;
      }
    }, {});
  };

  return getValueObject(values);
};
const hashCode = s =>
  s.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
const FlexiAdvancedFilter = ({
  columnDefs,
  groupsOrderConfig,
  onFilter,
  onDownload,
  onExport,
  deleteFilterAndRefresh
}) => {
  const filterLastValuesStorageKey = useMemo(
    () => `${LAST_VALUES_KEY}${hashCode(JSON.stringify(columnDefs))}`,
    [columnDefs]
  );

  const initialValues = useMemo(() => {
    const stored = window.localStorage.getItem(filterLastValuesStorageKey);

    return stored && stored.length > 2 ? JSON.parse(stored) : undefined;
  }, []);

  const storeFormValues = useCallback(values => {
    const withoutEmptyArray = Object.keys(values).reduce((acc, key) => {
      const value = values[key];
      if (Array.isArray(value)) {
        if (value.length) {
          acc[key] = value;
        }
      } else {
        acc[key] = value;
      }

      return acc;
    }, {});

    window.localStorage.setItem(
      filterLastValuesStorageKey,
      JSON.stringify(withoutEmptyArray)
    );
  }, []);

  const [open, setOpen] = useState(Boolean(initialValues));
  const toggleFilters = useCallback(() => setOpen(!open), [open, setOpen]);

  useEffect(() => {
    if (initialValues) {
      onFilter(getFilterKeyValueObject(initialValues));
    }
  }, []);

  const filterFields = useMemo(() => {
    const fields = columnDefs.reduce(
      (acc, colDef) => {
        if (colDef.filterConfig) {
          const fieldType = colDef.filterConfig.type;
          const name = colDef.filterConfig.filterParam || colDef.field;
          const label = colDef.filterConfig.label || colDef.headerName;
          const order = colDef.filterConfig.order;
          const range = colDef.filterConfig.range;
          const group = colDef.filterConfig.group;

          const item = {
            ...colDef.filterConfig,
            fieldType: range ? "range" : fieldType,
            type: range ? fieldType : undefined,
            name,
            label,
            order,
            group
          };

          if (!name) {
            throw new Error(`filterParam, or field not found!`);
          }

          if (!colDef.filterConfig.collapsed) {
            acc.visible[0].push(item);
          } else {
            acc.collapsed.push(item);
          }
        }

        return acc;
      },
      { visible: [[]], collapsed: [] }
    );

    fields.visible[0] = fields.visible[0].sort((a, b) => {
      const aHas = typeof a.order !== "undefined";
      const bHas = typeof b.order !== "undefined";
      return bHas - aHas || (aHas === true && a.order - b.order) || 0;
    });

    // grouppolas
    fields.collapsed = fields.collapsed.reduce((acc, item) => {
      const existingGroupIndex = acc.findIndex(
        group => group.groupName === item.group
      );

      if (existingGroupIndex !== -1) {
        acc[existingGroupIndex].fieldsConfig.push([item]);
      } else {
        acc.push({
          fieldType: "inputGroup",
          groupName: item.group,
          fieldsConfig: [[item]]
        });
      }

      return acc;
    }, []);

    // Ami nem range, azokat egymas melle pakoljuk
    fields.collapsed = fields.collapsed.map(inputGroup => {
      const rangeInputs = inputGroup.fieldsConfig.filter(conf => conf[0].range);
      const simple = inputGroup.fieldsConfig
        .filter(conf => !conf[0].range)
        .map(conf => conf[0]);

      const twoColumns = [];
      while (simple.length > 0) {
        twoColumns.push(simple.splice(0, 2));
      }

      inputGroup.fieldsConfig = [...rangeInputs, ...twoColumns];

      return inputGroup;
    });

    if (groupsOrderConfig) {
      fields.collapsed = fields.collapsed.sort(
        (a, b) =>
          groupsOrderConfig[a.groupName] - groupsOrderConfig[b.groupName]
      );
    }

    const chunks = [];
    while (fields.collapsed.length > 0) {
      chunks.push(fields.collapsed.splice(0, 2));
    }

    fields.collapsed = chunks;

    return fields;
  }, [columnDefs]);
  const ActionButtons = ({
    onDeleteClick,
    onFilterClick,
    onToggleClick,
    open
  }) => {
    const theme = useTheme();
    const underMd = useMediaQuery(theme.breakpoints.down("md"));

    const deleteFilterAndRefresh = useCallback(() => {
      onDeleteClick({});
      onFilterClick();
    }, [onDeleteClick, onFilterClick]);

    return (
      <Grid
        container
        justify={"center"}
        spacing={1}
        style={{ marginTop: underMd && "1rem" }}
      >
        {Boolean(filterFields?.collapsed.length) && (
          <Grid item>
            <IconButton
              style={{ minWidth: 0, padding: "5px" }}
              onClick={onToggleClick}
            >
              {!open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </IconButton>
          </Grid>
        )}
        <Grid item xs>
          <Button
            style={{ minWidth: 0, marginBottom: "8px" }}
            onClick={onFilterClick}
            fullWidth
          >
            SZŰRÉS
          </Button>
        </Grid>
      </Grid>
    );
  };
  const getActiveFiltersData = form =>
    form.getRegisteredFields().reduce((acc, fieldName) => {
      let itemToInsert;
      const originalFieldName = fieldName.replace(/(\.from|\.to)/, "");
      const colDef = columnDefs.find(item => {
        if (
          item.filterConfig &&
          item.filterConfig.filterParam === originalFieldName
        ) {
          return true;
        } else if (
          item.filterConfig &&
          item.filterConfig.suppressActiveFilterColDefFind
        ) {
          return false;
        }

        if (item.field === originalFieldName) {
          return true;
        }
      });
      const value = form.getFieldState(fieldName).value;
      const isEmptyArray = Array.isArray(value) && !value.length;
      const alreadyAdded = acc.find(item => item.name === originalFieldName);

      if (colDef && value && !alreadyAdded && !isEmptyArray) {
        if (colDef.filterConfig && colDef.filterConfig.range) {
          const fromValue =
            form.getFieldState(fieldName.replace(".to", ".from")).value || "";
          const toValue =
            form.getFieldState(fieldName.replace(".from", ".to")).value || "";
          itemToInsert = {
            label:
              (colDef.filterConfig && colDef.filterConfig.label) ||
              colDef.headerName,
            value: `${fromValue && fromValue + "-tól"} ${toValue &&
              toValue + "-ig"}`,
            name: originalFieldName
          };
        } else {
          itemToInsert = {
            label:
              (colDef.filterConfig && colDef.filterConfig.label) ||
              colDef.headerName,
            value,
            name: originalFieldName
          };
        }
        if (itemToInsert) {
          switch (colDef.filterConfig.type) {
            case "switch":
              itemToInsert.value = itemToInsert.value === "1" ? "Igen" : "Nem";
              break;
            case "select":
              const selectOptions = Array.isArray(colDef.filterConfig.options)
                ? colDef.filterConfig.options
                : [].concat.apply(
                    [],
                    Object.values(colDef.filterConfig.options)
                  );

              if (colDef.filterConfig.multiple) {
                itemToInsert.multiselect = true;

                itemToInsert.value = itemToInsert.value
                  .map(
                    val => selectOptions.find(item => item.value === val).text
                  )
                  .join(", ");
              } else {
                itemToInsert.value = selectOptions.find(
                  item => item.value === itemToInsert.value
                ).text;
              }

              break;

            default:
              break;
          }

          acc.push(itemToInsert);
        }
      }

      return acc;
    }, []);

  const [activeFilters, setActiveFilters] = useState([]);

  useEffect(() => {
    return () => {
      formInstance = null;
    };
  }, []);

  const handleFilterSubmit = values => {
    onFilter(getFilterKeyValueObject(values));
    setActiveFilters(getActiveFiltersData(formInstance));
  };

  return (
    <Grid>
      <FlexiForm
        id={formId}
        FinalFormFormProps={{
          onSubmit: handleFilterSubmit,
          initialValues,
          mutator: {
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            }
          }
        }}
        render={({ form, values }) => {
          storeFormValues(values);

          if (!formInstance) {
            formInstance = form;
            setTimeout(() => {
              setActiveFilters(getActiveFiltersData(form));
            }, 100);
          }

          return (
            <React.Fragment>
              <Paper>
                <Box p={"10px 16px"}>
                  <Grid container alignItems={"flex-end"} spacing={2}>
                    <Grid item xs={12} md={10}>
                      <Fields
                        config={filterFields.visible}
                        ContainerGridProps={{
                          alignItems: "flex-end"
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <ActionButtons
                        onDeleteClick={form.reset}
                        onFilterClick={submitForm}
                        onToggleClick={toggleFilters}
                        open={open}
                      />
                    </Grid>
                  </Grid>
                </Box>
                {Boolean(filterFields?.collapsed.length) && (
                  <Collapse in={open} style={{ margin: "0 16px" }}>
                    <Box p={"10px 16px 23px"}>
                      <Grid container>
                        <Grid item xs={12}>
                          <FlexiTypography
                            variant={"h3"}
                            style={{ marginTop: "30px", marginBottom: "30px" }}
                          >
                            Részletes keresés
                          </FlexiTypography>
                          <Grid container>
                            <Fields config={filterFields.collapsed} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid container>
                      <Grid item xs={12}>
                        <Divider
                          style={{
                            backgroundColor: "#979797",
                            marginBottom: "8px"
                          }}
                        />
                        <Grid container spacing={1}>
                          <Grid item style={{ flexGrow: 1 }} />
                          {onDownload && (
                            <Grid item>
                              <Button onClick={onDownload}>Letöltés</Button>
                            </Grid>
                          )}
                          {onExport && (
                            <Grid item>
                              <Button onClick={onExport}>Export</Button>
                            </Grid>
                          )}
                          <Grid item>
                            <Button onClick={submitForm}>SZŰRÉS</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Collapse>
                )}
                <input
                  type="submit"
                  style={{
                    position: "absolute",
                    left: "-9999px",
                    width: "1px",
                    height: "1px"
                  }}
                  tabIndex="-1"
                />
              </Paper>
              {Boolean(activeFilters.length) && (
                <Grid
                  container
                  spacing={1}
                  style={{ paddingTop: "10px" }}
                  alignItems={"center"}
                >
                  <Grid item style={{ paddingRight: "10px" }}>
                    <FlexiTypography variant={"h3"} fontSize={12}>
                      Aktív szűrők:
                    </FlexiTypography>
                  </Grid>
                  {activeFilters.map(({ label, value, name, multiselect }) => (
                    <Grid item>
                      <Chip
                        style={{
                          height: "30px",
                          backgroundColor: "#E3E3F3",
                          border: "none",
                          borderRadius: "5px"
                        }}
                        label={
                          <Typography variant={"body2"}>
                            {label}: {value}
                          </Typography>
                        }
                        onDelete={() => {
                          form.mutators.setValue(
                            name,
                            multiselect ? [] : undefined
                          );

                          setTimeout(() => {
                            handleFilterSubmit(form.getState().values);
                          }, 100);
                        }}
                        deleteIcon={
                          <CancelIcon
                            style={{
                              color: "#2227AC",
                              height: "16px",
                              width: "16px"
                            }}
                          />
                        }
                        variant="outlined"
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </React.Fragment>
          );
        }}
      />
    </Grid>
  );
};

export default FlexiAdvancedFilter;
export { resetStoredFilterData };

FlexiAdvancedFilter.propTypes = {
  columnDefs: PropTypes.array.isRequired,
  groupsOrderConfig: PropTypes.array,
  onDownload: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired
};
