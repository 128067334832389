import React, { useCallback, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import TaxNumberField from "./TaxNumberField";
import EmailField from "./EmailField";
import SimpleTextField from "./SimpleTextField";
import CurrencyField from "./CurrencyField";
import SelectField from "./SelectField";
import CheckboxField from "./CheckboxField";
import RadioField from "./RadioField";
import { FlexiFormContext } from "../index";
import DatePickerField from "./DatePickerField";
import Range from "./Range";
import InputGroup from "./InputGroup";
import Autosuggest from "./Autosuggest";
import FileUpload from "./FileUpload";
import AddressFields from "./Address";
import BisnodeCompanySearch from "./BisnodeCompanySearch";
import { SwitchField } from "./SwitchField";
import CheckboxWithDialog from "./CheckboxWithDialog";
import Password from "./Password";
import { Box, Tooltip } from "@material-ui/core";
import ChipInputField from "./ChipInputField";
import ToggleButtons, { ToggleField } from "./ToggleField";
import { ToggleFieldGPT } from "./ToggleFieldGPT";

const filterConfig = (config) => {
  return config.reduce((acc, row) => {
    const filteredRow = row.filter((item) => !item.deleteField);

    if (filteredRow.length) {
      acc.push(filteredRow);
    }

    return acc;
  }, []);
};

const Fields = ({ config, ContainerGridProps = {} }) => {
  const { fieldTypes } = useContext(FlexiFormContext);
  const renderField = useCallback(({ fieldType, tooltipText, ...props }) => {
    let Component;

    switch (fieldType) {
      case "taxNumber":
        Component = TaxNumberField;
        break;
      case "email":
        Component = EmailField;
        break;

      case "currency":
        Component = CurrencyField;
        break;

      case "select":
        Component = SelectField;
        break;

      case "checkbox":
        Component = CheckboxField;
        break;
      case "checkboxWithDialog":
        Component = CheckboxWithDialog;
        break;

      case "switch":
        Component = SwitchField;
        break;
      // case "toggle":
      //   Component = ToggleButtons;
      //   break;
      // case "togglegpt":
      //   Component = ToggleFieldGPT;
      //   break;

      case "radio":
        Component = RadioField;
        break;

      case "text":
        Component = SimpleTextField;
        break;

      case "datePicker":
        Component = DatePickerField;
        break;

      case "range":
        Component = Range;
        break;

      case "inputGroup":
        Component = InputGroup;
        break;

      case "autosuggest":
        Component = Autosuggest;
        break;

      case "fileUpload":
        Component = FileUpload;
        break;

      case "address":
        Component = AddressFields;
        break;

      case "bisnodeCompanySearch":
        Component = BisnodeCompanySearch;
        break;
      case "password":
        Component = Password;
        break;

      case "chipInput":
        Component = ChipInputField;
        break;

      default:
        Component = fieldTypes[fieldType];
        break;
    }

    if (!Component) {
      throw new Error(
        `"${fieldType}" field type not found! Check the FlexiFormContext values!`
      );
    }

    const component = <Component {...props} fullWidth />;

    if (tooltipText) {
      return (
        <Tooltip arrow title={tooltipText} placement={"top"}>
          <span>{component}</span>
        </Tooltip>
      );
    }

    if (props.disabled) {
      return (
        <div style={{ pointerEvents: "none", opacity: "0.4" }}>{component}</div>
      );
    }

    return component;
  }, []);

  return (
    <Grid container spacing={2} {...ContainerGridProps}>
      {filterConfig(config).map((row) => {
        const divided = 12 / row.length;
        const size = Number.isInteger(divided) ? divided : true;

        return row.map(({ gridSize, ...field }, i) => (
          <Grid
            item
            xs={12}
            md={gridSize || size}
            key={`fields-field-${field.type}-${field.name}-${i}`}
          >
            {renderField(field)}
          </Grid>
        ));
      })}
    </Grid>
  );
};

export default Fields;
