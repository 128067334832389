import Grid from "@material-ui/core/Grid";
import React, { useEffect, useMemo, useState } from "react";
import FlexiTypography from "../../FlexiTypography/index.js";
import { Fields, useFieldValue, useForm } from "../index";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import { Checkbox } from "@material-ui/core";
import withCheckboxErrorMessage from "../withCheckboxErrorMessage";

const blueCheckBoxStyles = {
  root: {
    "&$checked": {
      color: "#0045de"
    },
    span: {
      fontSize: "12px"
    }
  },
  checked: {}
};

const BlueCheckbox = withCheckboxErrorMessage(
  withStyles(blueCheckBoxStyles)(Checkbox)
);

const AddressFields = ({
  name,
  easyStructure,
  countriesUrl,
  disabled,
  showPostalBlock = true
}) => {
  const regName = easyStructure
    ? "registrationAddress"
    : "partyLegalEntity.registrationAddress";

  const fieldConfigs = useMemo(() => {
    const getFields = prefix => [
      [
        {
          fieldType: "select",
          name: `${prefix}.country`,
          label: "Ország",
          required: true,
          url: countriesUrl,
          disabled,
          initialValue: "HU"
        }
      ],
      [
        {
          fieldType: "text",
          name: `${prefix}.${easyStructure ? "postalCode" : "postalZone"}`,
          label: "Irányítószám",
          required: true,
          disabled
        },
        {
          fieldType: "text",
          name: `${prefix}.${easyStructure ? "city" : "cityName"}`,
          label: "Város",
          required: true,
          disabled
        }
      ],
      [
        {
          fieldType: "text",
          name: `${prefix}.addressLine`,
          label: "Utca, házszám",
          required: true,
          disabled
        }
      ]
    ];

    return {
      registrationFields: getFields(name ? `${name}.${regName}` : regName),
      postalFields: getFields(name ? `${name}.postalAddress` : "postalAddress")
    };
  }, []);
  const [showPostalAddress, setShowPostalAddress] = useState(false);
  const addressValues = useFieldValue(`${name}.${regName}`);
  const form = useForm();

  useEffect(
    () => {
      if (!showPostalAddress) {
        form.mutators.setValue(`${name}.postalAddress`, addressValues);
      }
    },
    [addressValues]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <FlexiTypography variant={"h3"}>Székhely cím</FlexiTypography>
      </Grid>
      <Grid item xs={12}>
        <Fields config={fieldConfigs.registrationFields} />
      </Grid>
      {showPostalBlock && (
        <>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <BlueCheckbox
                  checked={showPostalAddress}
                  onChange={e => setShowPostalAddress(e.target.checked)}
                />
              }
              label={
                <FlexiTypography fontSize={14} style={{ color: "#4a4a4a" }}>
                  Eltérő postai cím megadása
                </FlexiTypography>
              }
            />
          </Grid>
          {showPostalAddress && (
            <React.Fragment>
              <Grid item xs={12}>
                <FlexiTypography variant={"h3"}>Postai cím</FlexiTypography>
              </Grid>
              <Grid item xs={12}>
                <Fields config={fieldConfigs.postalFields} />
              </Grid>
            </React.Fragment>
          )}
        </>
      )}
    </Grid>
  );
};

export default AddressFields;
