import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import FilterIcon from '@material-ui/icons/FilterList';
import React from 'react';
import { compose, withState } from 'recompose';
import styled from 'styled-components';

const SortLabel = styled(TableSortLabel).attrs({
  classes: { root: 'list-header' },
})`
  && {
    font-size: 12px;
    color: #4A4A4A;
    text-transform: uppercase;
  }
`;

const SearchBarFilterMenu = ({
  sortableColumns,
  anchor,
  setAnchor,
  sort = {},
  onShortChange,
}) => (
  <div style={{ borderLeft: '1px solid #979797' }}>
    <InputAdornment style={{ height: 'auto' }}>
      <IconButton
        style={{ margin: '0 auto' }}
        aria-haspopup="true"
        onClick={e => setAnchor(e.currentTarget)}
      >
        <FilterIcon />
      </IconButton>
    </InputAdornment>
    <Menu
      anchorEl={anchor}
      id="filter-menu"
      open={Boolean(anchor)}
      onClose={() => setAnchor(null)}
    >
      {sortableColumns.map(col => (
        <MenuItem key={`smart-list-filter-item-${col.id}`}>
          <SortLabel
            active={sort.colId === col.field}
            direction={sort.sort}
            onClick={() =>
              onShortChange({
                colId: col.field,
                sort: sort.sort === 'asc' ? 'desc' : 'asc',
              })
            }
            key={`${col.headerName}-list-item-${col.id}`}
            style={{
              padding: '12px 16px',
              width: '100%',
            }}
          >
            {col.headerName}
          </SortLabel>
        </MenuItem>
      ))}
    </Menu>
  </div>
);

const enhance = compose(withState('anchor', 'setAnchor', null));

export default enhance(SearchBarFilterMenu);

SearchBarFilterMenu.propTypes = {
  anchor: PropTypes.any,
  onShortChange: PropTypes.func,
  setAnchor: PropTypes.func,
  sort: PropTypes.object,
  sortableColumns: PropTypes.array,
};
