import React from 'react';
import styled from 'styled-components';
import MUIDrawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { breakpoint } from '../utils/breakpoints.js';

const paper = 'drawerPaper';

// prettier-ignore
const Drawer = styled(props =>
  props.onClose ? (
    <SwipeableDrawer {...props} disableSwipeToOpen disableDiscovery />
  ) : (
    <MUIDrawer {...props} />
  ),
).attrs({
  classes: { paper },
})`
  height: 100%;
  
  .${paper} {
    width: 230px;
    background-color: ${props => props.sidebarbgcolor ? props.sidebarbgcolor : '#616161'};
    border-right: none;
    overflow-x: hidden;
    font-size: 14px;
    
    &::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: transparent;
    }
    ${breakpoint('md')`
        position: relative;
    `}
    
`;

export default Drawer;
