import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import FlexiDialog from "../FlexiDialog/index.js";
import { Button, LinearProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FlexiTypography from "../FlexiTypography/index.js";
import step1Img from "./images/lepes1.png";
import step2Img from "./images/lepes2.png";
import step3Img from "./images/lepes3.png";
import step4Img from "./images/lepes4.png";
import step5Img from "./images/lepes5.png";
import step6Img from "./images/lepes6.png";
import step7Img from "./images/lepes7.png";
import step8Img from "./images/lepes8.png";
import step9Img from "./images/lepes9.png";
import step10Img from "./images/lepes10.png";
import step11Img from "./images/lepes11.png";
import step12Img from "./images/lepes12.png";

const steps = [
  {
    imgSrc: step1Img,
    desc: (
      <React.Fragment>
        Menj fel a{" "}
        <a href={"https://onlineszamla.nav.gov.hu/"}>NAV Online Számla</a>{" "}
        oldalára és kattints a <strong>Bejelentkezés</strong> gombra. FONTOS: ha
        a böngészőből keresel rá az oldara, figyel arra, hogy ne a teszt
        környezetet nyisd meg!
      </React.Fragment>
    ),
  },
  {
    imgSrc: step2Img,
    desc: (
      <React.Fragment>
        Válaszd a <strong>Bejelentkezés KAÜ</strong>-vel opciót az Elsődleges
        felhasználó dobozban.
      </React.Fragment>
    ),
  },
  {
    imgSrc: step3Img,
    desc: (
      <React.Fragment>
        A rendszerbe történő belépéskor a KAÜ (Központi Azonosítási Ügynök)
        oldala azonosítja a bejelentkező személyt. Ehhez add meg az Ügyfélkapus
        adataidat.
      </React.Fragment>
    ),
  },
  {
    imgSrc: step4Img,
    desc: (
      <React.Fragment>
        A sikeres azonosítást követően az Online Számla rendszerében megadhatod
        kapcsolattartási adataidat és elolvashatod az adatkezelési tájékoztatót.
      </React.Fragment>
    ),
  },
  {
    imgSrc: step5Img,
    desc: (
      <React.Fragment>
        A jobb felső sarokban található legördülő menüben kattints az{" "}
        <strong>Adózó-felhasználó kapcsolatok</strong>ra.
      </React.Fragment>
    ),
  },
  {
    imgSrc: step6Img,
    desc: (
      <React.Fragment>
        Az adózó melletti <strong>Megnyitás</strong> gombra kattintva
        jelentkezhetsz be a vállalkozásodba.
      </React.Fragment>
    ),
  },
  {
    imgSrc: step7Img,
    desc: (
      <React.Fragment>
        Keresd meg a <strong>Felhasználók</strong> menüpontot, majd kattints rá
        és hozz létre egy technikai felhasználót az{" "}
        <strong>Új felhasználó</strong> gomb segítségével.
      </React.Fragment>
    ),
  },
  {
    imgSrc: step8Img,
    desc: (
      <React.Fragment>
        Válaszd a <strong>Technikai felhasználó</strong> opciót.
      </React.Fragment>
    ),
  },
  {
    imgSrc: step9Img,
    desc: (
      <React.Fragment>
        Töltsd ki az itt található mezőket - adj meg egy jelszót és a
        jogosultságokat (számlák kezelése, számlák lekérdezése, saját számlák
        lekérdezése), majd kattints a <strong>Mentés</strong> gombra.
      </React.Fragment>
    ),
  },
  {
    imgSrc: step10Img,
    desc: (
      <React.Fragment>
        A rendszer automatikusan generál egy felhasználónevet és kitölti a
        kapcsolattartás nyelvét. Nyomd meg a <strong>Kulcsgenerálás</strong>{" "}
        gombot.
      </React.Fragment>
    ),
  },
  {
    imgSrc: step11Img,
    desc: (
      <React.Fragment>
        Következő lépésként megjelenik a felhasználóneved, az XML aláírókulcs,
        és az XML cserekulcs. Ezekre az adatokra, valamint a Technikai
        felhasználó jelszavára lesz szükséged ahhoz, hogy FlexiBill fiókodat
        összeköthesd a NAV Online Számla rendszerével.
      </React.Fragment>
    ),
  },
  {
    imgSrc: step12Img,
    desc: (
      <React.Fragment>
        Lépj be a FlexiBill fiókodba és navigálj a{" "}
        <strong>Beállítások -> NAV beállítások</strong> fülre. Töltsd ki az
        oldal alján található űrlapot és kattints a{" "}
        <strong>Kapcsolat létrehozása</strong> gombra. Ha az oldal tetején lévő
        NAV ikon mellett zöld pipát látsz, létrejött az összekötés.
      </React.Fragment>
    ),
  },
];

const FlexiNavConnectionHelpDialog = ({ open, onClose }) => {
  const [stepIndex, setStepIndex] = useState(0);
  const step = steps[stepIndex];
  return (
    <FlexiDialog
      open={open}
      onClose={onClose}
      size={"large"}
      title={"NAV összekötés útmutató"}
      dialogActions={
        <Grid
          container
          direction={"row"}
          style={{ alignItems: "center", justify: "space-between" }}
        >
          <Grid item>
            <Button
              variant={"text"}
              disabled={stepIndex === 0}
              onClick={() => setStepIndex(stepIndex - 1)}
            >
              <ChevronLeft />
              Vissza
            </Button>
          </Grid>
          <Grid item style={{ flexGrow: 1, padding: "0 15px" }}>
            <LinearProgress
              style={{ width: "100%" }}
              variant="determinate"
              value={((stepIndex + 1) / steps.length) * 100}
            />
          </Grid>
          <Grid item>
            <Button
              variant={"text"}
              disabled={stepIndex + 1 === steps.length}
              onClick={() => setStepIndex(stepIndex + 1)}
            >
              Következő
              <ChevronRight />
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container direction={"column"}>
        <Grid item xs={12} style={{ paddingBottom: "12px" }}>
          <img style={{ width: "100%" }} src={step.imgSrc} />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: "12px" }}>
          <FlexiTypography
            fontSize={18}
            variant={"h3"}
            style={{ paddingTop: step.imgSrc === step12Img ? "170px" : "0px" }}
          >
            {stepIndex + 1}. lépés
          </FlexiTypography>
        </Grid>
        <Grid item xs={12} style={{ height: "100px", minHeight: "60px" }}>
          <FlexiTypography fontSize={14}>{step.desc}</FlexiTypography>
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};
export default FlexiNavConnectionHelpDialog;

FlexiNavConnectionHelpDialog.propTypes = {
  open: PropTypes.any.isRequired,
  onClose: PropTypes.any.isRequired,
};
