import ErrorBoundary from "../../components/ErrorBoundary";
import FlexiPieChart from "../../FlexiComponents/FlexiPieChart";
import React from "react";
import { useHistory } from "react-router-dom";
import uploadIcon from "../../images/redesignImages/Icon.svg";
import emptyImage2 from "../../images/redesignImages/107-box-package-open-outline 1.svg";
import EmptySection from "../../components/EmptySection";
import { useProfileData } from "../../hooks/useApi";

const pieChartDataTransform = pieChartApiResponse => {
  const labels = [];
  const data = [];

  pieChartApiResponse.map(item => {
    labels.push(item.text);
    data.push(item.sum);
  });

  const result = {
    type: "doughnut",
    data: {
      labels,
      datasets: [
        {
          backgroundColor: ["#F7471E", "#f7471e99", "#f7471e66", "#FFEDE9"],
          data: data
        }
      ]
    },
    options: {
      aspectRatio: 0,
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          right: 20
        }
      },
      legend: {
        display: false
      },
      cutoutPercentage: 70
    }
  };
  return result;
};

const DashboardOverdueInvoicesChart = ({
  invoiceStatSum,
  invoiceStat,
  onClick
}) => {
  const { push: redirect } = useHistory();
  const { szamlazzHu } = useProfileData();

  return (
    <>
      {invoiceStatSum ? (
        <ErrorBoundary>
          <FlexiPieChart
            config={pieChartDataTransform(invoiceStat)}
            detailsButtonClick={() => redirect("/app/invoice/DUED")}
          />
        </ErrorBoundary>
      ) : (
        <EmptySection
          buttonText={!szamlazzHu && "Új számlák feltöltése"}
          sectionTitle={"Nyitott számlák"}
          sectionDescription={
            szamlazzHu
              ? "Itt jelennek meg számláid, miután azokat Számlázz.hu rendszerével szinkronizáltuk."
              : "Ez a szekció üres, mert még nem töltöttél fel számlákat. Kezdj faktorálni!"
          }
          buttonIcon={!szamlazzHu && uploadIcon}
          imgSrc={emptyImage2}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default DashboardOverdueInvoicesChart;
