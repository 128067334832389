import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  useContractAgreementClaims,
  useDashboardPageData,
  useInvoicePageData,
  useInvoiceStats,
  useMutator,
  useNewContractAgreementClaimPost,
  useProfileData
} from "../../hooks/useApi";
import { defineMessages } from "react-intl.macro";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box/Box";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { isWidthUp } from "@material-ui/core";
import useHasPermission from "../../components/RoleProvider/useHasPermission";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Link, useHistory } from "react-router-dom";
import newInvoiceIcon from "../../images/redesignImages/check-on-paper.svg";
import newCustomerIcon from "../../images/redesignImages/user-add.svg";
import DashboardStepper from "./DashboardStepper";
import DashboardLimitsChart from "./DashboardLimitsChart";
import DashboardStatusesChart from "./DashboardStatusesChart";
import DashboardOverdueInvoicesChart from "./DashboardOverdueInvoicesChart";
import FlexiTipp from "../../components/FlexiTipp";
import DashboardPendingAgreementClaims from "./DashboardPendingAgreementClaims";
import { ROLE_FLEXIPAY_CUSTOMER_PLUS, ROLE_FLEXIPAY_SCF } from "../../roles";
import DashboardPayableInvoicesList from "./DashboardPayableInvoicesList";
import TemporaryDialog from "../../components/TemporaryDialog";
import InvoiceUploadTypeDialog from "../InvoicePage/InvoiceUploadTypeDialog";
import usePopupState from "../../hooks/usePopupState";
import MassUploadAgreementSelectDialog from "../InvoicePage/MassUploadAgreementSelectDialog";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import { useErrorOccurredNotification } from "../../hooks/useNotificationState";
import { useStoreData } from "../../hooks/useDataState";
import christmasTree from "../../images/christmas-tree-svg.svg";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";

const messages = defineMessages({
  pageTitle: { id: "pageTitle.dashboard", defaultMessage: "Főoldal" },
  invoiceStatus: {
    id: "app.components.flexiBarChartTitle",
    defaultMessage: "Számla státuszok"
  },
  barChartTitle: {
    id: "app.components.flexiBarChart.title",
    defaultMessage: "Keretkihasználtság áttekintése"
  },
  detailsText: {
    id: "app.components.flexiBarChart.details.button.text",
    defaultMessage: "Részletek"
  },
  christmasMessage: {
    id: "christmas",
    defineMessages: `Kedves Ügyfelünk,<br><br>
Köszönjük, hogy 2023-ban megtiszteltél bizalmaddal!
Természetesen Bill nem pihen – szolgáltatásaink zavartalanul működnek az év hátralévő részében.

Kellemes Karácsonyi Ünnepeket és sikerekben, növekedésben gazdag új évet kívánunk!

Üdvözlettel
A Báv Faktor és Flexibill csapata`
  }
});

const useStyles = makeStyles({
  purple: {
    background: "#7256CD !important",
    "&:hover": { background: "#6341d4 !important" }
  }
});

const ShortcutButton = ({
  img,
  buttonText,
  redirectUrl,
  onClick,
  redirect,
  width
}) => {
  const classes = useStyles();
  return (
    <Paper>
      <Box p={2}>
        <Grid
          container
          style={{
            alignContent: "center",
            height: "inherit",
            width: "inherit",
            minHeight: 72
          }}
          spacing={2}
          direction={
            isWidthDown("md", width)
              ? "row"
              : isWidthUp("lg", width)
              ? "row"
              : "column"
          }
        >
          <Grid
            item
            xs={3}
            sm={isWidthDown("md", width) ? 12 : 3}
            style={{
              display: "flex"
            }}
          >
            <img
              alt={"img"}
              src={img}
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
                maxHeight: "37px"
              }}
            />
          </Grid>
          <Grid
            item
            xs={9}
            sm={isWidthDown("md", width) ? 12 : 9}
            style={{ alignSelf: "center" }}
          >
            <Button
              className={classes.purple}
              onClick={onClick || (() => redirect(redirectUrl))}
              style={{ fontSize: "12px" }}
              fullWidth
              backgroundColor={"#7256CD"}
            >
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const DashboardPage = ({ width }) => {
  const [limitDialogData, setLimitDialogData] = useState(null);
  const [accountMatchingDialogOpen, setAccountMatchingDialogOpen] = useState(
    null
  );
  const [massUploadFile, setMassUploadFile] = useState(null);

  const [post, postLoading] = useMutator("POST");
  const { push: redirect } = useHistory();
  const [uploadTypeShow, showUploadType, hideUploadType] = usePopupState(
    "InvoiceUploadTypeDialog"
  );
  const { data: invoiceStats } = useInvoiceStats();
  const [
    agreementSelectShow,
    showAgreementSelect,
    hideAgreementSelect
  ] = usePopupState("MassUploadAgreementSelectDialog");

  const { mainCompany, szamlazzHu, kulcsSoft } = useProfileData();
  const storeData = useStoreData();
  const notifyError = useErrorOccurredNotification();
  const isScf = mainCompany.roles.includes(ROLE_FLEXIPAY_CUSTOMER_PLUS);
  const [tempDialogOpen, setTempDialogOpen] = useState(true);
  const {
    uploadMassInvoice,
    reloadInvoices,
    startGrouping,
    deleteInvoice,
    uploading
  } = useInvoicePageData();
  const {
    agreementStat,
    invoiceStat,
    invoices,
    loading,
    reloadAgreementStat
  } = useDashboardPageData();

  const [
    onNewAgreementClaimClick,
    newAgreementLoading
  ] = useNewContractAgreementClaimPost();
  const hasPermission = useHasPermission();
  const startUploadingMassInvoice = async agreementId => {
    const { data: massUploadResponse, error } = await uploadMassInvoice(
      massUploadFile
    );

    if (!massUploadResponse) {
      notifyError(selectErrorNotificationMessage(error));
    } else {
      storeData("parsedMassInvoices", {
        data: massUploadResponse,
        fileName: massUploadFile.name
      });
      redirect(`/app/save-mass-invoice/${agreementId}`);
    }
  };
  const statusCountChartData = [
    {
      label: "Faktorálható",
      value: invoiceStats?.pendingAndPrepared
    },
    {
      label: "Folyósítható",
      value: invoiceStats?.submittedGroups
    },
    {
      label: "Faktorált",
      value: invoiceStats?.faktoringGroups
    }
  ];
  const invoiceStatSum = invoiceStat?.reduce((sum, item) => sum + item.sum, 0);
  const statusCountSum = statusCountChartData?.reduce(
    (sum, item) => sum + item.value,
    0
  );
  const showNavInfoBar = !mainCompany?.defaultSettings
    .loadNavOutgoingInvoicesToFlexiPay;
  const { data: agreementClaims } = useContractAgreementClaims();
  const desktop = isWidthUp("md", width);

  return (
    <div>
      <Box height={"160px"} bgcolor={"#116EF2"} px={"110px"} pt={"40px"}>
        {desktop ? (
          <Grid
            container
            justifyContent={"space-between"}
            style={{ paddingTop: desktop ? undefined : "65px" }}
          >
            <Grid item>
              <Typography variant={"h5"} color={"textSecondary"}>
                Íme a Digitális <br /> Faktoring vezérlőpultod
              </Typography>
            </Grid>
            {!(szamlazzHu || kulcsSoft) && (
              <Grid item>
                <Box display={"flex"} style={{ gap: "25px" }}>
                  <Link
                    variant={"text"}
                    color={"textSecondary"}
                    style={{
                      color: "white",
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center"
                    }}
                    onClick={showUploadType}
                  >
                    <img src={newInvoiceIcon} />
                    Számla feltöltése
                  </Link>
                  <span style={{ border: "1px solid #FFFFFF" }} />
                  <Link
                    variant={"text"}
                    color={"textSecondary"}
                    style={{
                      color: "white",
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center"
                    }}
                    to={`/app/agreement-claims/new-contract/new`}
                  >
                    <img src={newCustomerIcon} />
                    Új vevő
                  </Link>
                </Box>
              </Grid>
            )}
          </Grid>
        ) : (
          "asd"
        )}
      </Box>
      <Box
        maxWidth={"1070px"}
        margin={"auto"}
        style={{
          transform: "translate(0px, -50px)"
        }}
      >
        {isScf ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DashboardStepper desktop={desktop} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DashboardPayableInvoicesList />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FlexiTipp />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DashboardStepper desktop={desktop} />
            </Grid>
            <Grid item xs={12}>
              <DashboardLimitsChart
                szamlazzHu={szamlazzHu}
                kulcsSoft={kulcsSoft}
                agreementStat={agreementStat}
                onClick={showUploadType}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DashboardStatusesChart
                statusCountSum={statusCountSum}
                statusCountChartData={statusCountChartData}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FlexiTipp />
            </Grid>
            <Grid item xs={12} sm={5}>
              <DashboardOverdueInvoicesChart
                invoiceStatSum={invoiceStatSum}
                invoiceStat={invoiceStat}
                onClick={showUploadType}
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <DashboardPendingAgreementClaims
                agreementClaims={agreementClaims}
              />
            </Grid>
          </Grid>
        )}
      </Box>
      {/*<TemporaryDialog*/}
      {/*  open={tempDialogOpen}*/}
      {/*  onClose={() => setTempDialogOpen(false)}*/}
      {/*/>*/}
      <InvoiceUploadTypeDialog
        open={uploadTypeShow}
        onSingleUploadClick={() => redirect("/app/invoice/new")}
        onMassUploadFileChanged={async file => {
          if (file) {
            setMassUploadFile(file);
            hideUploadType();
            showAgreementSelect();
          }
        }}
        onClose={hideUploadType}
      />
      <MassUploadAgreementSelectDialog
        open={agreementSelectShow}
        onClose={hideAgreementSelect}
        onAgreementSelected={async values => {
          await startUploadingMassInvoice(values.agreementId);
        }}
      />
    </div>
  );
};

export default withWidth()(DashboardPage);
