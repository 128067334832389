import "whatwg-fetch";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import useFetch from "../utils/useFetch";

export const getApiQueryString = params => {
  const esc = encodeURIComponent;
  const str = Object.keys(params)
    .filter(key => params[key])
    .map(k => `${esc(k)}=${esc(params[k])}`)
    .join("&");

  return str;
};
// Ez miatt https://dmitripavlutin.com/react-hooks-stale-closures/ (A fetchItems-ben az extraQueryParam az elso erteken maradt)
let _extraQueryParams;

const FlexiRemotePager = forwardRef(
  (
    {
      token,
      url,
      initialData,
      onLoadingChange,
      onError,
      children,
      extraQueryParams,
      getItemsFromResponse,
      rowsPerPageOptions,
      initialRowsPerPage,
      simpleSearchKey,
      storePaginationDataKey
    },
    ref
  ) => {
    _extraQueryParams = extraQueryParams;
    const formatPaginationData = ({
      page = 1,
      rowsPerPage = rowsPerPageOptions ? rowsPerPageOptions[0] : 10,
      sortModel,
      order,
      searchText
    }) => ({
      page: page,
      limit: rowsPerPage,
      order: order,
      [simpleSearchKey]: searchText,
      ...sortModel
    });

    const getQueryString = (data, extraParams) =>
      getApiQueryString({ ...formatPaginationData(data), ...extraParams });

    const savedPagerData = useMemo(() => {
      const item = sessionStorage.getItem(storePaginationDataKey);

      if (item) {
        return JSON.parse(item);
      }
    }, []);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPagination, setCurrentPagination] = useState(
      savedPagerData || initialData || formatPaginationData({})
    );
    const [abortController, setAbortController] = useState(null);
    const fetch = useFetch({ token });
    const hasQueryParameter = useMemo(
      () => {
        const urlWithProtocol =
          url.indexOf("//") === 0 ? window.location.protocol + url : url;
        const urlObj = new URL(urlWithProtocol);
        return Boolean(urlObj.search.length);
      },
      [url]
    );
    const queryString = useMemo(
      () => getQueryString(currentPagination || {}, extraQueryParams),
      [extraQueryParams, currentPagination]
    );

    useEffect(() => onLoadingChange(loading), [loading]);

    useEffect(
      () => {
        if (storePaginationDataKey && currentPagination) {
          sessionStorage.setItem(
            storePaginationDataKey,
            JSON.stringify(currentPagination)
          );
        }
      },
      [currentPagination]
    );

    const fetchItems = paginationData => {
      if (abortController) {
        abortController.abort();
      }
      setLoading(true);

      const controller = new AbortController();

      fetch(
        `${url}${hasQueryParameter ? "&" : "?"}${getQueryString(
          paginationData || currentPagination || {},
          _extraQueryParams
        )}`,
        {
          method: "GET",
          signal: controller.signal
        }
      )
        .then(data => {
          let total;
          let items;
          const finalPagination = paginationData || currentPagination;
          const {
            rowsPerPage = rowsPerPageOptions ? rowsPerPageOptions[0] : 10,
            page
          } = finalPagination;

          if (data && !data.entities && Array.isArray(data)) {
            items = data;
            total = data.length;
          } else if (Array.isArray(data.entities)) {
            items = data.entities;
            total = data.total;
          } else if (Array.isArray(data.companies)) {
            items = data.companies;
            total = data.companies.length;
          }

          const maxPage = Math.ceil(total / rowsPerPage);

          if (maxPage > 0 && page > maxPage) {
            fetchItems({ ...finalPagination, page: maxPage });
          } else {
            setItems(items);
            setTotal(total);

            setCurrentPagination(finalPagination);
          }
        })
        .catch(e => onError(e.message))
        .finally(() => setLoading(false));
      setAbortController(controller);
    };

    useImperativeHandle(ref, () => ({
      reload: fetchItems
    }));

    useEffect(
      () => {
        fetchItems();
      },
      [url, token, extraQueryParams]
    );

    return children({
      items,
      total,
      onPaginationChange: (page, rowsPerPage, sortModel, searchText) =>
        fetchItems({ page, rowsPerPage, sortModel, searchText }),
      reload: fetchItems,
      loading,
      currentPagination,
      queryString
    });
  }
);

FlexiRemotePager.displayName = "FlexiRemotePager";

export default FlexiRemotePager;
