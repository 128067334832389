import React from "react";
import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import WaitingForSupplierSignature from "../../images/redesignImages/WaitingForSupplierSignature.svg";
import WaitingForCustomerConfirmation from "../../images/redesignImages/WaitingForCustomerConfirmation.svg";
import WaitingForCustomerSignature from "../../images/redesignImages/WaitingForCustomerSignature.svg";
import WaitingForBoConfirmation from "../../images/redesignImages/WaitingForBoConfirmation.svg";
import Approved from "../../images/redesignImages/Approved.svg";
import Rejected from "../../images/redesignImages/Rejected.svg";

const InvoiceStatusHelperDialog = ({ open, onClose }) => {
  return (
    <FlexiDialog
      size={"small"}
      title={"Státusz magyarázat"}
      open={open}
      onClose={onClose}
    >
      <Grid container direction={"column"}>
        <Grid item xs={12} style={{ paddingBottom: "24px" }}>
          <Grid container>
            <Grid item xs={2}>
              <img src={WaitingForSupplierSignature} />
            </Grid>
            <Grid item xs={10}>
              <Typography style={{ fontWeight: "bold" }} variant={"body2"}>
                Szállító aláírására vár
              </Typography>
              <Typography variant={"body2"}>
                Legenerálódott a faktorálási értesítő és már csak az aláírásodra
                van szükség, hogy folytatódjon a folyamat
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: "24px" }}>
          <Grid container>
            <Grid item xs={2}>
              <img src={WaitingForCustomerConfirmation} />
            </Grid>
            <Grid item xs={10}>
              <Typography style={{ fontWeight: "bold" }} variant={"body2"}>
                Vevő visszaigazolására vár
              </Typography>
              <Typography variant={"body2"}>
                A faktorálandó számlák továbbításra kerültek a vevőd felé,
                visszaigazolására várnak
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: "24px" }}>
          <Grid container>
            <Grid item xs={2}>
              <img src={WaitingForCustomerSignature} />
            </Grid>
            <Grid item xs={10}>
              <Typography style={{ fontWeight: "bold" }} variant={"body2"}>
                Vevő aláírására vár
              </Typography>
              <Typography variant={"body2"}>
                A vevődnek kell aláírni a dokumentumot. Amint ez megtörtént, a
                folyósítási folyamat elindul
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: "24px" }}>
          <Grid container>
            <Grid item xs={2}>
              <img src={WaitingForBoConfirmation} />
            </Grid>
            <Grid item xs={10}>
              <Typography style={{ fontWeight: "bold" }} variant={"body2"}>
                Ellenőrzés alatt
              </Typography>
              <Typography variant={"body2"}>
                Munkatársaink dolgoznak az ügyleten, ellenőrzik a dokumentumok
                helyességét és meglétét
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: "24px" }}>
          <Grid container>
            <Grid item xs={2}>
              <img src={Approved} />
            </Grid>
            <Grid item xs={10}>
              <Typography style={{ fontWeight: "bold" }} variant={"body2"}>
                Faktorált
              </Typography>
              <Typography variant={"body2"}>
                A számlákat sikeresen benyújtottad faktorálásra. A folyósítás
                folyamatban van
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={2}>
              <img src={Rejected} />
            </Grid>
            <Grid item xs={10}>
              <Typography style={{ fontWeight: "bold" }} variant={"body2"}>
                Lezárt
              </Typography>
              <Typography variant={"body2"}>
                Az ügyletet munkatársunk lezárta. Amennyiben kérdésed van, vedd
                fel velünk a kapcsolatot
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};

export default InvoiceStatusHelperDialog;
