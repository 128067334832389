import { Select } from "final-form-material-ui";
import MenuItem from "@material-ui/core/MenuItem";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import BaseField from "./BaseField";
import { FlexiFormContext, Field } from "../index";
import useFetch from "../../utils/useFetch";
import ListSubheader from "@material-ui/core/ListSubheader";
import Checkbox from "@material-ui/core/Checkbox";

const multipleInitialValue = [];

const SelectField = ({
  name,
  required,
  label,
  validate,
  options = [],
  url,
  fullWidth,
  getInitialValueText,
  disabled,
  initialValue,
  valueGetter,
  textGetter,
  multiple,
  renderValue = multiple ? values => values.join(", ") : getInitialValueText
}) => {
  const { token } = useContext(FlexiFormContext);
  const fetch = useFetch({ token });
  const [selectOptions, setSelectOptions] = useState(options);
  useEffect(
    () => {
      if (!url && options) {
        setSelectOptions(options);
      }
    },
    [options]
  );
  const fullWidthStyle = useMemo(
    () => {
      if (fullWidth) {
        return {
          width: "100%"
        };
      }

      return {};
    },
    [fullWidth]
  );

  useEffect(() => {
    if (url) {
      fetch(url).then(data => {
        const options = Object.keys(data).map(value => ({
          value,
          text: data[value]
        }));
        setSelectOptions(options);
      });
    }
  }, []);

  const [multipleSelected, setMultipleSelected] = useState([]);

  const getMenuItem = useCallback(
    option => {
      const text = textGetter ? textGetter(option) : option.text;
      const value = valueGetter ? valueGetter(option) : option.value;
      const optionSelected =
        multiple &&
        multipleSelected.some(
          item => JSON.stringify(item) === JSON.stringify(value)
        );

      return (
        <MenuItem value={value}>
          {multiple && <Checkbox checked={optionSelected} />}
          {text}
        </MenuItem>
      );
    },
    [multipleSelected]
  );

  const getMenuItems = useCallback(
    items => {
      return items.map(option => getMenuItem(option));
    },
    [selectOptions, getMenuItem]
  );

  const getGroupedMenuItems = useCallback(
    () => {
      return Object.keys(selectOptions).map(groupName => [
        <ListSubheader style={{ backgroundColor: "white" }}>
          {groupName}
        </ListSubheader>,
        getMenuItems(selectOptions[groupName])
      ]);
    },
    [selectOptions, getMenuItems]
  );

  return (
    <>
      <Field name={name}>
        {({ input: { value } }) => {
          if (multiple) {
            if (value) {
              setMultipleSelected(value);
            }
          } else {
            setMultipleSelected(value);
          }
        }}
      </Field>
      <BaseField
        component={Select}
        multiple={multiple}
        name={name}
        label={label}
        renderValue={renderValue}
        required={required}
        validate={validate}
        disabled={disabled}
        initialValue={initialValue || (multiple && multipleInitialValue)}
        MenuProps={{
          variant: "menu",
          getContentAnchorEl: null
        }}
        formControlProps={{
          style: fullWidthStyle
          // variant: "outlined"
        }}
      >
        {Array.isArray(selectOptions)
          ? getMenuItems(selectOptions)
          : getGroupedMenuItems()}
      </BaseField>
    </>
  );
};

export default SelectField;
