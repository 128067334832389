import PropTypes from "prop-types";
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import FlexiTypography from "../FlexiTypography/index.js";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

const BulkActions = ({ selectedItems, loading, actions, itemName="számla" }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onlyOneAction = actions.length === 1;
  const firstAction = actions[0];

  return (
    <Grid item xs={12} sm>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm="auto">
          <FlexiTypography variant="body2">
            <b>{selectedItems.length}</b> kiválasztott {itemName}
          </FlexiTypography>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Button
            disabled={!selectedItems.length || loading || firstAction.disabled}
            onClick={e => {
              if (onlyOneAction) {
                firstAction.onClick(selectedItems);
              } else {
                setAnchorEl(e.currentTarget);
              }
            }}
          >
            {onlyOneAction ? firstAction.text : "Műveletek"}
          </Button>
          {!onlyOneAction && (
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {actions.map(menuItem => {
                const disabled = menuItem.checkDisabled
                  ? menuItem.checkDisabled(selectedItems)
                  : menuItem.disabled;
                return (
                  <Tooltip
                    title={
                      disabled && menuItem.disabledTooltip
                        ? menuItem.disabledTooltip
                        : ""
                    }
                  >
                    <span style={{ display: "block" }}>
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null);
                          menuItem.onClick(selectedItems);
                        }}
                        disabled={disabled}
                      >
                        <ListItemIcon>{menuItem.icon}</ListItemIcon>
                        <ListItemText>{menuItem.text}</ListItemText>
                      </MenuItem>
                    </span>
                  </Tooltip>
                );
              })}
            </Menu>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BulkActions;

BulkActions.propTypes = {
  actions: PropTypes.array,
  loading: PropTypes.bool,
  selectedItems: PropTypes.array
};
