import React from "react";
import PropTypes from "prop-types";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import ResetIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import Input from "@material-ui/core/Input";
import { compose } from "recompose";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

const textFieldInputRef = React.createRef();

const SearchBar = ({ searchChanged, onReset, searchText, filter, width }) => (
  <Grid container justify="flex-end">
    <Grid item xs={12} sm={6} md={3}>
      <Grid
        container
        style={{
          height: "40px",
          backgroundColor: "white",
          borderRadius: "32px",
          padding: "4px 10px",
          marginBottom: isWidthDown("md", width) ? "16px" : "5px",
          marginTop: isWidthDown("md", width) ? "16px" : "0px",
          boxShadow: "0 1px 3px 0 rgba(0,0,0,0.20)"
        }}
      >
        <Grid item xs={10}>
          <Input
            style={{
              width: "100%",
              // borderRight: `1px solid ${theme.dividerBg}`,
              marginRight: "5px",
              padding: "0 0 0 8px"
            }}
            disableUnderline
            inputRef={textFieldInputRef}
            onChange={e => searchChanged(e.target.value)}
            value={searchText}
            endAdornment={
              <InputAdornment position="end">
                {searchText && searchText.length ? (
                  <IconButton onClick={onReset}>
                    <ResetIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() =>
                      textFieldInputRef.current &&
                      textFieldInputRef.current.focus()
                    }
                  >
                    <SearchIcon />
                  </IconButton>
                )}
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={2} align="center">
          {filter}
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const enhance = compose(withWidth());

SearchBar.propTypes = {
  searchChanged: PropTypes.func,
  onReset: PropTypes.func,
  searchText: PropTypes.string,
  filter: PropTypes.node
};

export default enhance(SearchBar);
