import React from "react";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";

const FlexiInfoBar = ({ info, error, success, warning, children, action }) => {
  let severity;

  if (info) {
    severity = "info";
  }
  if (error) {
    severity = "error";
  }
  if (success) {
    severity = "success";
  }
  if (warning) {
    severity = "warning";
  }
  return <Alert severity={severity} action={action}>{children}</Alert>;
};

FlexiInfoBar.propTypes = {
  info: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
  children: PropTypes.any,
};

export default FlexiInfoBar;
