import { roleContext } from "./index";
import { useCallback, useContext } from "react";
import {
  ROLE_FLEXIPAY_CUSTOMER,
  ROLE_FLEXIPAY_CUSTOMER_PLUS,
  ROLE_FLEXIPAY_SUPER_ADMIN
} from "../../roles";

const useHasPermission = () => {
  const userRoles = useContext(roleContext);
  return useCallback(
    roles => {
      if (userRoles.includes(ROLE_FLEXIPAY_CUSTOMER_PLUS)) {
        return (Array.isArray(roles) ? roles : [roles]).some(
          r => userRoles.indexOf(r) >= 0
        );
      } else {
        return (Array.isArray(roles) ? roles : [roles]).some(r => {
          if (
            userRoles.includes(ROLE_FLEXIPAY_SUPER_ADMIN) &&
            userRoles.includes(ROLE_FLEXIPAY_CUSTOMER_PLUS)
          ) {
            return true;
          } else {
            return userRoles.indexOf(r) >= 0;
          }
        });
      }
    },
    [userRoles]
  );
};
export default useHasPermission;
