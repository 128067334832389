import {IconButton} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";

export const IconButtonWrap = withStyles({
    root: {
        padding: 0,
        "& span:first-of-type": {
            width: "40px",
            height: "40px",
            padding: 0,
        },
        svg : {
            fontSize: "25px",
}
    }
})(IconButton);
