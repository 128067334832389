import { getStoredToken } from "../../utils/authentication";
import FlexiExpandableRemoteList from "../../FlexiComponents/FlexiExpandableRemoteList";
import formatDate from "../../utils/formatDate";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import InfoIcon from "@material-ui/icons/Info";
import Check from "@material-ui/icons/Check";
import React, { useMemo } from "react";
import { getUrlWithCompanyId } from "../../utils/urlHelpers";
import { useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import pendingActionsIcon from "../../images/pending_actions_agreement_claims_icon.svg";
import { Box, Chip, Typography, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import checkingDocumentsIcon from "../../images/docCheck.svg";
import BillSection from "../../components/BillSection";
import billHappy from "../../images/bill/new/bill-happy.svg";

const isPendingAction = status =>
  status === "DOCUMENT_UPLOAD" ||
  status === "CALCULATION" ||
  status === "CUSOMER_UPLOAD";
// elírás?
const StatusCircle = ({ status }) => {
  let bgColor;
  let iconEl;
  let label;

  const whiteColor = {
    style: {
      color: "white"
    }
  };

  if (isPendingAction(status)) {
    bgColor = "#BF4CE1";
    label = "Feladatod van vele";
    iconEl = <img alt={"pendingActionsIcon"} src={pendingActionsIcon} />;
  } else if (status === "PAYMENT_DONE" || status === "CLOSED") {
    bgColor = "green";
    label = "A folyamat lezárult";
    iconEl = <Check fontSize={"small"} {...whiteColor} />;
  } else if (status === "DOCUMENT_REVIEW") {
    bgColor = "#4FC3F7";
    label = "Dokumentum ellenőrzés alatt";
    iconEl = <img alt={"checkingDocumentsIcon"} src={checkingDocumentsIcon} />;
  } else {
    bgColor = "#826AF9";
    label = "Folyamatban van";
    iconEl = <HourglassFullIcon fontSize={"small"} {...whiteColor} />;
  }

  return (
    <Box display={"flex"} alignItems={"center"} style={{ gap: "0.5rem" }}>
      <span
        style={{
          width: 24,
          height: 24,
          borderRadius: 12,
          backgroundColor: bgColor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {iconEl}
      </span>
      <Typography>{label}</Typography>
    </Box>
  );
};

const StyledChip = withStyles({
  label: {
    fontSize: "12px",
    whiteSpace: "normal",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    lineHeight: "initial"
  },
  root: {
    borderRadius: 3,
    backgroundColor: "#E8EBF5 !important"
  }
})(Chip);

const AgreementClaimsList = ({
  hideSearchBar,
  hidePager,
  onNewAgreementClaimClick
}) => {
  const dateValueFormatter = ({ value }) => formatDate(value);
  const { push: redirect } = useHistory();

  const columnDefs = useMemo(() => {
    return [
      {
        headerName: "Vevő neve",
        field: "customers",

        cellRendererFramework: ({ value }) => {
          const multipleCustomer = value.length > 1;

          return (
            <Box>
              <Tooltip
                title={
                  multipleCustomer
                    ? value.map(item => item.party.partyName).join(", ")
                    : value[0]?.party?.partyName || ""
                }
              >
                <StyledChip
                  label={
                    multipleCustomer
                      ? `${value.length} vevő`
                      : value[0]?.party?.partyName || "-"
                  }
                  deleteIcon={multipleCustomer && <InfoIcon />}
                  onDelete={multipleCustomer && (() => null)}
                />
              </Tooltip>
            </Box>
          );
        },
        sortable: true
      },
      {
        headerName: "Létrehozás dátuma",
        field: "createdAt",
        sortable: true,
        valueFormatter: dateValueFormatter
      },
      {
        headerName: "Státusz",
        field: "status",
        sortable: true,
        // eslint-disable-next-line react/prop-types
        cellRendererFramework: ({ value }) => <StatusCircle status={value} />,
        cellStyle: {
          display: "flex",
          alignItems: "center"
        }
      },
      {
        headerName: "",
        field: "id",
        sortable: true,
        // eslint-disable-next-line react/prop-types
        cellRendererFramework: ({ value }) => (
          <Button
            variant={"text"}
            onClick={() =>
              redirect(`/app/agreement-claims/new-contract/${value}`)
            }
          >
            Megtekintés
          </Button>
        ),
        width: 250,
        cellStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end"
        }
      }
    ];
  }, []);
  return (
    <div style={{ cursor: "pointer" }}>
      <FlexiExpandableRemoteList
        url={getUrlWithCompanyId("/contractAgreementClaim")}
        token={getStoredToken()}
        columnDefs={columnDefs}
        suppressExpand
        simpleSearchKey="filter[name][eq]"
        // hideSearchBar={hideSearchBar}
        // hidePager={hidePager}
        rowsPerPageOptions={hideSearchBar || hidePager ? [3] : undefined}
        EmptyListProps={{
          render: () => (
            <BillSection
              title={"Jelenleg nincs folyamatban szerződéses igény!"}
              img={billHappy}
            >
              <Typography>
                Az{" "}
                <Typography variant={"caption"} component={"span"}>
                  új igény
                </Typography>{" "}
                gombra kattintva teljesen új szerződéskötés folyamatot
                indíthatsz el, a későbbiekben pedig ebben a listában
                tájékozódhatsz a folyamatok állapotáról.
              </Typography>
              <Button onClick={onNewAgreementClaimClick}>Új igény</Button>
            </BillSection>
          )
        }}
        gridOptions={{
          getRowClass: ({ data }) => isPendingAction(data.status) && "yellow",
          onRowClicked: ({ data }) =>
            redirect(`/app/agreement-claims/new-contract/${data.id}`),
          rowStyle: { cursor: "pointer" }
        }}
      />
    </div>
  );
};

export default AgreementClaimsList;
