import styled from "styled-components";
import LinearProgress from "@material-ui/core/LinearProgress";

const FlexiLinearProgress = styled(LinearProgress)`
  && {
    position: absolute;
    height: 4px;
    width: 100%;
    left: 0;
    top: 0;
  }
`;

export default FlexiLinearProgress;

