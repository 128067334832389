import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import AppBar from "./AppBar";
import FlexiLinearProgress from "./LinearProgress";
import ErrorBoundary from "./ErrorBoundary";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import KebabMenu from "./KebabMenu";
import OpenableFab from "./OpenableFab";
import Button from "@material-ui/core/Button";
import BackIcon from "@material-ui/icons/ChevronLeft";
import { Box, Typography } from "@material-ui/core";
import FlexiSplitButton from "../FlexiSplitButton/index.js";

const renderRightSideComponent = component => {console.log(component)
  if (component.options) {
    return <FlexiSplitButton {...component} />;
  }
  if (component.props) {
    return <Button {...component.props} />;
  }

};

const getDesktopRightSideComponents = components => {
  return (
    <React.Fragment>
      {components.map(component => {
        if (!component.divider) {
          const fullWidthProp = {};

          if (component.props.fullWidth) {
            fullWidthProp.xs = 12;
          }

          return (
            <Grid item {...fullWidthProp}>
              {renderRightSideComponent(component)}
            </Grid>
          );
        } else {
          return <Grid item style={{ flexGrow: 1 }} />;
        }
      })}
    </React.Fragment>
  );
};

const getMobileRightSideComponents = components => {
  return components.filter(component => !component.divider).reduce(
    (placements, component) => {
      switch (component.mobile.placement) {
        case "fab":
          placements.fab.push(component);
          break;

        case "kebab":
          placements.kebab.push(component);
          break;

        case "toolbar":
          placements.toolbar.push({
            ...component,
            props: { ...component.props, fullWidth: true }
          });
          break;

        default:
          console.error("Unexpected mobile placement", component);
      }

      return placements;
    },
    { kebab: [], fab: [], toolbar: [] }
  );
};

const FlexiPageLayout = ({
  title,
  children,
  disabledBottomMargin = false,
  disabledTopMargin = false,
  loading = false,
  width,
  backButton,
  backButtonText = "Vissza",
  onBackButtonClick,
  rightSideComponents = [],
  rightSideComponentsPlacement = "top",
  backButtonEl,
  navBarWidth = 230,
  supressHeader
}) => {
  const mobileComponents = getMobileRightSideComponents(rightSideComponents);
  const isMobile = isWidthDown("sm", width);
  const topPlacement = rightSideComponentsPlacement === "top";
  return (
    <Box
      padding={["90px 15px", "90px 15px", "50px 70px"]}
      position={"relative"}
    >
      {loading && <FlexiLinearProgress style={{ top: isMobile ? 60 : 0 }} />}
      {!supressHeader && (
        <AppBar
          disabledBottomMargin={disabledBottomMargin}
          disabledTopMargin={disabledTopMargin}
        >
          <Grid container spacing={2}>
            <Grid item container alignItems={"center"} spacing={isMobile && 2}>
              <div
                style={{
                  width: "8px",
                  height: "60px",
                  backgroundColor: "#125EF2",
                  marginRight: "20px"
                }}
              />
              <Grid
                item
                style={
                  !backButton && !onBackButtonClick
                    ? { display: "flex", flexGrow: 1, alignItems: "center" }
                    : { flexGrow: 1 }
                }
              >
                {backButton &&
                  onBackButtonClick && (
                    <Button
                      onClick={onBackButtonClick}
                      style={{
                        paddingLeft: "0px !important",
                        marginLeft: "-7px",
                        justifyContent: "flex-start"
                      }}
                      variant={"text"}
                      style={{ color: "#2227ac" }}
                    >
                      <BackIcon /> {backButtonText}
                    </Button>
                  )}
                {backButton && backButtonEl}
                <Typography variant="subtitle1">{title}</Typography>
              </Grid>
              {isMobile &&
                Boolean(mobileComponents.kebab.length) && (
                  <Grid item>
                    <KebabMenu items={mobileComponents.kebab} />
                  </Grid>
                )}
              {(topPlacement ||
                Boolean(isMobile && mobileComponents.toolbar.length)) &&
                (Boolean(isMobile && mobileComponents.toolbar.length) ||
                  Boolean(!isMobile && rightSideComponents.length)) && (
                  <Grid item xs={isMobile && 12}>
                    <Grid container spacing={2}>
                      {getDesktopRightSideComponents(
                        isMobile
                          ? mobileComponents.toolbar
                          : rightSideComponents
                      )}
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </AppBar>
      )}
      <Grid container style={{ padding: isWidthDown("md", width) && "0 8px" }}>
        <Grid item xs>
          <ErrorBoundary>{children}</ErrorBoundary>
        </Grid>
      </Grid>
      {/*{!topPlacement &&*/}
      {/*  !isMobile && (*/}
      {/*    <BottomAppBar navBarWidth={navBarWidth}>*/}
      {/*      <Grid container spacing={2} justify={"flex-end"}>*/}
      {/*        {getDesktopRightSideComponents(rightSideComponents)}*/}
      {/*      </Grid>*/}
      {/*    </BottomAppBar>*/}
      {/*  )}*/}
      {isMobile &&
        Boolean(mobileComponents.fab.length) && (
          <OpenableFab actions={mobileComponents.fab} />
        )}
    </Box>
  );
};

FlexiPageLayout.propTypes = {
  backButton: PropTypes.bool,
  backButtonEl: PropTypes.node,
  backButtonText: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabledBottomMargin: PropTypes.bool,
  disabledTopMargin: PropTypes.bool,
  loading: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  rightSideComponents: PropTypes.array,
  rightSideComponentsPlacement: PropTypes.string,
  title: PropTypes.string.isRequired,
  width: PropTypes.any
};

export default withWidth()(FlexiPageLayout);
