import React, { useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FlexiTypography from "./../FlexiTypography/index.js";
import FlexiDialog from "./../FlexiDialog/index.js";

const ConfirmDialogContext = React.createContext({});

const FlexiConfirmDialogProvider = ({ children }) => {
  const [dialogData, setDialogData] = useState({
    title: "",
    desc: "",
    noButtonText: "",
    yesButtonText: "",
    fn: undefined
  });

  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);

    setTimeout(
      () =>
        setDialogData({
          title: "",
          desc: "",
          fn: undefined
        }),
      300
    );
  };

  // Be lehessen allitani a wrapperen is a title-t, desc-t, meg a confirm hivasbol is.
  const confirm = (
    fn,
    title,
    desc,
    yesButtonText = "Igen",
    noButtonText = "Nem"
  ) => {
    if (fn && title && desc) {
      setDialogData({ title, desc, fn, yesButtonText, noButtonText });
      setOpen(true);
    } else {
      console.warn(
        `Missing confirm data: fn, title, desc : ${fn}, ${title}, ${desc}`
      );
    }
  };

  return (
    <ConfirmDialogContext.Provider value={{ confirm }}>
      {children}
      <FlexiDialog
        open={open}
        onClose={onClose}
        title={dialogData.title}
        dialogActions={
          <Grid container spacing={2}>
            <Grid item>
              <Button variant={"text"} onClick={onClose}>
                {dialogData.noButtonText}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  dialogData.fn();
                  onClose();
                }}
              >
                {dialogData.yesButtonText}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <FlexiTypography>{dialogData.desc}</FlexiTypography>
      </FlexiDialog>
    </ConfirmDialogContext.Provider>
  );
};

export const useConfirmation = () => {
  const { confirm } = useContext(ConfirmDialogContext);

  return ({ fn, title, description, yesButtonText, noButtonText }) =>
    confirm(fn, title, description, yesButtonText, noButtonText);
};

export const useDeleteConfirmation = () => {
  const { confirm } = useContext(ConfirmDialogContext);

  return fn =>
    confirm(
      fn,
      "Biztos vagy benne?",
      "A törlés nem visszavonható!",
      "TÖRLÉS",
      "MÉGSE"
    );
};

export const Confirmation = ({ children }) => {
  const confirm = useConfirmation();

  return children({
    confirm
  });
};

export const DeleteConfirmation = ({ children }) => {
  const confirm = useDeleteConfirmation();

  return children({
    confirm
  });
};

export default FlexiConfirmDialogProvider;
