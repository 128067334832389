import PageLayout from "../../FlexiComponents/FlexiPageLayout";
import React, { useState, useMemo, useRef, useEffect } from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import ListIcon from "@material-ui/icons/List";
import SettingsIcon from "@material-ui/icons/Settings";
import CreateIcon from "@material-ui/icons/Create";
import error from "../../images/errorpurple.svg";
import check from "../../images/check.svg";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Grid from "@material-ui/core/Grid";
import {
  useCoworkerData,
  useMutator,
  useNewContractAgreementClaimPost,
  useNewContractPageData,
  useProfileData,
  useSzamlazzhuCustomers
} from "../../hooks/useApi";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  CircularProgress,
  isWidthUp,
  makeStyles,
  SvgIcon,
  Tooltip
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import signIcon from "../../images/redesignImages/signIcon.svg";
import bigGreenCheck from "../../images/redesignImages/green-check-big.svg";
import { Claims } from "./Claims";
import DocumentsPreparationSuccessPaper from "../../components/DocumentsPreparationSuccessPaper";
import Calculator from "./Calculator";
import { getFlexipayUrl, getUrlWithCompanyId } from "../../utils/urlHelpers";
import {
  useErrorOccurredNotification,
  useSaveSuccessNotification
} from "../../hooks/useNotificationState";
import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import { Fields } from "../../FlexiComponents/FlexiForm";
import { Form } from "react-final-form";
import Portal from "@material-ui/core/Portal";
import {
  ROLE_FLEXIPAY_AGREEMENT_UPLOADER,
  ROLE_FLEXIPAY_SUPER_ADMIN
} from "../../roles";
import useHasPermission from "../../components/RoleProvider/useHasPermission";
import ClaimsReview from "./ClaimsReview";
import billSmile from "../../images/redesignImages/Bill.svg";
import billSad from "../../images/bill/3_bill_sad.svg";
import billHappy from "../../images/redesignImages/Bill (1).svg";
import { useParams } from "react-router-dom";
import Results from "./Results";
import BillSection from "../../components/BillSection";
import {
  gtagAgreementClaimDocumentsUploaded,
  gtagAgreementClaimOfferAccepted
} from "../../utils/gtag";
import DigitalSignatureDialog, {
  FileUploaderDialog
} from "./DigitalSignatureDialog";
import HBNYRegistrationDialogs from "./HBNYRegistrationDialogs";
import DeclinedDialog from "./DeclinedDialog";
import FormDialog from "./FormDialog";
import CheckIcon from "@material-ui/icons/Check";
import HelperBill from "../../components/HelperBill";
import SpecialOfferBar from "../../components/SpecialOfferBar";
import doksi from "../../images/bill/new/doksi.svg";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import NavCarousel from "../NavDataPage/navDataTour";
import { convertBooleansToStrings } from "../../utils/initialValues";

export const agreementClaimStatusHelper = {
  CUSTOMER_UPLOAD: ["CUSTOMER_UPLOAD"],
  CALCULATION: ["CALCULATION"],
  DOCUMENT_UPLOAD: ["DOCUMENT_UPLOAD"],
  DOCUMENT_REVIEW: [
    "DOCUMENT_REVIEW",
    "PRE_RISK_EVALUATION",
    "RISK_EVALUATION",
    "DECISION",
    "BANK_DECISION",
    "ACCEPTING_FINAL_CALCULATION",
    "CONTRACT_FIXING",
    "CONTRACTING"
  ],
  SIGNATURE: [
    "SIGNATURE",
    "INTERNAL_SIGNATURE",
    "CONTRACT_CONFIGURE",
    "PAYMENT"
  ],
  PAYMENT_DONE: [
    "PAYMENT_DONE",
    "CLOSED",
    "PAYMENT_REVIEW_RISK_EVALUATION",
    "PAYMENT_REVIEW_BACK_OFFICE"
  ]
};

export const getAgreementClaimStatus = (
  status,
  array = agreementClaimStatusHelper
) => {
  return Object.keys(array).find(key => array[key].includes(status));
};

export function getAgreementClaimStepperSteps() {
  return [
    {
      label: "Kalkuláció",
      status: "CUSTOMER_UPLOAD",
      icon: <DescriptionIcon />
    },
    {
      label: "Kalkuláció",
      status: "CALCULATION",
      icon: <DescriptionIcon />
    },
    {
      label: "Dokumentumok feltöltése",
      status: "DOCUMENT_UPLOAD",
      icon: <ListIcon />
    },
    {
      label: "Adatok feldolgozása",
      status: "DOCUMENT_REVIEW",
      icon: <SettingsIcon />
    },
    {
      label: "Szerződéskötés",
      status: "SIGNATURE",
      icon: <BusinessCenterIcon />
    },
    {
      label: "Folyósító modul",
      status: "PAYMENT_DONE",
      icon: <ThumbUpIcon />
    }
  ];
}

const useStyles = makeStyles({
  paperRoot: {
    borderRadius: 5,
    border: "1px solid #394AB8",
    padding: "24px",
    marginBottom: 42
  },
  image: {
    width: 60,
    height: 60,
    margin: "auto",
    display: "block",
    marginBottom: 40
  },
  button: {
    backgroundColor: "#BD10E0 !important",
    marginTop: 20,
    margin: "auto",
    display: "block"
  }
});

const GiveAccessDialog = ({ open, onSendClick, onClose }) => {
  const dialogActionsRef = useRef();

  const giveAccessDialogFieldConfig = [
    [
      {
        fieldType: "email",
        name: `email`,
        label: "E-mail",
        required: true
      }
    ]
  ];

  return (
    <FlexiDialog
      title="Hozzáférés küldése"
      open={open}
      onClose={onClose}
      dialogActions={<div ref={dialogActionsRef} />}
    >
      <Grid container spacing={2} style={{ marginBottom: 8 }}>
        <Grid item xs={12}>
          <Typography>
            Adja meg munkatársa e-mail címét és elküldjük neki a linket, amin
            keresztül regisztrálhat és feltöltheti a szükséges dokumentumokat!
          </Typography>
          <br />
          <Form
            onSubmit={onSendClick}
            render={({ handleSubmit, form }) => {
              return (
                <form onSubmit={handleSubmit} id={"give-access"}>
                  <Fields config={giveAccessDialogFieldConfig} />
                  <Portal container={dialogActionsRef.current}>
                    <Button onClick={form.submit}>Küldés</Button>
                  </Portal>
                </form>
              );
            }}
          />
          <br />
          <Typography>
            FONTOS: Munkatársa “Megtekintő és dokumentum feltöltő” jogosultságot
            kap. Hozzáférését a “Munkatársak” menüpontban bármikor
            visszavonhatja!
          </Typography>
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};

const CallMeDialog = ({ open, onClose, imgSrc }) => {
  return (
    <FlexiDialog
      title={"Köszönjük érdeklődésed!"}
      open={open}
      onClose={onClose}
      dialogActions={
        <Button onClick={onClose} style={{ margin: "auto" }}>
          Rendben
        </Button>
      }
      imgSrc={imgSrc}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={"body2"}>
            Munkatársunk hamarosan felveszi veled a kapcsolatot a megadott
            elérhetőségen!
          </Typography>
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};

const WeAreHappyDialog = ({ open, onClose, imgSrc }) => {
  return (
    <FlexiDialog
      title={"Örülünk, hogy tetszik a kalkuláció!"}
      size={"small"}
      open={open}
      onClose={onClose}
      dialogActions={
        <Button style={{ margin: "auto" }} onClick={onClose}>
          Csináljuk!
        </Button>
      }
      imgSrc={imgSrc}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={"body2"}>
            Már csak néhány dokumentum feltöltése van hátra.
          </Typography>
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};

const FilesUploadedDialog = ({ open, onClose, onSendClick }) => {
  return (
    <FlexiDialog
      title={"Gratulálunk, mindent sikeresen feltöltöttél!"}
      open={open}
      onClose={onClose}
      imgSrc={billHappy}
      size={"small"}
      dialogActions={
        <Button style={{ margin: "auto" }} onClick={onSendClick}>
          ELKÜLDÖM
        </Button>
      }
    >
      <Typography>
        Ha ellenőrizni szeretnéd még a dokumentumokat, a jobb sarokban lévő X-re
        kattintva zárd be ezt az ablakot.
      </Typography>
      <Typography>
        Amennyiben megbizonyosodtál a dokumentumok helyességéről, az alábbi
        gombra kattintva elküldheted munkatársunknak!
      </Typography>
    </FlexiDialog>
  );
};

export const useBigButtonStyles = makeStyles({
  root: {
    padding: "20px 50px"
  },
  green: {
    background: "#5DCB9A !important",
    "&:hover": { background: "#3f8767 !important" }
  }
});

const NewContractPage = ({ history, width }) => {
  const buttonClasses = useBigButtonStyles();
  const [openGiveAccessDialog, setOpenGiveAccessDialog] = useState(false);
  const [openCallMeDialog, setOpenCallMeDialog] = useState(false);
  const [openWeAreHappyDialog, setOpenWeAreHappyDialog] = useState(false);
  const [openFilesUploadedDialog, setOpenFilesUploadedDialog] = useState(false);
  const [openDigitalSignature, setOpenDigitalSignature] = useState(false);
  const steps = getAgreementClaimStepperSteps();
  const { id: agreementClaimIdFromUrl } = useParams();
  const showOnBoardingWelcomePage = !Boolean(agreementClaimIdFromUrl);
  const isSuccess = status =>
    Boolean(status) &&
    status !== "CUSTOMER_UPLOAD" &&
    status !== "CALCULATION" &&
    status !== "DOCUMENT_UPLOAD" &&
    status !== "DOCUMENT_REVIEW" &&
    status !== "ACCEPTING_FINAL_CALCULATION" &&
    status !== "SIGNATURE" &&
    status !== "PAYMENT_DONE";

  const {
    customerAgreementClaims,
    supplierAgreementClaims,
    primaryDocumentTypes,
    secondaryDocumentTypes,
    uploadDocuments,
    uploadSignedDocuments,
    uploadDocumentCopy,
    deleteDocument,
    downloadDocument,
    downloadContract,
    downloadCalculation,
    sendCalculation,
    loading,
    agreementClaim,
    reloadAgreementClaim,
    recall,
    finalAccept,
    allFilesUploaded
  } = useNewContractPageData({
    isGuest: false,
    id: agreementClaimIdFromUrl === "new" ? null : agreementClaimIdFromUrl
  });
  const checked = agreementClaim?.supplier?.factorDataSheet;

  useEffect(() => {
    if (allFilesUploaded) {
      setOpenFilesUploadedDialog(true);
    }
  }, [allFilesUploaded]);

  const [
    onNewAgreementClaimClick,
    newAgreementLoading
  ] = useNewContractAgreementClaimPost();

  const notifySuccessSave = useSaveSuccessNotification();
  const notifyError = useErrorOccurredNotification();
  const { sendInvite } = useCoworkerData();
  const [post, postLoading] = useMutator("POST");
  const hasPermission = useHasPermission();
  const { szamlazzHu, mainCompany } = useProfileData();
  const hasSuperAdminPermission = hasPermission(ROLE_FLEXIPAY_SUPER_ADMIN);
  const hasDocumentUploaderRole =
    !hasSuperAdminPermission &&
    hasPermission([ROLE_FLEXIPAY_AGREEMENT_UPLOADER]);
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const nextFnOnDocumentUploadPage = async () => {
    const { data, error } = await post(
      getUrlWithCompanyId(
        `/contractAgreementClaim/${agreementClaimIdFromUrl}/submit`
      )
    );
    gtagAgreementClaimDocumentsUploaded();
    if (error) {
      notifyError({ text: error.message });
    }
    if (data) {
      reloadAgreementClaim();
    }
  };

  const rightSideComponents = useMemo(() => {
    if (showOnBoardingWelcomePage) {
      return [
        {
          props: {
            children: "Vágjunk bele!",
            onClick: onNewAgreementClaimClick
          },
          mobile: {
            placement: "toolbar"
          }
        }
      ];
    }
    if (agreementClaim?.status === "CALCULATION") {
      return [
        {
          options: [
            {
              text: "Kalkuláció letöltése",
              onClick: downloadCalculation
            },
            {
              text: "Kalkuláció küldése e-mailben",
              onClick: () => {
                sendCalculation();
                notifySuccessSave({
                  text: "Kalkuláció elküldve e-mail-ben"
                });
              }
            }
          ],
          mobile: {
            placement: ""
          },
          props: {}
        },
        {
          props: {
            children: "Visszahívást kérek",
            onClick: async () => {
              await recall();
              setOpenCallMeDialog(true);
            },
            variant: "outlined"
          },
          mobile: {
            placement: "toolbar"
          }
        },
        {
          props: {
            children: "Tovább",
            onClick: async () => {
              const { data, error } = await post(
                getUrlWithCompanyId(
                  `/contractAgreementClaim/${agreementClaimIdFromUrl}/calculation/accept`
                )
              );
              gtagAgreementClaimOfferAccepted();

              if (error) {
                notifyError({ text: error.message });
              }

              if (data) {
                setOpenWeAreHappyDialog(true);
                reloadAgreementClaim();
              }
            }
          },
          mobile: {
            placement: "toolbar"
          }
        }
      ];
    }
    if (
      agreementClaim?.status === "DOCUMENT_UPLOAD" &&
      !hasDocumentUploaderRole
    ) {
      return [
        {
          options: [
            {
              text: "Vissza az előkalkulációra",
              onClick: id => {
                post(
                  getUrlWithCompanyId(
                    `/contractAgreementClaim/${agreementClaim.id}/calculation/back`
                  )
                );
                window.location.reload();
              }
            },
            {
              text: "Visszahívást kérek",
              onClick: async () => {
                await recall();
                setOpenCallMeDialog(true);
              }
            }
          ],
          mobile: {
            placement: ""
          },
          props: {}
        },
        {
          props: {
            children: "Mentés",
            onClick: () =>
              notifySuccessSave({
                text:
                  "Ha kilépsz, innen tudod később folytatni, adataid nem vesznek el!"
              })
          },
          mobile: {
            placement: "toolbar"
          }
        },
        {
          props: {
            children: "Igény benyújtása",
            onClick: nextFnOnDocumentUploadPage,
            disabled: hasDocumentUploaderRole
          },
          mobile: {
            placement: "toolbar"
          }
        }
      ];
    } else {
      return [];
    }
  }, [agreementClaim]);
  const { data: szamlazzHuCustomers } = useSzamlazzhuCustomers(szamlazzHu);
  const stepperValue = getAgreementClaimStatus(agreementClaim?.status);
  const signedDocumentsUploaded = Boolean(
    agreementClaim?.supplier?.signedContractDocument?.length
  );

  useEffect(() => {
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
  }, [stepperValue]);
  return (
    <>
      <PageLayout
        pay
        loading={loading || postLoading || newAgreementLoading}
        title={
          showOnBoardingWelcomePage
            ? "Üdvözlünk a Digitális Faktoring világában!"
            : steps.find(
                step =>
                  step.status ===
                  (agreementClaimIdFromUrl === "new"
                    ? "CUSTOMER_UPLOAD"
                    : stepperValue)
              )?.label
        }
        suppressTourButton
        disabledBottomMargin
        rightSideComponents={rightSideComponents}
        rightSideComponentsPlacement={"top"}
        backButton={agreementClaim?.status !== "DOCUMENT_UPLOAD"}
        onBackButtonClick={() => history.push("/app/agreement-claims")}
      >
        {/*{agreementClaim?.status === "DOCUMENT_UPLOAD" && (*/}
        {/*  <SpecialOfferBar*/}
        {/*    date={new Date(agreementClaim?.createdAt).getTime()}*/}
        {/*  />*/}
        {/*)}*/}

        {isWidthUp("lg", width) &&
          (agreementClaimIdFromUrl === "new" ||
            agreementClaim?.status === "CALCULATION" ||
            agreementClaim?.status === "DOCUMENT_UPLOAD" ||
            agreementClaim?.status === "SIGNATURE") && (
            <HelperBill
              status={agreementClaim?.status}
              newContract={agreementClaimIdFromUrl === "new"}
            />
          )}
        <div
          style={
            hasPermission(ROLE_FLEXIPAY_AGREEMENT_UPLOADER) &&
            !hasSuperAdminPermission &&
            agreementClaim?.status !== "DOCUMENT_UPLOAD"
              ? { opacity: "0.6", pointerEvents: "none" }
              : undefined
          }
        >
          {(agreementClaimIdFromUrl === "new" ||
            agreementClaim?.status === "CALCULATION") && (
            <Grid
              container
              spacing={2}
              style={{ minHeight: "610px", height: "610px" }}
            >
              <Grid
                item
                xs={12}
                lg={6}
                style={{
                  pointerEvents:
                    mainCompany?.registrationSource === "ADMIN" ||
                    mainCompany?.registrationSource === "EXTERNAL_SALES" ||
                    mainCompany?.registrationSource === "SALES_PAGE"
                      ? "none"
                      : null,
                  opacity:
                    mainCompany?.registrationSource === "ADMIN" ||
                    mainCompany?.registrationSource === "EXTERNAL_SALES" ||
                    mainCompany?.registrationSource === "SALES_PAGE"
                      ? 0.4
                      : null
                }}
              >
                {!postLoading ? (
                  <Calculator
                    initialValues={
                      agreementClaim?.customers?.length
                        ? {
                            companies: agreementClaim?.customers?.map(
                              customer => ({
                                party: {
                                  partyName: customer.party.partyName,
                                  taxNumber: customer.party.taxNumber
                                },
                                calculation: {
                                  natureOfTransaction: "CASE_BY_CASE",
                                  needInsurance: customer.calculation.needInsurance.toString(),
                                  currency: customer.calculation.currency,
                                  monthlyInvoicesTotal:
                                    customer.calculation.monthlyInvoicesTotal,
                                  invoiceTerms:
                                    customer.calculation.invoiceTerms
                                },
                                localBusiness: customer.party.localBusiness.toString()
                              })
                            ),
                            _companies: [
                              {
                                party: { partyName: "asd" },
                                calculation: {
                                  natureOfTransaction: "CASE_BY_CASE",
                                  needInsurance: "true",
                                  currency: "HUF"
                                },
                                localBusiness: "true"
                              }
                            ]
                          }
                        : undefined
                    }
                    szamlazzHu={szamlazzHu}
                    onSubmit={async values => {
                      if (values.companies.length) {
                        const fullCompanies = values.companies.filter(
                          item => item.party.taxNumber
                        );
                        if (fullCompanies.length !== values.companies.length) {
                          notifyError({
                            text:
                              "Csak a listán feljövő vevőidre indíthatsz új igényt."
                          });
                          return;
                        }
                      }
                      let contractData = agreementClaim;
                      if (!agreementClaim) {
                        const result = await post(
                          getUrlWithCompanyId("/contractAgreementClaim")
                        );

                        if (result.error) {
                          notifyError({ text: result.error.message });
                          return;
                        }
                        contractData = result.data;
                      }
                      const { data, error } = await post(
                        getUrlWithCompanyId(
                          `/contractAgreementClaim/${contractData.id}/customer`
                        ),
                        values.companies.map(company => {
                          return {
                            party: {
                              ...company.party,
                              companyLegalForm:
                                company.localBusiness === "false"
                                  ? "009"
                                  : company.party.companyLegalForm,
                              localBusiness: company.localBusiness === "true"
                            },
                            calculation: {
                              ...company.calculation,
                              needInsurance:
                                company.calculation.needInsurance === "true"
                            }
                          };
                        })
                      );

                      if (error) {
                        notifyError({ text: error.message });
                      }
                      if (data) {
                        history.push(
                          `/app/agreement-claims/new-contract/${contractData.id}`
                        );
                      }
                      window.location.reload();
                    }}
                  />
                ) : (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "300px"
                    }}
                  >
                    <CircularProgress />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                {!agreementClaim?.customers ? (
                  <Paper
                    elevation={1}
                    style={{
                      padding: "32px",
                      margin: "24px 0",
                      textAlign: "center",
                      minHeight: "610px",
                      alignContent: "center"
                    }}
                  >
                    <Grid
                      container
                      direction={"column"}
                      justifyContent={"center"}
                    >
                      <Grid item xs>
                        <img src={doksi} style={{ paddingBottom: "20px" }} />
                        <Typography>
                          A Kalkuláció gombra kattintva megmutatjuk a számodra
                          készített ajánlatot.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                ) : (
                  <ClaimsReview
                    szamlazzHu={szamlazzHu}
                    data={agreementClaim}
                    showDetailedCalculation={
                      agreementClaim.showDetailedCalculation
                    }
                    onCallBackClick={async () => {
                      await recall();
                      setOpenCallMeDialog(true);
                    }}
                    onNextClick={async () => {
                      const { data, error } = await post(
                        getUrlWithCompanyId(
                          `/contractAgreementClaim/${agreementClaimIdFromUrl}/calculation/accept`
                        )
                      );
                      gtagAgreementClaimOfferAccepted();

                      if (error) {
                        notifyError({ text: error.message });
                      }

                      if (data) {
                        setOpenWeAreHappyDialog(true);
                        reloadAgreementClaim();
                      }
                    }}
                  />
                )}
              </Grid>
            </Grid>
          )}

          {agreementClaim?.status === "DOCUMENT_UPLOAD" && (
            <React.Fragment>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant={"h4"}
                    style={{ paddingBottom: "16px", paddingTop: "16px" }}
                  >
                    Ügyfél adatai
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Paper>
                    <Grid
                      container
                      direction={"row"}
                      alignItems={"center"}
                      style={{
                        height: "62px",
                        justifyContent: "space-between",
                        paddingLeft: "16px",
                        paddingRight: "16px"
                      }}
                    >
                      <Grid item>
                        <Grid container alignItems={"center"} direction={"row"}>
                          <Grid item>
                            {checked ? (
                              <img src={check} />
                            ) : (
                              <img src={error} />
                            )}
                          </Grid>
                          <Grid item>
                            <Grid
                              container
                              direction={"column"}
                              style={{ paddingLeft: "32px" }}
                            >
                              <Grid
                                item
                                style={{
                                  display: "inline-flex"
                                }}
                              >
                                <Typography
                                  variant={"h5"}
                                  style={{
                                    fontSize: "17px",
                                    paddingRight: "8px"
                                  }}
                                >
                                  Online adatlap
                                </Typography>
                                <Tooltip
                                  title={
                                    "Az online adatlap egészen rövid, első részében a Te adataidat kérjük (pl. hova kéred az utalást?). A további lapon vagy lapokon (több vevő esetén) már a vevőd adatait kérjük, akinek számlázol, erre kérlek figyelj!inden dokumentumot feltöltöttél, kattints a Tovább gombra."
                                  }
                                >
                                  <SvgIcon style={{ alignSelf: "end" }}>
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                                      fill="#2227AC"
                                    />
                                    <path
                                      d="M9.35381 10.9804L9.24448 11.4274C8.91648 11.5568 8.65448 11.6554 8.45938 11.7231C8.26407 11.7911 8.03713 11.8249 7.77856 11.8249C7.38148 11.8249 7.07263 11.7276 6.85236 11.5344C6.63208 11.3404 6.52191 11.0946 6.52191 10.7964C6.52191 10.681 6.5299 10.5622 6.54652 10.4413C6.56328 10.3202 6.58993 10.1838 6.6264 10.0313L7.03631 8.58045C7.07277 8.44152 7.10377 8.30989 7.1286 8.18541C7.15377 8.0617 7.16591 7.94788 7.16591 7.84542C7.16591 7.66014 7.12755 7.53054 7.05117 7.45767C6.9748 7.38502 6.82907 7.34813 6.61272 7.34813C6.50676 7.34813 6.39785 7.3651 6.28683 7.39792C6.17532 7.43088 6.08002 7.46272 6 7.49232L6.10961 7.04503C6.37814 6.9357 6.63481 6.84208 6.88034 6.76437C7.12586 6.68646 7.35785 6.64747 7.57729 6.64747C7.97163 6.64747 8.27592 6.74277 8.4896 6.93338C8.70329 7.12414 8.8101 7.37141 8.8101 7.67613C8.8101 7.73917 8.80308 7.85026 8.78801 8.00903C8.77328 8.16816 8.74586 8.31396 8.70588 8.44657L8.29773 9.8916C8.26428 10.0077 8.23419 10.1403 8.20803 10.2897C8.18103 10.438 8.16813 10.5513 8.16813 10.6273C8.16813 10.8192 8.21091 10.9502 8.29668 11.0198C8.38308 11.0895 8.53189 11.1241 8.74326 11.1241C8.8425 11.1241 8.95541 11.1065 9.08087 11.0719C9.20598 11.0374 9.29722 11.007 9.35381 10.9804ZM9.45732 4.91379C9.45732 5.16556 9.36244 5.38057 9.17176 5.55737C8.98156 5.7348 8.75238 5.82358 8.48427 5.82358C8.21533 5.82358 7.98558 5.7348 7.79322 5.55737C7.6012 5.3805 7.50498 5.16556 7.50498 4.91379C7.50498 4.66252 7.6012 4.44715 7.79322 4.26811C7.98523 4.08935 8.2154 4 8.48427 4C8.75231 4 8.98156 4.08956 9.17176 4.26811C9.36258 4.44715 9.45732 4.66259 9.45732 4.91379Z"
                                      fill="white"
                                    />
                                  </SvgIcon>
                                </Tooltip>
                              </Grid>
                              <Grid item>
                                <Typography
                                  variant={"body1"}
                                  style={{ color: "#111A29", opacity: 0.5 }}
                                >
                                  Még nincsenek kitöltve az adatok. A gombra
                                  kattintva töltsd ki az adatlapot!
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        {checked ? (
                          <Button
                            onClick={() => {
                              setFormDialogOpen(true);
                            }}
                          >
                            <CheckIcon />
                            Kitöltve
                          </Button>
                        ) : (
                          <Button
                            variant={"outlined"}
                            onClick={() => {
                              setFormDialogOpen(true);
                            }}
                          >
                            <CreateIcon style={{ marginRight: "10px" }} />
                            Kitöltés
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Claims
                    items={customerAgreementClaims}
                    onUploadDocuments={values =>
                      uploadDocuments(values, "customer")
                    }
                    documentTypes={primaryDocumentTypes}
                    onDocumentRemove={values =>
                      deleteDocument(values, "customer")
                    }
                    onDocumentDownload={downloadDocument}
                  />
                </Grid>
                <>
                  <Typography
                    variant={"h4"}
                    style={{ paddingBottom: "16px", paddingTop: "16px" }}
                  >
                    Könyvelőnél meglévő adatok
                  </Typography>
                </>
                <Grid item xs={12}>
                  <Paper>
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      style={{ paddingLeft: "16px", paddingRight: "16px" }}
                    >
                      <Grid item xs={9}>
                        <Grid item container direction={"column"}>
                          <Grid item>
                            <Typography variant={"h4"}>
                              Kérd el a könyvelődtől ezeket a dokumentumokat!
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              style={{ color: "#111A29", opacity: 0.5 }}
                              variant={"body1"}
                            >
                              Az alábbi gombra kattintva jogosultságot adhatsz
                              könyvelődnek, aki elvégezheti az alábbi
                              dokumentumok feltöltését. Ha rendelkezel a
                              dokumentumokkal, Te is feltöltheted!
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button onClick={() => setOpenGiveAccessDialog(true)}>
                          Hozzáférés küldése
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <>
                  <Claims
                    items={supplierAgreementClaims}
                    customers={customerAgreementClaims}
                    onUploadDocuments={values => {
                      return uploadDocuments(
                        values,
                        values.type === "customer" ? "customer" : "supplier"
                      );
                    }}
                    documentTypes={secondaryDocumentTypes}
                    onDocumentRemove={values =>
                      deleteDocument(
                        values,
                        values.type === "customer" ? "customer" : "supplier"
                      )
                    }
                    onDocumentDownload={downloadDocument}
                  />
                  <hr style={{ marginTop: 0, marginBottom: "50px" }} />

                  <FilesUploadedDialog
                    open={openFilesUploadedDialog}
                    onClose={() => setOpenFilesUploadedDialog(false)}
                    onSendClick={nextFnOnDocumentUploadPage}
                  />
                  <FormDialog
                    initialValues={convertBooleansToStrings({
                      supplier: {
                        ...agreementClaim?.supplier?.factorDataSheet,
                        overdueTaxDebt:
                          agreementClaim?.supplier?.factorDataSheet
                            ?.overdueTaxDebt || "null"
                      },
                      customer: agreementClaim?.customers?.map(
                        customer => customer.factorDataSheet
                      )
                    })}
                    customerId={agreementClaim?.customers[0]?.id}
                    agreementClaim={agreementClaim}
                    supplierId={agreementClaim?.supplier?.id}
                    open={formDialogOpen}
                    onClose={() => setFormDialogOpen(false)}
                    onSubmit={async values => {
                      // debugger;
                      if (values?.supplier?.overdueTaxDebt === "true") {
                        values.supplier.overdueTaxDebt =
                          values.supplier.overdueTaxDebtAmount;
                      } else if (values?.supplier?.overdueTaxDebt === "false") {
                        values.supplier.overdueTaxDebt = null;
                      }
                      if (!Array.isArray(values?.supplier?.electronicMail)) {
                        values.supplier.electronicMail = values.supplier.electronicMail.split(
                          ","
                        );
                      }
                      values.customer.forEach(customer => {
                        if (!Array.isArray(customer?.contact?.electronicMail)) {
                          customer.contact.electronicMail = customer.contact.electronicMail.split(
                            ","
                          );
                        }
                        if (
                          customer?.contactForInvoiceConfirmation
                            ?.electronicMail &&
                          !Array.isArray(
                            customer?.contactForInvoiceConfirmation
                              ?.electronicMail
                          )
                        ) {
                          customer.contactForInvoiceConfirmation.electronicMail = customer.contactForInvoiceConfirmation.electronicMail.split(
                            ","
                          );
                        }
                      });

                      await post(
                        getUrlWithCompanyId(
                          `/contractAgreementClaimParty/${agreementClaim?.supplier?.id}/factor-datasheet`
                        ),
                        values.supplier
                      );
                      for (const customerIndex in values.customer) {
                        const customer = values.customer[customerIndex];
                        await post(
                          getUrlWithCompanyId(
                            `/contractAgreementClaimParty/${agreementClaim.customers[customerIndex].id}/factor-datasheet`
                          ),
                          customer
                        );
                      }
                      setFormDialogOpen(false);
                      window.location.reload();
                    }}
                  />
                </>
              </Grid>
            </React.Fragment>
          )}

          {getAgreementClaimStatus(agreementClaim?.status) ===
            "DOCUMENT_REVIEW" &&
            !agreementClaim.declined && (
              <Grid
                style={{
                  marginLeft: "118px",
                  marginRight: "118px",
                  marginTop: "80px"
                }}
              >
                <DocumentsPreparationSuccessPaper />
              </Grid>
            )}

          {getAgreementClaimStatus(agreementClaim?.status) ===
            "DOCUMENT_REVIEW" && (
            <Results
              agreementClaim={agreementClaim}
              finalAccept={finalAccept}
              recall={recall}
            />
          )}

          {getAgreementClaimStatus(agreementClaim?.status) ===
            "DOCUMENT_REVIEW" &&
            agreementClaim.declined && (
              <DeclinedDialog agreementClaim={agreementClaim} />
            )}

          {getAgreementClaimStatus(agreementClaim?.status) === "SIGNATURE" && (
            <Grid
              container
              direction={"row"}
              spacing={6}
              style={{ paddingTop: "30px" }}
            >
              <Grid item xs={7}>
                <Paper style={{ padding: "60px" }}>
                  <Grid
                    container
                    alignItems={"center"}
                    direction={"column"}
                    spacing={2}
                  >
                    <Grid item>
                      <img
                        alt={"signIcon"}
                        src={signedDocumentsUploaded ? bigGreenCheck : signIcon}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant={"h3"}>
                        {signedDocumentsUploaded
                          ? "Sikeres feltöltés!"
                          : "Elkészült a szerződésed!"}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button variant={"outlined"} onClick={downloadContract}>
                        Letöltés
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={5} container>
                <Grid item>
                  <Typography variant={"h3"}>Gratulálunk,</Typography>
                </Grid>
                {!signedDocumentsUploaded && (
                  <Grid item>
                    <Typography>
                      Már csak egy lépésre vagy attól, hogy a céged is
                      digitálisan faktoráltasson! Egyetlen aláírás kell, és a
                      likviditásod biztos kezekben lesz, hiszen a számláidat a
                      FlexiBill-en bármikor faktoráltathatod.
                      <br />
                      <br />
                      (Ha esetleg van már papíron kötött szerződésed velünk, az
                      érvényes marad, így ezt a lépést kihagyhatod.)
                    </Typography>
                  </Grid>
                )}
                {signedDocumentsUploaded && (
                  <Grid item>
                    <Typography>
                      Sikeresen feltöltötted az aláírt szerződást, így nincs
                      további teendőd! Munkatársunk hamarosan felveszi veled a
                      kapcsolatot/ hamarosan hozzáférhetsz a kibővített ügyfél
                      felületedhez.
                    </Typography>
                  </Grid>
                )}
                {!signedDocumentsUploaded && (
                  <>
                    <Grid
                      item
                      style={{ display: "inline-flex", gap: "0.5rem" }}
                    >
                      <Button onClick={() => setOpenDigitalSignature(true)}>
                        Digitális aláírás
                      </Button>
                      <FileUploaderDialog
                        title={"Aláírt file feltöltése"}
                        description={
                          "Itt töltheted fel a letöltött, beszkennelt és általad aláírt szerződést. A feltöltés mellett kérjük, juttass el hozzánk két példányt személyesen vagy postán (1066 Budapest, Mozsár utca 16.)."
                        }
                        onSubmit={values =>
                          uploadSignedDocuments(values).then(() =>
                            setOpenDigitalSignature(false)
                          )
                        }
                        files={agreementClaim?.supplier.documents.filter(
                          doc =>
                            doc.type === "signed_contract_supplier_document"
                        )}
                      >
                        {({ openDialog }) => {
                          return (
                            <Button onClick={openDialog}>
                              Aláírt fájl feltöltése
                            </Button>
                          );
                        }}
                      </FileUploaderDialog>

                      <DigitalSignatureDialog
                        open={openDigitalSignature}
                        onClose={() => setOpenDigitalSignature(false)}
                        onSubmit={values =>
                          uploadSignedDocuments(values).then(() =>
                            setOpenDigitalSignature(false)
                          )
                        }
                        // files={agreementClaim.supplier.signedContractDocument}
                      />
                    </Grid>
                    <FileUploaderDialog
                      title={"Okmányok feltöltése"}
                      description={
                        <Typography>
                          Itt töltheted fel a szerződéskötéshez szükséges
                          okmányokat (
                          <b>személyi igazolvány, lakcímkártya és adókártya</b>
                          ). Kérlek, az okmányok <b>mindkét oldalát</b>{" "}
                          szkenneld be. Minden papíron szerepeljen, hogy “
                          <b>Az adataim kezeléséhez hozzájárulok</b>”, továbbá
                          az aláírásod és a dátum. Fontos, hogy annak a
                          személynek az okmányait töltsd fel, aki a szerződést
                          aláírja.
                        </Typography>
                      }
                      onSubmit={values => uploadDocumentCopy(values)}
                      files={agreementClaim?.supplier.documents.filter(
                        doc => doc.type === "document_copy"
                      )}
                    >
                      {({ openDialog }) => {
                        return (
                          <Button variant={"outlined"} onClick={openDialog}>
                            Okmányok feltöltése
                          </Button>
                        );
                      }}
                    </FileUploaderDialog>
                    <HBNYRegistrationDialogs
                      agreementClaimId={agreementClaim.id}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          )}
          {agreementClaim?.status === "PAYMENT_DONE" ||
            agreementClaim?.status === "PAYMENT_REVIEW_RISK_EVALUATION" ||
            (agreementClaim?.status === "PAYMENT_REVIEW_BACK_OFFICE" && (
              <>
                {!agreementClaim.declined && (
                  <BillSection
                    title={"Szuper, végig is értél a folyamaton!"}
                    img={billSmile}
                  >
                    {szamlazzHu ? (
                      <>
                        <Typography>
                          Egyszerű volt, nem? A szerződést sikeresen aláírtad,
                          most nálunk a labda: elkészítjük neked a személyre
                          szóló felületed, ahol mindent átlátsz és mindent
                          irányíthatsz majd céged digitális faktorálása során.
                          <br />
                          Javasoljuk, hogy a következő számláddal próbáld is ki:
                          miután kiállítottad a számlázz.hu-ban, mi
                          automatikusan betöltjük az ügyfélfelületedre. Itt
                          végig tudod majd követni a teljes folyamatot egészen
                          az utalásig. Onnantól a pénz már nálad van!
                          <br />
                          Örülünk, hogy FlexiBill-es lettél!
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant={"body1"}>
                          Egyszerű volt, nem? A szerződést sikeresen aláírtad,
                          most nálunk a labda: elkészítjük neked a személyre
                          szóló felületed, ahol mindent átlátsz és mindent
                          irányíthatsz majd céged digitális faktorálása során.
                          Ide töltheted majd föl a számlákat, melyek
                          ellenértékét villámgyorsan szeretnél majd megkapni
                          tőlünk.
                        </Typography>
                        <Typography variant={"body1"}>
                          Javasoljuk, hogy a következő számláddal próbáld is ki:
                          kiállítás után töltsd föl hozzánk, mi küldjük a pénzt,
                          Te pedig végig tudod majd követni a teljes folyamatot
                          egészen az utalásig. Onnantól a pénz már nálad van!
                        </Typography>
                        <Typography variant={"body1"}>
                          Örülünk, hogy FlexiBill-es lettél!
                        </Typography>
                      </>
                    )}
                  </BillSection>
                )}
              </>
            ))}
          {agreementClaim?.status === "CLOSED" && (
            <BillSection
              img={billSad}
              title={"A szerződéses igényed lezárásra került"}
            >
              Új szerződéses igény esetén kérlek vedd fel velünk a kapcsolatot,
              vagy indítsd el digitálisan az új igény gombra kattintva.
              <Button
                onClick={() => {
                  history.push("/external-sales/agreement-claims/new-contract");
                }}
              >
                Új igény
              </Button>
            </BillSection>
          )}
          {/*</div>*/}
          <GiveAccessDialog
            open={openGiveAccessDialog}
            onClose={() => setOpenGiveAccessDialog(false)}
            onSendClick={async values => {
              const { data: successResponse, error } = await sendInvite({
                ...values,
                roles: [ROLE_FLEXIPAY_AGREEMENT_UPLOADER]
              });

              if (successResponse) {
                notifySuccessSave({ text: "Meghívó elküldve!" });
                setOpenGiveAccessDialog(false);
              }
              if (error) {
                notifyError({ text: error.message });
              }
            }}
          />
          <CallMeDialog
            open={openCallMeDialog}
            onClose={() => setOpenCallMeDialog(false)}
            imgSrc={billSmile}
          />
          <WeAreHappyDialog
            open={openWeAreHappyDialog}
            onClose={() => setOpenWeAreHappyDialog(false)}
            imgSrc={billHappy}
          />
        </div>
      </PageLayout>
    </>
  );
};

export default withWidth()(NewContractPage);
