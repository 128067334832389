import React, { createContext, useCallback, useMemo } from "react";
import {
  Form,
  FormSpy,
  Field,
  useField,
  useForm,
  useFormState
} from "react-final-form";
import arrayMutators from "final-form-arrays";
import Fields from "./Fields/Fields";
import createDecorator from "final-form-calculate";
import { FieldArray } from "react-final-form-arrays";
import {
  OnChange,
  OnBlur,
  OnFocus,
  ExternallyChanged
} from "react-final-form-listeners";

const FlexiFormContext = createContext({
  fieldTypes: {},
  token: ""
});

const makeExternalFormSubmit = formId => () =>
  // { cancelable: true } required for Firefox
  // https://github.com/facebook/react/issues/12639#issuecomment-382519193
  document
    .getElementById(formId)
    .dispatchEvent(new Event("submit", { cancelable: true }));

const FlexiForm = ({
  id,
  fields,
  render,
  FinalFormFormProps,
  FieldsContainerGridProps,
  decorators = []
}) => {
  const formDecorators = useMemo(
    () => {
      return decorators.map(config => createDecorator(config));
    },
    [decorators]
  );

  const scrollToFirstError = useCallback(() => {
    setTimeout(() => {
      const el = document.querySelector(".field-error");
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    });
  }, []);

  return (
    <Form
      {...FinalFormFormProps}
      mutators={{
        ...arrayMutators,
        ...FinalFormFormProps.mutator,
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        }
      }}
      decorators={formDecorators}
      render={renderProps => {
        return (
          <form
            onSubmit={renderProps.handleSubmit}
            id={id}
            style={{ width: "100%" }}
          >
            <FormSpy
              subscription={{ submitFailed: true }}
              onChange={scrollToFirstError}
            />
            {fields && (
              <Fields
                config={fields}
                ContainerGridProps={FieldsContainerGridProps}
              />
            )}
            {render && render(renderProps)}
          </form>
        );
      }}
    />
  );
};

const withFlexiForm = ({
  id,
  formatBeforeSubmit = values => values,
  validate
}) => WrappedComponent => ({ onSubmit, formatBeforeSubmitProp, ...props }) => {
  const submit = makeExternalFormSubmit(id);
  const formatter = formatBeforeSubmitProp || formatBeforeSubmit;
  return (
    <FlexiForm
      id={id}
      FinalFormFormProps={{
        onSubmit: values => onSubmit(formatter(values)),
        initialValues: props.initialValues,
        validate: validate
      }}
      render={renderProps => (
        <WrappedComponent {...renderProps} {...props} submit={submit} />
      )}
    />
  );
};

const useFieldValue = name => {
  const {
    input: { value }
  } = useField(name);
  return value;
};

export {
  Fields,
  makeExternalFormSubmit,
  FlexiFormContext,
  FieldArray,
  FormSpy,
  Field,
  OnChange,
  OnBlur,
  OnFocus,
  ExternallyChanged,
  withFlexiForm,
  useField,
  useForm,
  useFormState,
  useFieldValue
};

export default FlexiForm;
