import React, { useMemo } from "react";
import BaseField from "./BaseField";
import { Checkbox } from "final-form-material-ui";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withCheckboxErrorMessage from "../withCheckboxErrorMessage";
import { withStyles } from "@material-ui/core";
import FlexiTypography from "../../FlexiTypography/index.js";

const blueCheckBoxStyles = {
  root: {
    "&$checked": {
      color: "#0045de"
    },
    span: {
      fontSize: "12px"
    }
  },
  checked: {}
};

const BlueCheckbox = withCheckboxErrorMessage(
  withStyles(blueCheckBoxStyles)(Checkbox)
);

const CheckboxField = ({
  name,
  required,
  label,
  validate,
  fullWidth,
  disabled,
  ...rest
}) => {
  const fullWidthProps = useMemo(
    () => {
      if (fullWidth) {
        return {
          formControlProps: {
            style: {
              width: "100%"
            }
          }
        };
      }

      return {};
    },
    [fullWidth]
  );

  return (
    <FormControlLabel
      style={{ alignItems: "start" }}
      label={
        <FlexiTypography
          fontSize={14}
          style={{ color: "#4a4a4a", marginTop: "11px" }}
        >
          {label}
        </FlexiTypography>
      }
      control={
        <BaseField
          component={BlueCheckbox}
          name={name}
          required={required}
          validate={validate}
          disabled={disabled}
          type="checkbox"
          {...fullWidthProps}
          {...rest}
        />
      }
    />
  );
};

export default CheckboxField;
