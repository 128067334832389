import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import FlexiLayout from "../../../FlexiComponents/FlexiLayout";
import React, { useEffect, useMemo, useState } from "react";
import payLogo from "../../../images/flexipaybill_hor_white.svg";
import robotImg from "../../../images/robot.svg";
import billAvatar from "../../../images/menuBill.svg";
import waterMarkLogo from "../../../images/fexibill_digitalis_faktoring_feher.svg";

import {
  getStoredCompanyId,
  getStoredToken,
  storeCompanyId
} from "../../../utils/authentication";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { useProfileData } from "../../../hooks/useApi";
import { getConfig } from "../../../utils/config";
import arrowRight from "../../../images/redesignImages/Arrow 1.svg";
import { FlexiExpandableRemoteListContext } from "../../../FlexiComponents/FlexiExpandableRemoteList";
import FlexiFileUploaderProvider from "../../../components/FlexiFileUploadProvider";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import externalSalesRoutes from "./externalSalesRoutes";

const homeLogoUrl = "/";
const logoProps = {
  src: payLogo,
  style: {
    width: "auto",
    marginRight: 48
  },
  alt: "FlexiPay",
  title: "FlexiPay ®"
};

const Wrapper = withStyles(theme => {
  return {
    root: {
      [theme.breakpoints.up("lg")]: {
        paddingTop: "85px"
      }
    }
  };
})(Box);

const ExternalSalesApplication = ({ width, history }) => {
  const { mainCompany, companies, user, loading } = useProfileData();

  const navBarProps = useMemo(() => {
    return {
      onNavListItemClick: () => undefined,
      menuItems: externalSalesRoutes.filter(route => route.menuItem)
    };
  }, [externalSalesRoutes]);

  const companyChooserProps = {
    avatarSrc: robotImg,
    appbarcomponent: !isWidthDown("sm", width),
    companies: [],
    menuItems: [],
    company: "",
    username: "",
    appsIconShow: !isWidthDown("md", width),
    appsIconColor: "#ffffff",
    onCompanySelected: item => {
      if (item && item.id) {
        if (item.id === "LOGOUT") {
          history.push("/app/logout");
        } else {
          storeCompanyId(item.id);
          window.location.href = "/";
        }
      }
    }
  };

  if (mainCompany) {
    companyChooserProps.company = mainCompany?.party?.partyName;
    companyChooserProps.logoPath = mainCompany?.profilePicture?.path;
  }

  if (user) {
    companyChooserProps.company = `${user.surname} ${user.firstname}`;
    companyChooserProps.username = user.email;
  }

  companyChooserProps.companies = [
    ...companies.slice(0, 4),
    {
      name: "Kijelentkezés",
      id: "LOGOUT",
      border: false,
      hideOnMobile: true,
      icon: <img alt={"arrowRight"} src={arrowRight} />
    }
  ];

  const layoutDemoProps = {
    companyChooserProps,
    navBarProps,
    homeLogoUrl,
    logoProps,
    sidebarbgcolor: "#142595",
    appbarbgcolor: "#000B55"
  };

  const flexiPayUrl = `${getConfig(
    "FLEXIBILL_APP_URL"
  )}/auth/token/${getStoredToken()}/company-id/${getStoredCompanyId()}`;

  const otherInterface = !loading && mainCompany && !mainCompany?.roles?.length;
  const accountSuspended = !loading && mainCompany?.active === false;
  const noCompany = !loading && !mainCompany;

  const [token, setToken] = useState(getStoredToken());

  useEffect(() => {
    const setAndCheckToken = () => {
      setTimeout(() => {
        const tokenFromLocalStorage = getStoredToken();
        if (!tokenFromLocalStorage) {
          setAndCheckToken();
        } else {
          setToken(tokenFromLocalStorage);
        }
      }, 1000);
    };

    setAndCheckToken();
  }, []);

  const salesData = mainCompany?.flexipayContactList;
  const salesProps = {
    placeholderImg: billAvatar,
    isSalesDepartment: salesData?.length === 1,
    salesContact: salesData?.[0],
    invoiceHelp: salesData?.[0],
    contractHelp: salesData?.[1]
  };
  return (
    <FlexiExpandableRemoteListContext.Provider value={{ token }}>
      <FlexiFileUploaderProvider token={token}>
        {/*<AccountMatchingDialog*/}
        {/*  otherInterface={otherInterface}*/}
        {/*  accountSuspended={accountSuspended}*/}
        {/*  noCompany={noCompany}*/}
        {/*  // onClose={() => setAccountMatchingDialogOpen(null)}*/}
        {/*/>*/}
        {/*<GreetingMessageDialog />*/}
        <FlexiLayout
          salesProps={salesData ? salesProps : undefined}
          waterMark
          waterMarkSrc={waterMarkLogo}
          navBarProps={navBarProps}
          hasDemoAppBar={getConfig("APP_ENV") !== "production"}
          {...layoutDemoProps}
        >
          <Wrapper>
            <Switch>
              {externalSalesRoutes.map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                />
              ))}
              {Boolean(navBarProps.menuItems.length) && (
                <Redirect to={navBarProps.menuItems[0].path} />
              )}
            </Switch>
          </Wrapper>
        </FlexiLayout>
      </FlexiFileUploaderProvider>
    </FlexiExpandableRemoteListContext.Provider>
  );
};

export default withRouter(withWidth()(ExternalSalesApplication));
