import {
  getStoredSsoToken,
  resetAuthData,
  resetToken
} from "../../utils/authentication";
import { useLoginPageData } from "../../hooks/useApi";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import LoadingDialog from "../../FlexiComponents/FlexiLoader";
import { useErrorOccurredNotification } from "../../hooks/useNotificationState";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import { useStoreData } from "../../hooks/useDataState";

const searchParams = new URLSearchParams(window.location.search);
export const szamlazzhuSsoLoginToken = searchParams.get(
  "szamlazzhuSsoLoginToken"
);

const SsoPage = () => {
  const ssoToken = getStoredSsoToken();
  const [doLogin, loading] = useLoginPageData();
  const { push: redirect } = useHistory();
  const notifyError = useErrorOccurredNotification();
  const storeData = useStoreData();

  useEffect(() => {
    resetToken();
    doLogin(
      szamlazzhuSsoLoginToken ? szamlazzhuSsoLoginToken : ssoToken,
      szamlazzhuSsoLoginToken ? "SzamlazzhuSsoLoginToken" : "SzamlazzhuSsoToken"
    ).then(({ data, error }) => {
      storeData("szamlazzHu", true);
      if (data) {
        redirect("/app");
      } else {
        notifyError(selectErrorNotificationMessage(error));
      }
    });
  }, []);

  return <LoadingDialog loading={true} />;
};

export default SsoPage;
