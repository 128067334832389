import { TextField } from "final-form-material-ui";
import React from "react";
import { formatNumber, normalizeNumber } from "../numberUtils";
import { InputAdornment } from "@material-ui/core";
import BaseField from "./BaseField";

const currencyFieldProps = {
  format: formatNumber,
  parse: normalizeNumber
};

const RenderCurrencyField = ({ currency, ...rest }) => {
  const props = {};

  if (currency) {
    props.InputProps = {
      endAdornment: <InputAdornment position="end">{currency}</InputAdornment>
    };
  }

  return <TextField {...rest} {...props} />;
};

export const CurrencyField = props => (
  <BaseField
    {...props}
    {...currencyFieldProps}
    component={RenderCurrencyField}
  />
);

export default CurrencyField;
