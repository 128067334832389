import Menu from "@material-ui/core/Menu";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import React from "react";
import PropTypes from "prop-types";
import { compose, withHandlers, withState } from "recompose";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { IconButton } from "@material-ui/core";
import FlexiTypography from "../../FlexiTypography/index.js";
import FlexiDialog from "../../FlexiDialog/index.js";
import Grid from "@material-ui/core/Grid";

const ListItem = styled(MenuItem)`
  && {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    &:last-child {
      border-bottom: 0;
    }
  }
`;

const enhanceListMenuItem = compose(
  withState("confirmPopupShow", "setConfirmPopupShow", false)
);

const ListMenuItem = enhanceListMenuItem(
  ({
    icon,
    text,
    dispatch,
    confirmTitle = "Biztos vagy benne?",
    yesButtonText = "Törlés",
    noButtonText = "Mégse",
    confirmText,
    confirmPopupShow,
    setConfirmPopupShow,
    onClick,
    ...rest
  }) => {
    const onListClick = confirmText ? () => setConfirmPopupShow(true) : onClick;

    return (
      <React.Fragment>
        <ListItem onClick={onListClick} {...rest}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{text}</ListItemText>
        </ListItem>
        {confirmText && (
          <FlexiDialog
            open={confirmPopupShow}
            title={confirmTitle}
            dialogActions={
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant={"text"}
                    onClick={() => setConfirmPopupShow(false)}
                  >
                    {noButtonText}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      onClick();
                      setConfirmPopupShow(false);
                    }}
                  >
                    {yesButtonText}
                  </Button>
                </Grid>
              </Grid>
            }
          >
            <FlexiTypography>{confirmText}</FlexiTypography>
          </FlexiDialog>
        )}
      </React.Fragment>
    );
  }
);

ListMenuItem.propTypes = {
  confirmText: PropTypes.string,
  dispatch: PropTypes.func,
  icon: PropTypes.element,
  text: PropTypes.any
};

const enhance = compose(
  withState("anchor", "setAnchor", null),
  withHandlers({
    onOpen: props => e => props.setAnchor(e.currentTarget),
    onClose: props => () => props.setAnchor(null)
  })
);

const KebabMenu = enhance(({ onOpen, onClose, anchor, value, items }) => {
  if (!items.length) {
    return null;
  }

  return (
    <React.Fragment>
      <IconButton size="small" onClick={onOpen} style={{ margin: "4px" }}>
        <MoreIcon />
      </IconButton>
      <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={onClose}>
        {items.map(item => (
          <ListMenuItem
            key={`${item.id}-${value}`}
            id={item.id}
            icon={item.icon}
            text={item.text}
            confirmText={item.confirmText}
            onClick={item.onClick}
            roles={item.roles}
            yesButtonText={item.yesButtonText}
            noButtonText={item.noButtonText}
          />
        ))}
      </Menu>
    </React.Fragment>
  );
});

const makeKebabMenuRenderer = getMenuItems => props => (
  // eslint-disable-next-line react/prop-types
  <KebabMenu items={getMenuItems(props.data)} {...props} />
);

// const makeKebabMenuRenderer = getMenuItems =>
//   enhance(props => {
//     console.log(props, 'hahahahahah');
//     return (
//       <React.Fragment>
//         <Button
//           color="secondary"
//           variant="contained"
//           size="small"
//           disableRipple
//           // onClick={onOpen}
//         >
//           <MoreIcon />
//         </Button>
//         <Menu>
//           {/*{items.map(item => (*/}
//           {/*  <ListMenuItem*/}
//           {/*    key={`${item.id}-${value}`}*/}
//           {/*    id={item.id}*/}
//           {/*    icon={item.icon}*/}
//           {/*    text={item.text}*/}
//           {/*    // todo confirm*/}
//           {/*    onClick={item.onClick}*/}
//           {/*    roles={item.roles}*/}
//           {/*  />*/}
//           {/*))}*/}
//         </Menu>
//       </React.Fragment>
//     );
//   });

export default makeKebabMenuRenderer;
