/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
    flexiBarChartTitle: {
        id: 'app.components.flexiBarChartTitle',
        defaultMessage: 'Számla státuszok',
    },
});

