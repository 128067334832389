import React from "react";
import { Button, Divider, Typography } from "@material-ui/core";
import { Fields } from "../../FlexiComponents/FlexiForm";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/ChevronLeft";
import Link from "../../FlexiComponents/FlexiLink";
import FlexiForm from "../../FlexiComponents/FlexiForm";

const StepTwo = ({ onBackButtonClick, onClick }) => {
  const contactFields = [
    [
      {
        fieldType: "email",
        name: `email`,
        label: "E-mail cím",
        required: true
      }
    ],
    [
      {
        fieldType: "checkbox",
        title: "Felhasználási feltételek",
        name: "acceptTermsAndConditions",
        required: true,
        label: (
          <Typography variant={"body1"} style={{ lineHeight: "28px" }}>
            Elfogadom a{" "}
            <Link
              style={{
                fontWeight: "bold",
                textDecoration: "none",
                color: "#FF6630",
                cursor: "help"
              }}
              href={
                "https://storage.googleapis.com/flexibill-documents/documents/20220201_Flexibill_Digitalis_Faktoring_ASZF.pdf"
              }
              target="_blank"
            >
              FlexiBill{" "}
            </Link>{" "}
            és a{" "}
            <Link
              style={{
                fontWeight: "bold",
                textDecoration: "none",
                color: "#FF6630",
                cursor: "help"
              }}
              href={"https://www.szamlazz.hu/adatvedelem/"}
              target={"_blank"}
            >
              Számlázz.hu
            </Link>{" "}
            adatkezelési tájékoztatóját.
          </Typography>
        ),
        url:
          "https://storage.googleapis.com/flexibill-documents/documents/20220201_Flexibill_Digitalis_Faktoring_ASZF.pdf"
      }
    ]
  ];
  return (
    <FlexiForm
      FinalFormFormProps={{
        onSubmit: onClick
      }}
      id={"subscriptionContact"}
      render={() => {
        return (
          <div>
            <Button
              style={{
                paddingBottom: "47px",
                textAlign: "left",
                color: "#2227AC"
              }}
              variant={"text"}
              onClick={onBackButtonClick}
            >
              <BackIcon />
              Vissza
            </Button>
            <Typography style={{ paddingBottom: "23px" }} variant={"h3"}>
              Hova küldhetjük a kalkulációt?
            </Typography>
            <Typography style={{ paddingBottom: "47px" }}>
              A kalkulációt e-mailben is megkapod. Ehhez kérjük, add meg e-mail
              címed!
            </Typography>
            <Divider />
            <Grid style={{ paddingTop: "47px" }} container>
              <Grid item xs={12}>
                <Fields config={contactFields} />
                <Button style={{ marginTop: "20px" }} type={"submit"}>
                  Kérem a kalkulációt
                </Button>
              </Grid>
            </Grid>
          </div>
        );
      }}
    />
  );
};

export default StepTwo;
