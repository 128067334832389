import SimpleTextField from "./SimpleTextField";
import React from "react";
import msk from "msk";
// import { taxNumber } from "../validators";

const parse = value => msk.fit(value, "99999999-9-99");

const TaxNumberField = ({ name, required, label, fullWidth, disabled }) => (
  <SimpleTextField
    name={name}
    required={required}
    label={label}
    parse={parse}
    // validate={taxNumber}
    fullWidth={fullWidth}
    disabled={disabled}
  />
);

export default TaxNumberField;