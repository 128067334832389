/**
 * @render react
 * @name FlexiNavListItem
 * @description FlexiNavListItem
 * @example
 * <FlexiNavListItem />
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { lighten } from "polished";
import Badge from "@material-ui/core/Badge";
import { Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledNavLink = styled(({ exact, disableHref, ...rest }) =>
  disableHref ? <div {...rest} /> : <NavLink exact={exact} {...rest} />
)`
  font-family: "Nunito-Regular", Helvetica;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 15px 0 15px 16px;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.sidebarbgcolor ? lighten(0.1, props.sidebarbgcolor) : "#848484"};
  }

  &.active {
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;

      border-right: 10px solid white;
      position: absolute;
      right: -2px;
    }

    background-color: ${(props) =>
      props.sidebarbgcolor ? lighten(0.1, props.sidebarbgcolor) : "#848484"};

    &:hover {
      background-color: ${(props) =>
        props.sidebarbgcolor ? lighten(0.2, props.sidebarbgcolor) : "#848484"};
    }
  }
`;

const StyledListItemText = withStyles({
  root: {
    paddingLeft: "0",
    color: "white",
    lineHeight: 1.2,
    marginTop: "1px !important",
    marginBottom: "0 !important",
  },
})(ListItemText);

const StyledBadge = withStyles({
  root: {
    color: "white",
  },
  span: {
    top: "0px",
    right: "7px",
    marginRight: "5px",
    backgroundColor: (props) => (props.badgeColor ? props.badgeColor : "red"),
  },
})(Badge);

const StyledListItemIcon = withStyles({
  root: {
    marginRight: "10px",
    minWidth: "initial",
    height: "20px",
    lineHeight: 1,
    color: "#fff !important",
    svg: { fontSize: "20px" },
  },
})(ListItemIcon);

const StyledListItem = withStyles({
  root: {
    padding: "0",
    "&:hover": {
      backgroundColor: (props) =>
        props.sidebarbgcolor ? lighten(0.1, props.sidebarbgcolor) : "#848484",
    },
  },
})(ListItem);

const NavListItem = ({
    first,
  text,
  path,
  icon,
  exact = false,
  disableHref,
  subMenuItems,
  sidebarbgcolor,
  badgeContent,
  badgeColor,
  ...rest
}) => (
    <React.Fragment>
      {!first &&
      <Divider style={{ width: "160px", marginLeft: 20, backgroundColor: "#fff", opacity: 0.2  }} />}
  <StyledListItem disableGutters {...rest} sidebarbgcolor={sidebarbgcolor}>
    <StyledNavLink
      to={path}
      exact={exact}
      disableHref={disableHref}
      sidebarbgcolor={sidebarbgcolor}
    >
      {(icon && badgeContent && (
        <StyledBadge
          overlap={"circle"}
          badgeContent={badgeContent}
          badgeColor={badgeColor}
        >
          <StyledListItemIcon>{icon}</StyledListItemIcon>
        </StyledBadge>
      )) || <StyledListItemIcon>{icon}</StyledListItemIcon>}
      <StyledListItemText disableTypography primary={text} />
    </StyledNavLink>
  </StyledListItem>
    </React.Fragment>
);

NavListItem.propTypes = {
  text: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.element,
  exact: PropTypes.bool,
  disableHref: PropTypes.bool,
  subMenuItems: PropTypes.any,
};

export default NavListItem;
