import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";

const FlexiSalesContact = ({
  isSalesDepartment,
  avatarPlaceholder,
  salesContact,
  invoiceHelp,
  contractHelp,
}) => {

  // const salesProps = {
  //   isSalesDepartment:
  //       salesProps2.flexipayContactList.length === 1,
  //   salesContact: salesProps2.flexipayContactList[0],
  //   invoiceHelp: salesProps2.flexipayContactList[0],
  //   contractHelp: salesProps2.flexipayContactList[1],
  // };

  return (

    <div>
      {isSalesDepartment ? (
        <Grid container direction={"column"} style={{ padding: 20 }}>
          <Grid item style={{ margin: "auto", paddingBottom: "12px" }}>
            <Avatar
              style={{ width: "50px", height: "50px" }}
              src={salesContact.avatar || avatarPlaceholder}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{ color: "#fff", fontWeight: "bold",  fontFamily: "Poppins" }}
              fontSize={16}
            >
              A Te értékesítőd:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{ color: "#fff", fontFamily: "Poppins"}}
              fontSize={12}
            >
              {salesContact.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{ color: "#fff", fontWeight: "bold",  fontFamily: "Poppins" }}
              fontSize={12}
            >
              {salesContact.email}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{ color: "#fff", fontWeight: "bold", fontFamily: "Poppins"  }}
              fontSize={12}
            >
              {salesContact.phoneNumber}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction={"column"} style={{ padding: "15px 25px 15px " }}>
          <Grid item xs={12} style={{ paddingBottom: "6px", borderBottom: "1px solid #fff" }}>
            <Typography
              style={{ color: "#fff", fontWeight: "bold",  fontFamily: "Poppins" }}
              fontSize={16}
            >
              Kérdésed van?
            </Typography>
          </Grid>
          <Grid container style={{paddingTop: "16px"}}>
            <Grid item xs={12}>
              <Typography
                style={{ color: "#fff", fontFamily: "Poppins"  }}
                fontSize={12}
              >
                Faktorálással kapcsolatban:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{ color: "#fff", fontWeight: "bold", fontFamily: "Poppins"  }}
                fontSize={12}
              >
                {invoiceHelp.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{ color: "#fff", fontWeight: "bold", fontFamily: "Poppins"  }}
                fontSize={12}
              >
                {invoiceHelp.phoneNumber}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{ color: "#fff", fontFamily: "Poppins"  }}
              fontSize={12}
            >
              Szerződéssel kapcsolatban:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{ color: "#fff", fontWeight: "bold", fontFamily: "Poppins"  }}
              fontSize={14}
            >
              {contractHelp.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{ color: "#fff", fontWeight: "bold", fontFamily: "Poppins"  }}
              fontSize={12}
            >
              {contractHelp.phoneNumber}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

FlexiSalesContact.propTypes = {
  invoiceHelp: PropTypes.array,
  contractHelp: PropTypes.array,
  salesContact: PropTypes.array,
  isSalesDepartment: PropTypes.bool,
};

export default FlexiSalesContact;
