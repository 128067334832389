import React from "react";
import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import Typography from "@material-ui/core/Typography";
import { Form } from "react-final-form";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { formatCurrency } from "../../FlexiComponents/FlexiCurrencyFormatter";
import robotImg from "../../images/robot.svg";
import CurrencyField from "../FormFields/CurrencyField";

const LimitChangeDialog = props => {
  return (
    <FlexiDialog
      dialogActions={
        <Button
          onClick={() => {
            document
              .getElementById("limitChangeForm")
              .dispatchEvent(new Event("submit", { cancelable: true }));
          }}
        >
          Küldés
        </Button>
      }
      onClose={props.onClose}
      open={props.open}
      title={"Limit megváltoztatása"}
    >
      <Grid container direction={"column"}>
        <Grid item style={{ paddingBottom: "24px" }}>
          <Typography fontSize={16}>
            Amennyiben szeretnéd a vevődhöz tartozó limitet megváltoztatni,
            kérlek, add meg az összeget és értesítjük munkatársunkat!
          </Typography>
        </Grid>
        <Grid item style={{ display: "inline-flex" }}>
          <Avatar
            src={(props.company && props.company.logoPath) || robotImg}
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          <Typography
            fontSize={16}
            style={{ lineHeight: 1.4, paddingBottom: "15px" }}
          >
            {(props.company && props.company.companyName) ||
              (props.company && props.company.customerName)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontSize={16} style={{ paddingBottom: "35px" }}>
            Jelenlegi limit összege:{" "}
            {props.company &&
              props.company.currency &&
              formatCurrency(
                (props.company && props.company.all) ||
                  props.company?.limitAmount,
                {
                  currency: props.company && props.company.currency
                }
              )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography bold fontSize={16} style={{ paddingBottom: "20px" }}>
            Erre az összegre szeretném megváltoztatni:
          </Typography>
        </Grid>
        <Grid item>
          <Form
            onSubmit={props.onSubmit}
            render={({ handleSubmit }) => (
              <form id={"limitChangeForm"} onSubmit={handleSubmit}>
                <CurrencyField
                  fullWidth
                  name={"newLimit"}
                  label={`Új limit (${props.company?.currency})`}
                  initialValue={
                    props.company?.all || props.company?.limitAmount
                  }
                />
              </form>
            )}
          />
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};

export default LimitChangeDialog;
