import React from "react";
import { useIntl } from "react-intl";
import EditIcon from "@material-ui/icons/Edit";
import { defineMessages } from "react-intl.macro";
import Switch from "@material-ui/core/Switch";
import EmailIcon from "@material-ui/icons/Mail";
import CancelIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import TrashIcon from "@material-ui/icons/Delete";
import {
  ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE,
  ROLE_FLEXIPAY_CUSTOMER,
  ROLE_FLEXIPAY_INVOICE_MANAGER,
  ROLE_FLEXIPAY_SUPER_ADMIN
} from "../../roles";
import useRoleFilter from "../../hooks/useRoleFilter";
import useHasPermission from "../../components/RoleProvider/useHasPermission";
import { useProfileData } from "../../hooks/useApi";
import FlexiExpandableRemoteList, {
  makeLongTextColumnDefinitionConfig
} from "../../FlexiComponents/FlexiExpandableRemoteList";
import { getUrlWithCompanyId } from "../../utils/urlHelpers";
import { getStoredToken } from "../../utils/authentication";
import { Box, Typography } from "@material-ui/core";

const columnMessages = defineMessages({
  coworkerName: {
    id: "listColumnHeader.coworkers.coworkerName",
    defaultMessage: "Partner neve"
  },
  coworkerEmail: {
    id: "listColumnHeader.coworkers.coworkerEmail",
    defaultMessage: "E-mail cím"
  },
  coworkerRole: {
    id: "listColumnHeader.coworkers.coworkerRole",
    defaultMessage: "Jogosultság"
  },
  coworkerCreateDate: {
    id: "listColumnHeader.coworkers.coworkerCreateDate",
    defaultMessage: "Létrehozva"
  },
  coworkerStatus: {
    id: "listColumnHeader.coworkers.coworkerStatus",
    defaultMessage: "Státusz"
  },
  coworkerDeactivation: {
    id: "listColumnHeader.coworkers.coworkerDeactivation",
    defaultMessage: "Státusz"
  },
  coworkerStatusPENDING: {
    id: "listColumnHeader.coworkers.coworkerStatusPENDING",
    defaultMessage: "Folyamatban"
  },
  coworkerStatusCOMPLETED: {
    id: "listColumnHeader.coworkers.coworkerStatusCOMPLETED",
    defaultMessage: "Aktív"
  },
  coworkerStatusINACTIVE: {
    id: "listColumnHeader.coworkers.coworkerStatusINACTIVE",
    defaultMessage: "Inaktív"
  },
  coworkerROLE_EDIT: {
    id: "listColumnHeader.coworkers.coworkerROLE_EDIT",
    defaultMessage: "Írás"
  },
  coworkerROLE_FLEXIPAY_INVOICE_MANAGER: {
    id: "listColumnHeader.coworkers.coworkerROLE_FLEXIPAY_INVOICE_MANAGER",
    defaultMessage: "Számlakezelő"
  },
  coworkerROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE: {
    id: "listColumnHeader.coworkers.coworkerROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE",
    defaultMessage: "Engedményezés végrehajtó"
  },
  coworkerROLE_SUPER_ADMIN: {
    id: "listColumnHeader.coworkers.coworkerROLE_SUPER_ADMIN",
    defaultMessage: "Adminisztrátor"
  },
  coworkerROLE_FLEXIPAY_CUSTOMER: {
    id: "listColumnHeader.coworkers.coworkerROLE_FLEXIPAY_CUSTOMER",
    defaultMessage: "Adminisztrátor"
  },
  coworkerROLE_FLEXIPAY_CUSTOMER_PLUS: {
    id: "listColumnHeader.coworkers.coworkerROLE_FLEXIPAY_CUSTOMER_PLUS",
    defaultMessage: "Adminisztrátor"
  },
  coworkerROLE_FLEXIPAY_AGREEMENT_UPLOADER: {
    id: "listColumnHeader.coworkers.coworkerROLE_FLEXIPAY_AGREEMENT_UPLOADER",
    defaultMessage: "Dokumentum feltöltő"
  }
});

const listItemMessages = defineMessages({
  coworkerEmailResend: {
    id: "coworkersList.menu.coworkerEmailResend",
    defaultMessage: "E-mail újraküldése"
  },
  coworkerEmailEdit: {
    id: "coworkersList.menu.coworkerEmailEdit",
    defaultMessage: "Szerkesztés"
  },
  coworkerDeactivation: {
    id: "coworkersList.menu.coworkerDeactivation",
    defaultMessage: "Deaktiválás"
  },
  coworkerActivation: {
    id: "coworkersList.menu.coworkerActivation",
    defaultMessage: "Aktiválás"
  },
  coworkerDelete: {
    id: "coworkersList.menu.coworkerDelete",
    defaultMessage: "Törlés"
  }
});

const getRoleTranslations = role =>
  columnMessages[`coworker${role}`] || {
    id: `coworker${role}-MISSING`,
    defaultMessage: role
  };

const makeGetMenuItems = (
  intl,
  onResendEmailClick,
  onActivateClick,
  onDeactivationClick,
  onEditClick,
  onDeleteClick
) => item => {
  const menuItems = {
    resendEmail: {
      id: "coworker-list-resendEmail",
      icon: <EmailIcon />,
      text: intl.formatMessage(listItemMessages.coworkerEmailResend),
      onClick: () => onResendEmailClick(item)
    },
    activation: {
      id: "coworker-list-activation",
      icon: <CheckIcon />,
      text: intl.formatMessage(listItemMessages.coworkerActivation),
      onClick: () => onActivateClick(item)
    },
    deactivation: {
      id: "coworker-list-deactivation",
      icon: <CancelIcon />,
      text: intl.formatMessage(listItemMessages.coworkerDeactivation),
      onClick: () => onDeactivationClick(item)
    },
    edit: {
      id: "coworker-list-edit",
      icon: <EditIcon />,
      text: intl.formatMessage(listItemMessages.coworkerEmailEdit),
      onClick: () => onEditClick(item)
    },
    delete: {
      id: "coworker-list-delete",
      icon: <TrashIcon />,
      text: intl.formatMessage(listItemMessages.coworkerDelete),
      confirmText: "A törlés nem visszavonható!",
      onClick: () => onDeleteClick(item)
    }
  };

  const { user } = useProfileData();

  let items = [];

  if (!item.active && item.status !== "INVITED") {
    items.push(menuItems.activation);
  }

  if (item.status === "INVITED") {
    items.push(menuItems.resendEmail);
  }

  if (item?.user?.id !== user?.id) {
    if (item.active) {
      items.push(menuItems.deactivation);
    } else {
      items.push(menuItems.delete);
    }
  }

  if (
    useRoleFilter(item.roles) === ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE ||
    ROLE_FLEXIPAY_INVOICE_MANAGER
  ) {
    return (items = []);
  }

  if (item?.user?.id === user?.id) {
    return items;
  } else {
    return [menuItems.edit, ...items];
  }
};

const makeSwitchRenderer = onDeactivateChange => params => {
  const { user } = useProfileData();
  const hasPermission = useHasPermission();
  const canModify = hasPermission([ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE]);
  const disabled =
    !canModify ||
    params.data.status === "INVITED" ||
    params.data?.userEmail === user?.email ||
    params.data?.roles?.includes(ROLE_FLEXIPAY_SUPER_ADMIN);

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      style={{ gap: "0.5rem", opacity: disabled ? 0.3 : 1 }}
    >
      <Switch
        checked={params.value}
        onChange={(e, value) => onDeactivateChange(params.data, value)}
        disabled={disabled}
      />
      <Typography variant={params.value ? "caption" : "body1"}>
        {params.value ? "Aktív" : "Inaktív"}
      </Typography>
    </Box>
  );
};

const getColumns = (intl, getMenuItems, onDeactivateChange) => {
  return [
    {
      headerName: intl.formatMessage(columnMessages.coworkerName),
      field: "userFirstname",
      sortable: true,
      autoHeight: true,
      resizable: true,
      mobileCellXs: 12,
      cellClass: "cell-wrap-text",
      valueGetter: ({ data }) => {
        if (data.userFirstname && data.userSurname) {
          return `${data.userSurname} ${data.userFirstname}`;
        } else {
          return "-";
        }
      }
    },
    {
      headerName: intl.formatMessage(columnMessages.coworkerEmail),
      valueGetter: ({ data }) => data.userEmail || data.email || "-",
      sortable: true,
      autoHeight: true,
      cellClass: "cell-wrap-text"
    },
    {
      headerName: intl.formatMessage(columnMessages.coworkerRole),
      field: "roles",
      sortable: true,
      resizable: true,
      autoHeight: true,
      ...makeLongTextColumnDefinitionConfig({
        tooltip: true,
        lines: 1
      }),
      valueGetter: ({ data }) => {
        const resultRoles = [];
        const roles = data.roles.filter(name => !name.includes("FLEXIBILL"));

        if (roles) {
          roles.forEach(role => {
            resultRoles.push(intl.formatMessage(getRoleTranslations(role)));
          });
          return resultRoles.join(", ");
        } else {
          return "-";
        }
      }
    },
    {
      headerName: intl.formatMessage(columnMessages.coworkerCreateDate),
      field: "createdAt",
      sortable: true,
      autoHeight: true,
      cellClass: "cell-wrap-text"
    }
  ];
};

const CoworkersList = ({
  onResendEmailClick,
  onActivateClick,
  onDeactivationClick,
  onEditClick,
  onDeleteClick,
  coworkers,
  listRef,
  readOnly
}) => {
  const intl = useIntl();

  const getMenuItems = makeGetMenuItems(
    intl,
    onResendEmailClick,
    onActivateClick,
    onDeactivationClick,
    onEditClick,
    onDeleteClick
  );

  const onDeactivateChange = (data, activated) => {
    if (activated) {
      onActivateClick(data);
    } else {
      onDeactivationClick(data);
    }
  };

  let columnDefs = useRoleFilter(
    getColumns(intl, getMenuItems, onDeactivateChange)
  );

  const statusAndMenu = [
    {
      field: "active",
      headerName: intl.formatMessage(columnMessages.coworkerDeactivation),
      sortable: true,
      autoHeight: true,
      cellClass: "cell-wrap-text",
      cellRendererFramework: makeSwitchRenderer(onDeactivateChange)
    },
    {
      field: "id",
      menu: true,
      roles: [ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE],
      confirmText: "A törlés nem visszavonható!",
      getMenuItems
    }
  ];

  if (!readOnly) {
    columnDefs = columnDefs.concat(statusAndMenu);
  }

  return (
    <FlexiExpandableRemoteList
      readOnly={readOnly}
      ref={listRef}
      columnDefs={columnDefs}
      items={coworkers}
      mobileCellSpacing={0}
      url={getUrlWithCompanyId("/coworker")}
      token={getStoredToken()}
      suppressExpand
    />
  );
};

export default CoworkersList;
