import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { compose } from "recompose";

const Wrapper = styled.div`
  position: relative;

  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 100ms ease-in;
  }

  div.transition-group {
    position: relative;
  }

  div.route-section {
    min-width: 320px;
    min-height: 100vh;
    position: absolute;
    left: 0;
    width: 100%;
  }
`;

const RouteWrapper = ({ loading, location, children, width, ...rest }) => (
  <Wrapper {...rest}>
    <div className="route-section">
      <Grid container justify="center">
        <Grid item xs={12} lg={12}>
          {children}
        </Grid>
      </Grid>
    </div>
  </Wrapper>
);

RouteWrapper.propTypes = {
  children: PropTypes.any,
  width: PropTypes.string
};

export default compose(withWidth())(RouteWrapper);
