import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import { isInclusivelyBeforeDay, SingleDatePicker } from "react-dates";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import huLocale from "moment/locale/hu";
import BaseField from "./BaseField";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { FormHelperText } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

moment.locale("hu", huLocale);

const Wrapper = styled.div`
  && {
    .DayPickerNavigation * {
      box-sizing: border-box;
    }

    .SingleDatePicker_picker__fullScreenPortal {
      z-index: 1100 !important;
    }

    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: #0045de !important;
      border: 1px double #0045de !important;
    }

    width: 100%;
    input {
      font-size: 16px;
      font-weight: 400;
      padding: 6px 0 2px;
      border-bottom: 0;
    }

    .SingleDatePickerInput_calendarIcon {
      padding: 5px;
      position: absolute;
      right: 0;
    }

    .SingleDatePicker,
    .SingleDatePickerInput,
    .DateInput {
      width: 100%;
    }

    .DateInput {
      &:after {
        content: "";
        width: 100%;
        bottom: 0;
        position: absolute;
        left: 0;
        border-bottom: ${(props) =>
          props.error ? "2px solid #f44336" : "1px solid rgba(0, 0, 0, 0.42)"};
        transition: border 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }

    button[disabled] {
      cursor: default;
    }

    &:hover .SingleDatePicker:after {
      border-bottom: 2px solid rgba(0, 0, 0, 0.87);
      transition: border 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    ${(props) =>
      props.error &&
      `
      
      p::after {
          border-bottom: 2px solid #f44336;
      }
      
      input {
      
        &::placeholder {
          color: #f44336;
        }
      }
  `};
  }
`;

const CustomLabel = styled.label`
  width: 100%;
  height: 16px;
  color: ${(props) => (props.error ? "#f44336" : "rgba(0, 0, 0, 0.54)")};
  line-height: 16px;
  font-size: 16px;
  display: block;
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
  font-family: inherit;
`;

const DatePickerField = ({
  input,
  label,
  disabled,
  meta,
  width,
  openDirection,
  disableDayBeforeToday,
  FormHelperTextProps,
  muiPicker,
}) => {
  return (
    <React.Fragment>
      {!muiPicker && (
        <CustomLabel
          disabled={disabled}
          error={meta.touched && meta.error && typeof meta.error === "string"}
        >
          {label}
        </CustomLabel>
      )}
      <Wrapper
        error={meta.touched && meta.error && typeof meta.error === "string"}
      >
        {muiPicker && (
          <MuiPickersUtilsProvider
            libInstance={moment}
            utils={MomentUtils}
            locale={"hu"}
          >
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              format="YYYY.MM.DD."
              margin="normal"
              label={label}
              value={input.value || ""}
              InputLabelProps={{
                shrink: input.value ? true : undefined,
                filled: input.value ? true : undefined,
              }}
              error={
                meta.touched && meta.error && typeof meta.error === "string"
              }
              helperText={
                meta.touched &&
                meta.error &&
                typeof meta.error === "string" &&
                meta.error
              }
              disabledPast={disableDayBeforeToday}
              disabled={disabled}
              autoOk
              onChange={(date) => {
                if (date && date.isValid()) {
                  input.onChange(date.format("YYYY-MM-DD"));
                } else if (date === null) {
                  input.onChange(undefined);
                }
              }}
              inputProps={{
                onChange: (e) => {
                  if (
                    moment(
                      e.target.value,
                      ["YYYYmmDD", "YYYY.mm.DD"],
                      true
                    ).isValid()
                  ) {
                    input.onChange(moment(e.target.value).format("YYYY-MM-DD"));
                  }
                },
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        )}
        {!muiPicker && (
          <SingleDatePicker
            placeholder={"Válassz"}
            date={input.value && moment(input.value)}
            onDateChange={(date) => {
              if (date) {
                input.onChange(moment(date).format("YYYY-MM-DD"));
              } else {
                input.onChange(undefined);
              }
            }}
            displayFormat="YYYY.MM.DD."
            focused={meta.active}
            onFocusChange={({ focused }) =>
              focused ? input.onFocus() : input.onBlur()
            }
            isOutsideRange={(day) =>
              disableDayBeforeToday
                ? isInclusivelyBeforeDay(day, moment().subtract(1, "day"))
                : false
            }
            noBorder
            showDefaultInputIcon
            inputIconPosition="after"
            disabled={disabled}
            withFullScreenPortal={isWidthDown("sm", width) && true}
            withPortal
            orientation={isWidthDown("sm", width) ? "vertical" : "horizontal"}
            numberOfMonths={isWidthDown("sm", width) ? 3 : 2}
            anchorDirection="right"
            hideKeyboardShortcutsPanel
            openDirection={openDirection}
          />
        )}

        {!muiPicker &&
          meta.touched &&
          meta.error &&
          typeof meta.error === "string" && (
            <FormHelperText {...FormHelperTextProps} error>
              {meta.error}
            </FormHelperText>
          )}
      </Wrapper>
    </React.Fragment>
  );
};

DatePickerField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  focused: PropTypes.bool.isRequired,
  handleFocusChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  name: PropTypes.string,
  addMoreDays: PropTypes.array,
};

const Enhanced = withWidth()(DatePickerField);
const EnhancedField = (props) => <BaseField component={Enhanced} {...props} />;

export default EnhancedField;
