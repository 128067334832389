import SimpleTextField from "./SimpleTextField";
import React from "react";
import { email } from "../validators";

const EmailField = ({ name, required, label, fullWidth, disabled }) => (
  <SimpleTextField
    name={name}
    required={required}
    label={label}
    validate={email}
    fullWidth={fullWidth}
    disabled={disabled}
  />
);

export default EmailField;