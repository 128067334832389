import React, { useState } from "react";
import ChipInput from "material-ui-chip-input";
import { useField } from "../index";

const ChipInputField = ({
  name,
  addingErrorMessage,
  required,
  label,
  fullWidth,
  onBeforeAdd,
  disabled
}) => {
  const field = useField(name);
  const [error, setError] = useState(null);

  return (
    <>
      <ChipInput
        value={field.input.value}
        onBeforeAdd={item => {
          if (onBeforeAdd) {
            const isValid = onBeforeAdd(item);

            if (!isValid) {
              setError(true);
            }
            return isValid;
          }

          return true;
        }}
        onDelete={(item, index) =>
          field.input.onChange(field.input.value.filter((_, i) => i !== index))
        }
        blurBehavior="add"
        onChange={value => field.input.onChange(value)}
        onUpdateInput={() => setError(false)}
        newChipKeyCodes={[32, 13, 9]}
        onBlur={() => setError(false)}
        error={(field.meta.touched && !field.meta.valid) || error}
        helperText={field.meta.error || (error && addingErrorMessage)}
        label={label}
        fullWidth={fullWidth}
        disabled={disabled}
        required={required}
      />
    </>
  );
};

export default ChipInputField;
