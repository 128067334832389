import React from "react";
import emptyListPlaceholder from "./no-data.svg";
import FlexiTypography from "../FlexiTypography/index.js";
import { Box } from "@material-ui/core";

const DefaultEmptyList = ({
  titleText = "Nincs találat",
  descriptionText,
  imagePath = emptyListPlaceholder
}) => (
  <div style={{ position: "relative" }}>
    <div
      style={{
        width: "100%",
        height: "300px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {imagePath && <img alt="no-data" src={imagePath} />}
      {titleText && (
        <FlexiTypography
          variant="h3"
          style={{
            margin: "43px 13px 0"
          }}
        >
          {titleText}
        </FlexiTypography>
      )}
      {descriptionText && (
        <FlexiTypography
          style={{
            margin: "13px 0",
            maxWidth: 740
          }}
          align={"center"}
        >
          {descriptionText}
        </FlexiTypography>
      )}
    </div>
  </div>
);

const EmptyList = ({ render, loading, ...rest }) => {
  return (
    !loading &&
    (render ? (
      <Box
        minHeight={"300px"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {render()}
      </Box>
    ) : (
      <DefaultEmptyList {...rest} />
    ))
  );
};

export default EmptyList;
