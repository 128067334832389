import { Fields } from "../index";
import React, { useMemo } from "react";

const Range = ({ type, name, label }) => {
  const config = useMemo(
    () => [
      [
        { fieldType: type, name: `${name}.from`, label: `${label} (-tól)` },
        { fieldType: type, name: `${name}.to`, label: `${label} (-ig)` }
      ]
    ],
    [type, name, label]
  );

  return <Fields config={config} />;
};

export default Range;
