import React, { useMemo } from "react";
import PageLayout from "../../components/PageLayout";
import { defineMessages } from "react-intl.macro";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import { Form } from "react-final-form";
import {
  useErrorOccurredNotification,
  useSaveSuccessNotification
} from "../../hooks/useNotificationState";
import { useMutator, useProfileData } from "../../hooks/useApi";
import { getFlexipayUrl } from "../../utils/urlHelpers";
import { makeExternalFormSubmit } from "../../utils/makeExternalFormSubmit";
import { Fields } from "../../FlexiComponents/FlexiForm";
import { Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PasswordChangeDialog from "../../components/PasswordChangeDialog";
import FlexiInfoBar from "../../FlexiComponents/FlexiInfoBar";

const formId = "profileDataForm";

const onSubmit = makeExternalFormSubmit(formId);

const messages = defineMessages({
  pageTitle: {
    id: "pageTitle.profileDataPage",
    defaultMessage: "Profil"
  }
});

const ProfileDataPage = () => {
  const [passwordDialogOpen, setPasswordDialogOpen] = React.useState(false);
  const [put, putLoading] = useMutator("PUT");
  const notifyError = useErrorOccurredNotification();
  const notifySuccessSave = useSaveSuccessNotification();
  const {
    mainCompany,
    companies,
    user,
    szamlazzHu,
    kulcsSoft
  } = useProfileData();
  const profileFields = useMemo(() => {
    return [
      [
        {
          fieldType: "text",
          name: `surname`,
          label: "Vezetéknév",
          required: true
        }
      ],
      [
        {
          fieldType: "text",
          name: `firstname`,
          label: "Keresztnév",
          required: true
        }
      ],
      [
        {
          fieldType: "text",
          name: `phoneNumber`,
          label: "Telefonszám",
          required: true
        }
      ],
      [
        {
          fieldType: "checkbox",
          name: `newsletter`,
          label: "Feliratkozom a hírlevélre"
        }
      ]
    ];
  }, []);
  const rightSideButtons = [
    {
      props: {
        onClick: setPasswordDialogOpen,
        children: "Jelszó módosítása",
        disabled: szamlazzHu || kulcsSoft
      },
      mobile: {
        placement: "fab",
        tooltip: "Jelszó módosítása",
        icon: <SaveIcon />,
        disabled: szamlazzHu || kulcsSoft
      }
    },
    {
      props: {
        onClick: onSubmit,
        children: "Mentés"
      },
      mobile: {
        placement: "fab",
        tooltip: "Mentés",
        icon: <SaveIcon />
      }
    }
  ];

  return (
    <PageLayout
      insidePage
      pay
      suppressTourButton
      rightSideComponents={rightSideButtons}
      titleMessage={messages.pageTitle}
      loading={putLoading}
    >
      <Grid container>
        {szamlazzHu && (
          <Grid item xs={12} style={{ marginBottom: "32px" }}>
            <FlexiInfoBar info>
              Számlázz.hu-s felhasználóként regisztráltál nálunk, így az ott
              használt jelszavad érvényes a Flexibill szolgáltatáshoz is!
            </FlexiInfoBar>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper
            style={{
              maxWidth: "526px",
              height: "280px",
              padding: "32px 40px 40px",
              marginBottom: "32px"
            }}
          >
            <Typography variant={"h5"} style={{ paddingBottom: "24px" }}>
              Személyes adatok
            </Typography>
            <Form
              initialValues={user}
              onSubmit={async values => {
                const copy = { ...values };
                delete copy.id;
                delete copy.email;
                delete copy.companies;
                const { data, error } = await put(
                  getFlexipayUrl(`/profile`),
                  copy
                );
                if (error) {
                  notifyError(error);
                }
                if (data) {
                  notifySuccessSave();
                }
              }}
              render={({ handleSubmit }) => {
                return (
                  <form id={formId} onSubmit={handleSubmit}>
                    <Grid item xs={12}>
                      <Fields config={profileFields} />
                    </Grid>
                  </form>
                );
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant={"body2"}
            style={{ paddingBottom: "8px", fontWeight: "bold" }}
          >
            Adat-, és fióktörlés
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"body2"} style={{ width: "420px" }}>
            Amennyiben törölni szeretné fiókját, kérjük, vegye fel a kapcsolatot
            velünk az alábbi e-mail címen: faktor@flexibill.hu
          </Typography>
        </Grid>
      </Grid>
      <PasswordChangeDialog
        open={passwordDialogOpen}
        onClose={() => setPasswordDialogOpen(false)}
      />
    </PageLayout>
  );
};

export default ProfileDataPage;
