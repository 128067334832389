import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Button from "@material-ui/core/Button";
import { useBigButtonStyles } from "./index";
import SimpleCard from "./SimpleCard";
import { ClaimsReviewItems } from "./ClaimsReview";
import billSadImg from "../../images/bill/3_bill_sad.svg";

const SupplierCustomerTitle = ({ supplier, title }) => {
  return (
    <Box
      marginTop={"26px"}
      display={"flex"}
      alignItems={"end"}
      marginBottom={"13px"}
    >
      {supplier ? (
        <LocalShippingIcon
          style={{
            color: "#9b9b9b",
            display: "inline-block",
            marginRight: "0.5rem"
          }}
        />
      ) : (
        <AccountCircleIcon
          style={{
            color: "#9b9b9b",
            display: "inline-block",
            marginRight: "0.5rem"
          }}
        />
      )}
      <Typography variant="h3">{title}</Typography>
    </Box>
  );
};

const DisbursementConditionItem = ({ supplier, title, conditions }) => {
  return (
    <Box marginBottom={"24px"}>
      <SupplierCustomerTitle title={title} supplier={supplier} />
      {conditions.map(condition => (
        <Box marginTop={2}>
          <Typography varian={"h6"} style={{ marginBottom: "5px" }}>
            {condition.title}
          </Typography>
          <Typography>
            <span dangerouslySetInnerHTML={{ __html: condition.description }} />
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const OtherComments = ({ supplier, title, comment }) => {
  return (
    <Box>
      <SupplierCustomerTitle supplier={supplier} title={title} />
      <Typography>{comment}</Typography>
    </Box>
  );
};

const ContinuationConditions = ({ agreementClaim }) => {
  return (
    <Paper>
      <Typography
        variant={"h3"}
        align={"center"}
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        Folyósítási feltételek&nbsp;
        <Tooltip
          title={
            "A folyósítási feltételek tisztázása kiemelten fontos első folyósítás előtt. NEM gátolja a szerződéskötés folyamatát, ellenben az első folyósítás nem jöhet létre, amíg a feltételek nem teljesülnek."
          }
        >
          <InfoIcon fontSize={"small"} />
        </Tooltip>
      </Typography>

      <DisbursementConditionItem
        title={agreementClaim.supplier.party.partyName}
        conditions={
          agreementClaim.supplier.riskEvaluation.disbursementConditions
        }
        supplier
      />

      {agreementClaim.customers
        .filter(
          customer =>
            customer.riskEvaluation &&
            !customer.riskEvaluation.declined &&
            customer.riskEvaluation.disbursementConditions.length
        )
        .map(customer => (
          <DisbursementConditionItem
            conditions={customer.riskEvaluation.disbursementConditions}
            title={customer.party.partyName}
          />
        ))}

      <Typography variant={"h3"} align={"center"}>
        Egyéb megjegyzés
      </Typography>

      {agreementClaim.supplier.riskEvaluation.comment && (
        <OtherComments
          supplier
          title={agreementClaim.supplier.party.partyName}
          comment={agreementClaim.supplier.riskEvaluation.comment}
        />
      )}

      {agreementClaim.customers
        .filter(customer => customer.riskEvaluation?.comment)
        .map(customer => (
          <OtherComments
            comment={customer.riskEvaluation.comment}
            title={customer.party.partyName}
          />
        ))}
    </Paper>
  );
};

const Results = ({ agreementClaim, finalAccept, recall }) => {
  const buttonClasses = useBigButtonStyles();

  const [recallSuccessOpen, setRecallSuccessOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);

  useEffect(() => {
    if (agreementClaim?.status === "CONTRACT_FIXING") {
      setSuccessOpen(true);
    }
  }, [agreementClaim]);

  return (
    <Box>
      {agreementClaim.declined && (
        <div
          style={{
            marginLeft: "118px",
            marginRight: "118px",
            marginTop: "80px"
          }}
        >
          <SimpleCard
            title={`Sajnálattal értesítünk, hogy az ügylet nem került jóváhagyásra. "${agreementClaim.customers
              .map(item => item.party.partyName)
              .join(
                ", "
              )}" vevő(id)del kapcsolatban nem fogunk tudni szerződni és faktorálást indítani.`}
            description={
              "Köszönjük, a bizalmadat! Bízunk benne, hogy egy másik partner kapcsán pozitív elbírálásról tudunk értesíteni."
            }
            img={billSadImg}
          />
        </div>
      )}
    </Box>
  );
};

export default Results;
