import { BrowserRouter, Route, Switch } from "react-router-dom";
import React, { useEffect, useState } from "react";
import InnerApplication from "./InnerApplication";
import OuterApplication from "./OuterApplication";
import useNotificationState from "../../hooks/useNotificationState";
import FlexiSnackbar from "../../components/Snackbar";
import { SWRConfig } from "swr";
import { fetcher } from "../../utils/fetcher";
import { resetAuthData } from "../../utils/authentication";
import ConfirmDialog from "./ConfirmDialog";
import { FlexiFormContext } from "../../FlexiComponents/FlexiForm";
import AddressFields from "../../components/FormFields/AddressFields";
import MultipleFileField from "../../components/FormFields/MultipleFileField";
import AgreementSelectField from "../../components/FormFields/AgreementSelectField";
import { getStoredToken } from "../../utils/authentication";
import FlexiConfirmProvider from "../../FlexiComponents/FlexiConfirm";
import WidgetsApplication from "./WidgetsApplication";
import FlexiConfirmDialogProvider from "../../FlexiComponents/FlexiConfirm";
import ExternalSalesApplication from "./ExternalSales/ExternalSalesApplication";

const notAuthenticatedErrorMessages = [
  "JWT Token not found",
  "Expired JWT Token",
  "Invalid JWT Token",
  // "Cannot access FlexiPay with this company.",
  "Company not found"
];

const AppNotification = () => {
  const [notification, notificationActions] = useNotificationState();

  return (
    <FlexiSnackbar
      variant={notification.variant}
      text={notification.text}
      open={notification.open}
      onClose={() => notificationActions.hideNotification()}
    />
  );
};

const Application = () => {
  const [showPage, setShowPage] = useState(false);
  const [token, setToken] = useState(getStoredToken());

  useEffect(() => {
    setTimeout(() => setShowPage(true), 1000);
  });

  useEffect(() => {
    const setAndCheckToken = () => {
      setTimeout(() => {
        const tokenFromLocalStorage = getStoredToken();
        if (!tokenFromLocalStorage) {
          setAndCheckToken();
        } else {
          setToken(tokenFromLocalStorage);
        }
      }, 1000);
    };

    setAndCheckToken();
  }, []);

  const checkUnauthenticatedError = message => {
    if (notAuthenticatedErrorMessages.indexOf(message) !== -1) {
      resetAuthData();
      window.location.href = "/login";
    }
  };

  return (
    <React.Fragment>
      <FlexiConfirmDialogProvider>
        <FlexiConfirmProvider>
          <FlexiFormContext.Provider
            value={{
              token,
              fieldTypes: {
                addressFields: AddressFields,
                agreementSelect: AgreementSelectField,
                multipleFile: MultipleFileField
              }
            }}
          >
            <SWRConfig
              value={{
                shouldRetryOnError: false,
                revalidateOnFocus: false,
                fetcher: fetcher,
                onError: err => checkUnauthenticatedError(err.message)
              }}
            >
              <AppNotification />
              <ConfirmDialog />
              <BrowserRouter>
                <div
                  style={{
                    display: showPage ? "block" : "none",
                    backgroundColor: "#fff"
                  }}
                >
                  <Switch>
                    <Route path={"/app"} component={InnerApplication} />
                    <Route
                      path={"/external-sales"}
                      component={ExternalSalesApplication}
                    />
                    <Route path={"/widgets"} component={WidgetsApplication} />
                    <Route component={OuterApplication} />
                  </Switch>
                </div>
              </BrowserRouter>
            </SWRConfig>
          </FlexiFormContext.Provider>
        </FlexiConfirmProvider>
      </FlexiConfirmDialogProvider>
    </React.Fragment>
  );
};

export default Application;
