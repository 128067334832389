/**
 * @render react
 * @name FlexiNavBar
 * @description FlexiNavBar
 * @example
 * <BrowserRouter>
 <FlexiNavbar
 onNavListItemClick={() => alert('yay')}
 menuItems={[
			{
				text: "Kiscigaretta BT.",
				path: "/a",
				icon: "asdfdsaf.png",
				exact: false,
				subMenuItems: [
					{
						text: "Kiscigaretta BT.",
						path: "/",
						icon: "asdfdsaf.png",
						exact: false
					}
				]
			},
			{
				text: "Kiscigaretta Kft.",
				path: "/b",
				icon: "asdfdsaf.png",
				exact: false,
				subMenuItems: [
				{
						text: "Kiscigaretta BT.",
						path: "/",
						icon: "asdfdsaf.png",
						exact: false
					}
				]
			},
			{
				text: "Kiscigaretta Zrt.",
				path: "/c",
				icon: "asdfdsaf.png",
				exact: false,
				subMenuItems: [
					{
						text: "Kiscigaretta BT.",
						path: "/",
						icon: "asdfdsaf.png",
						exact: false
					}
				]
			}
		]}/>
 </BrowserRouter>
 */

/* eslint-disable indent */

import React, { useContext } from "react";
import { compose, withProps, withState, lifecycle } from "recompose";
import { withRouter } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import List from "@material-ui/core/List";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import NavListItem from "./NavListItem";
import PropTypes from "prop-types";
import LayoutContext from "./LayoutContext";
import FlexiSalesContact from "./SalesContract";
import { withStyles } from "@material-ui/core/styles";

const AnimatedList = withStyles({
  root: {
    "&.side-menu": { opacity: 1 },
    "&.side-menu-enter": { opacity: 0 },
    "&.side-menu-enter-active": {
      opacity: 1,
      transition: "opacity 0.3s ease-in-out"
    },
    "&.side-menu-exit": { opacity: 0 },
    "&.side-menu-exit-active": {
      opacity: 1,
      transition: "opacity 0.3s ease-in-out"
    }
  }
})(List);

const Navbar = ({
  onNavListItemClick,
  menuItems,
  subMenuItems,
  setSubMenuItems,
  sidebarbgcolor,
  onClose,
  salesProps
}) => {
  const layoutContext = useContext(LayoutContext);

  return (
    <React.Fragment>
      <CSSTransition
        in={Boolean(subMenuItems.length)}
        timeout={300}
        classNames="side-menu"
      >
        {() => (
          <AnimatedList
            disablePadding
            style={{ minHeight: "100vh" }}
            className="side-menu"
          >
            {(subMenuItems &&
              subMenuItems.length && (
                <NavListItem
                  first={subMenuItems[0]}
                  onClick={() => {
                    setSubMenuItems([]);
                  }}
                  key="navbar-submenu-back"
                  disableHref
                  text="Vissza"
                  icon={<ChevronLeft />}
                  sidebarbgcolor={sidebarbgcolor}
                />
              )) ||
              null}
            {(subMenuItems && subMenuItems.length
              ? subMenuItems
              : menuItems
            ).map(
              ({
                component,
                menuItem,
                subMenuItems: items,
                render,
                ...item
              }) => {
                let badgeContent;

                if (
                  layoutContext &&
                  layoutContext.menuItemBadges &&
                  layoutContext.menuItemBadges.length
                ) {
                  const badgeConfig = layoutContext.menuItemBadges.find(
                    badge => badge.path === item.path
                  );

                  if (badgeConfig) {
                    badgeContent = badgeConfig.count;
                  }
                }

                return (
                  <NavListItem
                    {...item}
                    badgeContent={badgeContent}
                    badgeColor={"red"}
                    subMenuItems={items}
                    onMouseEnter={() =>
                      component && component.preload && component.preload()
                    }
                    onClick={() => {
                      if (items && items.length) {
                        setSubMenuItems(items);
                      } else {
                        onNavListItemClick();
                        if (onClose) {
                          onClose();
                        }
                      }
                    }}
                    key={item.path}
                    id={`drawer-menu-item-${item.path}`}
                    disableHref={
                      Boolean(items) &&
                      Boolean(items.length) &&
                      !item.navigateOnClick
                    }
                    sidebarbgcolor={sidebarbgcolor}
                  />
                );
              }
            )}
            <div
              style={{ position: "fixed", left: 0, bottom: 0, width: "200px" }}
            >
              {/*{sponsorProps && <FlexiSponsor {...sponsorProps} />}*/}
              {salesProps && <FlexiSalesContact {...salesProps} />}
            </div>
          </AnimatedList>
        )}
      </CSSTransition>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  onNavListItemClick: PropTypes.func,
  menuItems: PropTypes.array.isRequired,
  subMenuItems: PropTypes.array.isRequired,
  setSubMenuItems: PropTypes.func.isRequired,
  salesProps: PropTypes.object
};

NavListItem.propTypes = {
  disableHref: PropTypes.bool
};

const enhance = compose(
  withState("subMenuItems", "setSubMenuItems", []),
  withProps(props => ({
    menuItems: props.menuItems
  })),
  withRouter,
  lifecycle({
    componentDidMount() {
      const { match, setSubMenuItems, menuItems } = this.props;
      const selectedMenuItem = menuItems.find(menuItem => {
        if (menuItem.exact) {
          return match.path === menuItem.path;
        }

        return match.path.indexOf(menuItem.path) !== -1;
      });

      if (
        selectedMenuItem &&
        selectedMenuItem.subMenuItems &&
        selectedMenuItem.subMenuItems.length
      ) {
        setSubMenuItems(selectedMenuItem.subMenuItems);
      }
    }
  })
);

export default enhance(Navbar);
