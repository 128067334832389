import React, { useState } from "react";
import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Field, Form } from "react-final-form";
import { email, required, composeValidators } from "../../utils/validators";
import { TextField, Checkbox } from "final-form-material-ui";
import { FormControlLabel } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import {
  ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE,
  ROLE_FLEXIPAY_INVOICE_MANAGER,
  ROLE_FLEXIPAY_AGREEMENT_UPLOADER
} from "../../roles";

const getRoleInitValues = selectedCoworker => {
  const roles = selectedCoworker.roles;
  const resultRoles = {};

  roles.forEach(role => {
    resultRoles[role] = roles.includes(role);
  });

  return {
    email: selectedCoworker.userEmail,
    roles: { ...resultRoles }
  };
};

const CoworkerInviteDialog = ({
  selectedCoworker,
  open,
  onClose,
  onSubmit
}) => {
  const [submitDisabled, setSubmitDisabled] = useState(true);

  return (
    <FlexiDialog
      open={open}
      onClose={onClose}
      title={
        !Boolean(selectedCoworker)
          ? "Munkatárs meghívása"
          : "Jogok szerkesztése"
      }
      dialogActions={
        <Button
          disabled={submitDisabled}
          style={{ margin: "auto" }}
          onClick={() => {
            document
              .getElementById("coworkerInviteForm")
              .dispatchEvent(new Event("submit", { cancelable: true }));
          }}
        >
          Küldés
        </Button>
      }
    >
      <Form
        onSubmit={values => {
          if (values.roles) {
            let keys = Object.keys(values.roles);

            let filtered = keys.filter(function(key) {
              return values.roles[key];
            });

            onSubmit({ ...values, roles: filtered });
          } else {
            onSubmit(values);
          }
        }}
        initialValues={selectedCoworker && getRoleInitValues(selectedCoworker)}
        render={({ handleSubmit, values }) => {
          setSubmitDisabled(
            !Boolean(
              Object.keys(values?.roles || {}).length &&
                Object.values(values?.roles || {}).some(Boolean)
            )
          );

          return (
            <form onSubmit={handleSubmit} id={"coworkerInviteForm"}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography>
                    Add meg a meghívandó személy e-mail címét!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={"email"}
                    label={"E-mail cím"}
                    component={TextField}
                    validate={composeValidators(email, required)}
                    fullWidth
                    disabled={Boolean(selectedCoworker)}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 16 }}>
                  <FormLabel component="legend" style={{ marginBottom: 8 }}>
                    Jogosultság:
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Field
                        name={`roles.${ROLE_FLEXIPAY_AGREEMENT_UPLOADER}`}
                        checked={selectedCoworker?.roles.includes(
                          ROLE_FLEXIPAY_AGREEMENT_UPLOADER
                        )}
                        type={"checkbox"}
                        component={Checkbox}
                      />
                    }
                    label={"Dokumentum feltöltő"}
                  />
                  <Typography
                    variant={"body2"}
                    style={{
                      width: "60%",
                      opacity: "0.5",
                      paddingLeft: "37px",
                      marginBottom: "8px",
                      marginTop: "-8px"
                    }}
                  >
                    Megtekintheti az adatokat és feltöltheti a szerződéskötéshez
                    szükséges dokumentumokat
                  </Typography>

                  <FormControlLabel
                    control={
                      <Field
                        name={`roles.${ROLE_FLEXIPAY_INVOICE_MANAGER}`}
                        checked={selectedCoworker?.roles.includes(
                          ROLE_FLEXIPAY_INVOICE_MANAGER
                        )}
                        type={"checkbox"}
                        component={Checkbox}
                      />
                    }
                    label={"Számlakezelő"}
                  />
                  <Typography
                    variant={"body2"}
                    style={{
                      width: "60%",
                      opacity: "0.5",
                      paddingLeft: "37px",
                      marginBottom: "8px",
                      marginTop: "-8px"
                    }}
                  >
                    Megtekintheti, rögzítheti és faktorálásra előkészítheti a
                    számlákat
                  </Typography>

                  <FormControlLabel
                    control={
                      <Field
                        name={`roles.${ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE}`}
                        checked={selectedCoworker?.roles.includes(
                          ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE
                        )}
                        type={"checkbox"}
                        component={Checkbox}
                      />
                    }
                    label={"Engedményezés végrehajtó"}
                  />
                  <Typography
                    variant={"body2"}
                    style={{
                      width: "60%",
                      opacity: "0.5",
                      paddingLeft: "37px",
                      marginBottom: "8px",
                      marginTop: "-8px"
                    }}
                  >
                    Benyújthatja a számlákat faktorálásra és aláírhatja a
                    dokumentumokat
                  </Typography>
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
    </FlexiDialog>
  );
};
export default CoworkerInviteDialog;
