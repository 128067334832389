import TablePagination from "@material-ui/core/TablePagination";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import Mark from "mark.js";
import styled from "styled-components";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { breakpoint } from "../utils/breakpoints";
import EmptyList from "./EmptyList";
import SearchBarFilterMenu from "./SearchBarFilterMenu";
import makeFullWidthCellRenderer from "./cellRenderers/makeFullWidthCellRenderer";
import SearchBar from "./SearchBar";
import makeKebabMenuRenderer from "./cellRenderers/makeKebabMenuRenderer";

const Wrapper = styled.div`
  background-color: transparent !important;
  font-family: "Nunito-Regular", Helvetica, Arial, sans-serif !important;

  .ag-icon-checkbox-checked {
    color: #0045de !important;
  }

  .ag-header-cell-resize {
    color: white;
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(to top, #f2f2f2, rgba(0, 0, 0, 0)) 1 100%;
    right: -15px !important;
    &:hover {
      border-width: 6px;
      border-image: linear-gradient(to top, #bcbcbc, #bcbcbc) 1 100%;
    }
  }

  .multi-line {
    .multi-line-helper {
      display: block;
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      p {
        width: 100%;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-height: 1.1;
      }
    }
  }

  .ag-header {
    background-color: transparent !important;
    div span:not(.ag-icon) {
      font-family: "Nunito-SemiBold" !important;
      font-size: 10px !important;
      line-height: 14px;
      text-transform: uppercase !important;
    }

    .ag-icon {
      position: relative;
      left: 1px;
    }
  }

  .text-left {
    text-align: left;
    .ag-cell-value {
      width: 100%;
    }
  }
  .text-right {
    text-align: right;
    .ag-cell-value {
      width: 100%;
    }
  }
  .text-center {
    text-align: center;
    .ag-cell-value {
      width: 100%;
    }
  }

  .ag-full-width-row {
    border-width: 0 !important;
  }

  .align-header-left {
    .ag-header-cell-label {
      .ag-header-cell-text {
        width: 100%;
        text-align: left !important;
      }
    }
  }

  .align-header-right {
    .ag-header-cell-label {
      .ag-header-cell-text {
        width: 100%;
        text-align: right !important;
      }
    }
  }
  .align-header-center {
    .ag-header-cell-label {
      .ag-header-cell-text {
        width: 100%;
        text-align: center !important;
      }
    }
  }

  .ag-cell {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .ag-header-cell,
  .ag-header-cell-sortable {
    padding-left: 8px !important;
    padding-right: 8px !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }

  .ag-react-container {
    text-overflow: ellipsis;
  }

  .generate-in-progress {
    position: absolute !important;
    right: -1px;
    top: 8px;
  }

  .long-text-padding {
    .ag-react-container {
      padding: 0 20px 0 0;
    }
  }

  ${breakpoint("xs", "lg")`
    .ag-header {
      display: none !important;
    }

    .ag-theme-material .ag-row-selected {
      background-color: transparent !important;
    }

    .ag-row {
      height: auto !important;
      top: auto !important;
      position: relative !important;
      margin-bottom: ${props => props.mobileBottomMargin};
      padding-bottom: 5px;
    }

    .ag-full-width-container {
      height: auto !important;
      position: relative !important;
    }

    .ag-center-cols-clipper {
      display: none !important;
    }

    .generate-in-progress {
      position: relative !important;
      right: initial;
      top: initial;
    }
  `};
`;

const TablePaginatior = styled(TablePagination)`
  font-size: 12px;
  ${breakpoint("xs", "md")`
      && * {
        margin: 0;
      }
      & > div {
        flex-direction: column;
        padding: 0;
      }
      button:nth-child(1) {
        margin-right: 15px;
      }
      button:nth-child(2) {
      margin-left: 15px;}
  `};
`;

let api = null;
let markInstance = null;

const markFilter = text => {
  markInstance = new Mark(document.querySelector(".ag-theme-material > div"));
  markInstance.mark(text, {
    className: "list-search-mark",
    exclude: [
      ".ag-header-cell .ag-header-cell-text",
      ".list-header",
      ".grid-pager"
    ]
  });
};

const FlexiListRemote = ({
  width,
  items = [],
  columnDefs,
  rowHeight = 50,
  onGridReady = () => {},
  getGridOptions,
  totalCount = items.length,
  onPageChange,
  mobileCellSpacing = 2,
  mobileBottomMargin = ".5rem",
  loading,
  hidePager,
  hideSearchBar,
  rowsPerPageOptions,
  EmptyListProps,
  currentPagination = {}
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(
    rowsPerPageOptions ? rowsPerPageOptions[0] : 10
  );

  useEffect(() => {
    if (currentPagination.rowsPerPage) {
      setRowsPerPage(currentPagination.rowsPerPage);
    }
  }, [currentPagination.rowsPerPage]);

  const syncSortModel = () => {
    if (api) {
      const sortModel = api.getSortModel()[0];

      if (!sortModel && currentPagination.sortModel) {
        api.setSortModel([currentPagination.sortModel]);
      }

      if (sortModel && currentPagination.sortModel) {
        if (
          sortModel.colId !== currentPagination.sortModel.colId &&
          sortModel.sort !== currentPagination.sortModel.sort
        ) {
          api.setSortModel([currentPagination.sortModel]);
        }
      }
    }
  };

  useEffect(() => {
    syncSortModel();
  }, [currentPagination]);

  const [searchText, setSearchText] = useState(currentPagination.searchText);

  if (markInstance) {
    markInstance.unmark();
  }

  if (currentPagination.searchText && currentPagination.searchText.length) {
    setTimeout(() => markFilter(currentPagination.searchText));
  }

  const gridDefaultOptions = {
    // Ez a next oldotta meg a link renderelest router nelkul hmmm..
    reactNext: true,
    autoHeight: true,
    pagination: true,
    animateRows: false,
    suppressCellSelection: true,
    suppressPaginationPanel: true,
    suppressMovableColumns: true,
    columnDefs: columnDefs.map(item => {
      if (item.menu) {
        return {
          cellRendererFramework: makeKebabMenuRenderer(item.getMenuItems),
          headerName: "",
          width: 100,
          ...item
        };
      }

      return item;
    }),
    rowData: items,
    domLayout: "autoHeight",
    // quickFilterText,
    paginationPageSize: rowsPerPage,
    onGridReady: params => {
      // eslint-disable-next-line prefer-destructuring
      api = params.api;
      params.api.resetRowHeights();
      onGridReady(params);
      syncSortModel();
    },
    gridOptions: {
      onSortChanged: (function(currentPagination) {
        return () => {
          const sortModel = api.getSortModel();

          if (sortModel && sortModel.length) {
            onListPaginationFilterSearchChange(
              currentPagination.page,
              rowsPerPage,
              sortModel[0],
              currentPagination.searchText
            );
          }
        };
      })(currentPagination)
    },
    getRowHeight: () => (isWidthDown("md", width) ? 0 : rowHeight)
  };

  const gridOptions = getGridOptions
    ? getGridOptions(gridDefaultOptions)
    : gridDefaultOptions;

  // const handlePageChange = (page, count, currentSort) => {
  //   onPageChange(page + 1, count, currentSort, quickFilterText);
  // };

  const onListPaginationFilterSearchChange = (
    page,
    rpp,
    currentSort,
    searchText
  ) => {
    setRowsPerPage(rpp);
    setSearchText(searchText);
    onPageChange(page, rpp, currentSort, searchText);
  };

  const agGridElement = !isWidthDown("md", width) ? (
    <AgGridReact {...gridOptions} />
  ) : (
    <AgGridReact
      key="ag-grid-invoice-mobile"
      isFullWidthCell={() => true}
      frameworkComponents={{
        fullWidthCellRenderer: makeFullWidthCellRenderer({
          spacing: mobileCellSpacing
        })
      }}
      fullWidthCellRenderer="fullWidthCellRenderer"
      {...gridOptions}
    />
  );

  const showSearchBar = Boolean(
    (!items.length && searchText && searchText.length) || items.length
  );

  return (
    <React.Fragment>
      {!hideSearchBar && showSearchBar && (
        <SearchBar
          searchChanged={value => {
            onListPaginationFilterSearchChange(
              currentPagination.page,
              rowsPerPage,
              currentPagination.sortModel,
              value
            );
          }}
          searchText={searchText}
          onReset={() =>
            onListPaginationFilterSearchChange(
              currentPagination.page,

              rowsPerPage,
              currentPagination.sortModel,

              ""
            )
          }
          sortModel={currentPagination.sortModel}
          filter={
            <SearchBarFilterMenu
              sortableColumns={columnDefs.filter(
                col => col.field && col.sortable
              )}
              onShortChange={shortModel => {
                api.setSortModel([shortModel]);
              }}
              sort={currentPagination.sortModel}
            />
          }
        />
      )}
      <Wrapper
        className="ag-theme-material"
        mobileBottomMargin={mobileBottomMargin}
      >
        {!items.length ? (
          <EmptyList loading={loading} {...EmptyListProps} />
        ) : (
          agGridElement
        )}
        {!hidePager && totalCount && (
          <TablePaginatior
            component="div"
            className={"grid-pager"}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={currentPagination.page - 1}
            labelRowsPerPage="Sorok száma: "
            labelDisplayedRows={({ from, to, count }) =>
              `${from}.-től ${to}.-ig, összesen: ${count}`
            }
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={(e, page) => {
              if (api) {
                // api.paginationGoToPage(page + 1);
                onListPaginationFilterSearchChange(
                  page + 1,
                  rowsPerPage,
                  currentPagination.sortModel,
                  currentPagination.searchText
                );
              }
            }}
            onChangeRowsPerPage={e => {
              if (api) {
                api.paginationSetPageSize(parseInt(e.target.value, 10));
              }
              onListPaginationFilterSearchChange(
                1,
                parseInt(e.target.value, 10),
                currentPagination.sortModel,
                currentPagination.searchText
              );
            }}
            rowsPerPageOptions={
              rowsPerPageOptions ? rowsPerPageOptions : [10, 50, 100]
            }
          />
        )}
      </Wrapper>
    </React.Fragment>
  );
};

export default withWidth()(FlexiListRemote);

FlexiListRemote.propTypes = {
  EmptyListProps: PropTypes.object,
  columnDefs: PropTypes.any,
  currentPage: PropTypes.number,
  getGridOptions: PropTypes.func,
  getInvoiceStatusIndicatorTooltipText: PropTypes.func,
  hidePager: PropTypes.bool,
  hideSearchBar: PropTypes.bool,
  intl: PropTypes.object,
  invoices: PropTypes.array,
  items: PropTypes.array,
  loading: PropTypes.any,
  match: PropTypes.object,
  mobileBottomMargin: PropTypes.string,
  mobileCellSpacing: PropTypes.number,
  mobileRowHeight: PropTypes.number,
  onGridReady: PropTypes.func,
  onNavStatusClick: PropTypes.func,
  onPageChange: PropTypes.func,
  quickFilterText: PropTypes.string,
  rowHeight: PropTypes.number,
  rowsPerPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  setQuickFilterText: PropTypes.func,
  setRowsPerPage: PropTypes.func,
  setSort: PropTypes.func,
  sort: PropTypes.object,
  totalCount: PropTypes.number,
  width: PropTypes.string
};
