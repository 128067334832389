import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 14,
    paddingRight: 20,
    paddingLeft: 20
  },
  active: {
    "& $line": {
      backgroundColor: "#0045de"
    }
  },
  completed: {
    "& $line": {
      backgroundColor: "#0045de"
    }
  },
  line: {
    height: 1,
    border: 0,
    backgroundColor: "#9b9b9b",
    borderRadius: 1
  }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#d8d8d8",
    zIndex: 1,
    color: "#fff",
    width: 30,
    height: 30,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      fontSize: "1.1rem"
    }
  },
  active: {
    backgroundColor: "#0045de",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
  },
  completed: {
    backgroundColor: "#0045de"
  }
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {props.icon}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  transparentBg: {
    backgroundColor: "transparent"
  },
  label: {
    fontSize: "11px",
    "& span:not(.MuiStepLabel-active)": {
      fontWeight: 400,
      fontSize: "11px",
    }
  }
}));

const FlexiIconStepper = ({ items, value, width }) => {
  const classes = useStyles();

  const activeStep = items.findIndex(step => step.status === value);

  return (
    <div>
      <Stepper
        classes={{ root: classes.transparentBg }}
        alternativeLabel={isWidthDown("sm", width) ? false : true}
        activeStep={activeStep}
        // connector={<ColorlibConnector />}
        orientation={isWidthDown("sm", width) ? "vertical" : "horizontal"}
      >
        {items.map(step => (
          <Step key={step.label}>
            <StepLabel
              classes={{ root: classes.label }}
              StepIconComponent={ColorlibStepIcon}
              icon={step.icon}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default withWidth()(FlexiIconStepper);
