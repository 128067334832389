import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import Countdown from "react-countdown";

const SpecialOfferBar = ({ date }) => {
  const [offerDialogOpen, setOfferDialogOpen] = useState(false);
  return (
    <Countdown
      daysInHours={true}
      zeroPadTime={3}
      date={date + 100000000000000}
      renderer={({ hours, minutes, seconds, completed }) => {
        return (
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-around"}
            style={{
              padding: "0px, 50px",
              backgroundColor: !completed ? "#FF1267" : "grey",
              height: "48px"
            }}
          >
            <Grid item style={{ display: "inline-flex" }}>
              <Typography variant={"h6"} style={{ color: "#fff" }}>
                50% KEDVEZMÉNY
              </Typography>
            </Grid>
            <Grid item style={{ display: "inline-flex" }}>
              <Typography variant={"body1"} style={{ color: "#fff" }}>
                A SZERZŐDÉSKÖTÉSI DÍJBÓL, HA FELTÖLTÖD A DOKUMENTUMOKAT!
              </Typography>
              <Typography
                onClick={() => setOfferDialogOpen(true)}
                variant={"caption"}
                style={{
                  color: "#fff",
                  textDecoration: "underline",
                  paddingLeft: "2px",
                  cursor: "pointer"
                }}
              >
                TOVÁBBI INFORMÁCIÓ
              </Typography>
            </Grid>
            <Grid item style={{ display: "inline-flex" }}>
              <Typography
                variant={"body1"}
                style={{
                  color: "#fff",
                  paddingTop: "3px",
                  paddingRight: "2px"
                }}
              >
                {completed
                  ? "A KEDVEZMÉNY VÉGET ÉRT."
                  : "A KEDVEZMÉNY EKKOR ÉR VÉGET:"}
              </Typography>
              <Typography
                style={{
                  color: "#fff",
                  border: "1px solid #fff",
                  padding: "3px"
                }}
              >
                {hours}:{minutes}:{seconds}
              </Typography>
            </Grid>
            <FlexiDialog
              open={offerDialogOpen}
              onClose={() => setOfferDialogOpen(false)}
            >
              Kedvezmény leírása blablabla
            </FlexiDialog>
          </Grid>
        );
      }}
    />
  );
};

export default SpecialOfferBar;
