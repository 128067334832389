import Grid from "@material-ui/core/Grid";
import React, { useMemo, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Fields } from "../../FlexiComponents/FlexiForm";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useCountries } from "../../hooks/useApi";
import withCheckboxErrorMessage from "../../utils/withCheckboxErrorMessage";
import withStyles from "@material-ui/core/styles/withStyles";
import { Checkbox } from "@material-ui/core";

const blueCheckBoxStyles = {
  root: {
    "&$checked": {
      color: "#0045de"
    },
    span: {
      fontSize: "12px"
    }
  },
  checked: {}
};

const BlueCheckbox = withCheckboxErrorMessage(
  withStyles(blueCheckBoxStyles)(Checkbox)
);

const AddressFields = ({ name, easyStructure }) => {
  const { data: countries } = useCountries();
  const fieldConfigs = useMemo(() => {
    const getFields = prefix => [
      [
        {
          fieldType: "select",
          name: `${prefix}.country`,
          label: "Ország",
          required: true,
          options: countries
        }
      ],
      [
        {
          fieldType: "text",
          name: `${prefix}.postalZone`,
          label: "Irányítószám",
          required: true
        },
        {
          fieldType: "text",
          name: `${prefix}.cityName`,
          label: "Város",
          required: true
        }
      ],
      [
        {
          fieldType: "text",
          name: `${prefix}.addressLine`,
          label: "Utca, házszám",
          required: true
        }
      ]
    ];

    const regName = easyStructure
      ? "registrationAddress"
      : "partyLegalEntity.registrationAddress";

    return {
      registrationFields: getFields(name ? `${name}.${regName}` : regName),
      postalFields: getFields(name ? `${name}.postalAddress` : "postalAddress")
    };
  }, [countries?.length]);
  const [showPostalAddress, setShowPostalAddress] = useState(false);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={"h3"}>Székhely cím</Typography>
      </Grid>
      <Grid item xs={12}>
        <Fields config={fieldConfigs.registrationFields} />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <BlueCheckbox
              checked={showPostalAddress}
              onChange={e => setShowPostalAddress(e.target.checked)}
            />
          }
          label="Eltérő postai cím megadása"
        />
      </Grid>
      {showPostalAddress && (
        <React.Fragment>
          <Grid item xs={12}>
            <Typography variant={"h3"}>Postai cím</Typography>
          </Grid>
          <Grid item xs={12}>
            <Fields config={fieldConfigs.postalFields} />
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

export default AddressFields;
