import Form, { Fields, Field } from "../../FlexiComponents/FlexiForm";
import { TextField } from "final-form-material-ui";
import React, { useEffect } from "react";
import { Paper, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { maxNum, required } from "../../utils/validators";
import { Prompt } from "react-router-dom";
import AgreementSelectField from "../FormFields/AgreementSelectField";
import { useErrorOccurredNotification } from "../../hooks/useNotificationState";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import Box from "@material-ui/core/Box";
import FlexiInfoBar from "../../FlexiComponents/FlexiInfoBar";
import InvoiceAttachmentUploadNewUpload from "../InvoiceAttachmentUpload/NewUpload";
import { useConfirmation } from "../../FlexiComponents/FlexiConfirm";
import moment from "moment";
import useHasPermission from "../RoleProvider/useHasPermission";
import { ROLE_FLEXIPAY_CUSTOMER_PLUS } from "../../roles";

const InvoiceForm = props => {
  const [attachedFiles, setAttachedFiles] = React.useState([]);
  const notifyError = useErrorOccurredNotification();
  const hasPermission = useHasPermission();
  const isScf = hasPermission(ROLE_FLEXIPAY_CUSTOMER_PLUS);

  useEffect(() => {
    if (props.initialValues) {
      const extendedWithName = props.initialValues.documents.map(file => ({
        type: file.type,
        file: { name: file.originalName, ...file }
      }));
      setAttachedFiles(extendedWithName);
    }
  }, [props.initialValues]);

  const confirm = useConfirmation();

  return (
    <>
      {props.initialValues?.status === "PROGRESSING" && (
        <Box marginBottom={3}>
          <FlexiInfoBar info>
            Az adatok módosítása után a számla bekerül a Faktorálható számlák
            listába. Onnan tudod újra elindítani a faktorálás folyamatát!
          </FlexiInfoBar>
        </Box>
      )}
      <Form
        FinalFormFormProps={{
          initialValues: props.initialValues,
          mutators: {
            removeDocument: ([id], state, { changeValue }) => {
              changeValue(state, "documents", attachments =>
                attachments.filter(file => file.id !== id)
              );
            }
          },
          onSubmit: data => {
            if (moment(moment()).diff(data.issueDate, "month") >= 1) {
              confirm({
                fn: () =>
                  props.onSubmit({
                    ...data,
                    modifiedDueDate: data.dueDate,
                    files: attachedFiles
                  }),
                title: "A kiállítás dátuma egy hónapnál régebbi.",
                description: "Biztos ezekkel az adatokkal akarod rögzíteni?",
                yesButtonText: "Igen",
                noButtonText: "Mégse"
              });
            } else {
              props.onSubmit({
                ...data,
                modifiedDueDate: data.dueDate,
                files: attachedFiles
              });
            }
          },
          validate: values => {
            const errors = {};
            const error = maxNum(values.totalGrossAmount)(
              values.initialCompensation
            );

            if (error) {
              errors.initialCompensation = error;
            }
            return errors;
          }
        }}
        render={({
          handleSubmit,
          form,
          values,
          submitSucceeded,
          errors,
          touched
        }) => {
          let partnerCurrency = null;
          const selectedPartner = props.partnerOptions.find(
            partner => "" + partner.value === "" + values.agreementId
          );

          if (selectedPartner) {
            partnerCurrency = selectedPartner.currency;
          }

          return (
            <form onSubmit={handleSubmit} id={"invoiceForm"}>
              <Prompt
                when={!submitSucceeded}
                message={
                  "Biztosan el hagyod az oldalt? A nem mentett adataid el fognak veszni."
                }
              />
              <Grid container spacing={3}>
                <Grid item xs={12} md={8} className="invoice-datas">
                  <Paper square style={{ padding: "32px 40px 40px" }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant={"h5"}
                          style={{ marginBottom: "2rem" }}
                        >
                          Számla adatai
                        </Typography>
                      </Grid>
                      <Grid container direction={"row"} spacing={3}>
                        <Grid item xs={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                              <Field
                                name={"invoiceId"}
                                component={TextField}
                                validate={required}
                                label={"Számla sorszáma"}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <AgreementSelectField />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "3rem" }}>
                          <Fields
                            config={[
                              [
                                {
                                  fieldType: "datePicker",
                                  muiPicker: true,
                                  name: "issueDate",
                                  label: "Keltezés",
                                  required: true
                                },
                                {
                                  fieldType: "datePicker",
                                  muiPicker: true,
                                  name: "taxPointDate",
                                  label: "Teljesítés dátuma",
                                  required: true
                                },
                                {
                                  fieldType: "datePicker",
                                  muiPicker: true,
                                  name: "dueDate",
                                  label: "Fizetési határidő",
                                  required: true
                                }
                              ]
                            ]}
                          />
                          {/*<Grid container spacing={3}>*/}
                          {/*  <Grid item xs={12} md={4}>*/}
                          {/*    <Field*/}
                          {/*      name={"dueDate"}*/}
                          {/*      error={errors.issueDate && touched.issueDate}*/}
                          {/*      component={DatePickerField}*/}
                          {/*      label={"Fizetési határidő"}*/}
                          {/*      validate={required}*/}
                          {/*    />*/}
                          {/*  </Grid>*/}
                          {/*  <Grid item xs={12} md={4}>*/}
                          {/*    <Field*/}
                          {/*      name={"taxPointDate"}*/}
                          {/*      error={*/}
                          {/*        errors.taxPointDate && touched.taxPointDate*/}
                          {/*      }*/}
                          {/*      component={DatePickerField}*/}
                          {/*      label={"Teljesítés dátuma"}*/}
                          {/*      validate={required}*/}
                          {/*    />*/}
                          {/*  </Grid>*/}
                          {/*  <Grid item xs={12} md={4}>*/}
                          {/*    <Field*/}
                          {/*      name={"dueDate"}*/}
                          {/*      error={errors.dueDate && touched.dueDate}*/}
                          {/*      component={DatePickerField}*/}
                          {/*      label={"Fizetési határidő"}*/}
                          {/*      validate={required}*/}
                          {/*    />*/}
                          {/*  </Grid>*/}
                          {/*</Grid>*/}
                        </Grid>
                        {/*<Grid*/}
                        {/*item*/}
                        {/*xs={12}*/}
                        {/*md={4}*/}
                        {/*style={{ marginBottom: "3rem" }}*/}
                        {/*>*/}
                        {/*<FormControlLabel*/}
                        {/*control={*/}
                        {/*<Checkbox*/}
                        {/*value={modifiedDueDate}*/}
                        {/*onChange={e =>*/}
                        {/*setModifiedDueDate(e.target.checked)*/}
                        {/*}*/}
                        {/*/>*/}
                        {/*}*/}
                        {/*label={"Módosított fizetési határidő"}*/}
                        {/*/>*/}
                        {/*{modifiedDueDate && (*/}
                        {/*<Field*/}
                        {/*name={"modifiedDueDate"}*/}
                        {/*component={DatePickerField}*/}
                        {/*label={"Módisított fizetési határidő"}*/}
                        {/*validate={required}*/}
                        {/*defaultValue={moment(new Date()).format(*/}
                        {/*"YYYY-MM-DD"*/}
                        {/*)}*/}
                        {/*/>*/}
                        {/*)}*/}
                        {/*</Grid>*/}
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          variant={"h5"}
                          style={{ marginBottom: "2rem" }}
                        >
                          Összegek
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Fields
                              config={[
                                isScf
                                  ? [
                                      {
                                        fieldType: "text",
                                        type: "number",
                                        name: `totalGrossAmount`,
                                        label: "Számla bruttó összege",
                                        required: true
                                      },
                                      {
                                        fieldType: "text",
                                        type: "number",
                                        name: `compensation`,
                                        label: "Beszámítás összege",
                                        required: false
                                      }
                                    ]
                                  : [
                                      {
                                        fieldType: "text",
                                        type: "number",
                                        name: `totalGrossAmount`,
                                        label: "Számla bruttó összege",
                                        required: true
                                      }
                                    ]
                              ]}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  className="invoice-attachments-upload"
                >
                  <InvoiceAttachmentUploadNewUpload
                    invoiceId={props.initialValues?.id}
                    reloadInvoice={props.reloadInvoice}
                    onAddFile={files =>
                      setAttachedFiles([...attachedFiles, ...files])
                    }
                    onDeleteFile={async file => {
                      const { id } = file;
                      if (id) {
                        const { data, error } = await props.deleteAttachment(
                          id
                        );

                        if (data) {
                          form.mutators.removeDocument(id);
                          setAttachedFiles(
                            attachedFiles.filter(f => f.file.id !== id)
                          );
                        } else {
                          notifyError(selectErrorNotificationMessage(error));
                        }
                      } else {
                        setAttachedFiles(items =>
                          items.filter(f => f.file.name !== file.name)
                        );
                      }
                    }}
                    files={attachedFiles}
                    onFileTypeChanged={(file, type) =>
                      setAttachedFiles(
                        attachedFiles.map(f => {
                          if (f.file.name === file.name) {
                            return { ...f, type };
                          }

                          return f;
                        })
                      )
                    }
                  />
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
    </>
  );
};

export default InvoiceForm;
