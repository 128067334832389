/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */

import { defineMessages } from "react-intl";

export default defineMessages({
  flexiPieChartTitle: {
    id: "app.components.flexiPieChart.title",
    defaultMessage: "Nyitott számlák"
  },
  flexiPieChartDetailsButton: {
    id: "app.components.flexiPieChart.details.button.text",
    defaultMessage: "Részletek"
  }
});
