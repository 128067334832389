import PageLayout from "../../components/PageLayout";
import React, { useCallback } from "react";
import DocumentsByDate from "../../components/DocumentsByDate";
import ErrorBoundary from "../../components/ErrorBoundary";
import { useInvoiceDocumentsPageData } from "../../hooks/useApi";
import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import DocumentsCarousel from "../../components/DocViewer";
import { getUrlWithCompanyId } from "../../utils/urlHelpers";
import placeholderBill from "../../images/bill/documentPlaceholderBill.svg";
import { Grid, Paper, Typography } from "@material-ui/core";
import useHasPermission from "../../components/RoleProvider/useHasPermission";
import { ROLE_FLEXIPAY_CUSTOMER_PLUS } from "../../roles";

const InvoiceDocumentPage = ({ match, history }) => {
  const { invoice, downloadDocument, loading } = useInvoiceDocumentsPageData(
    match.params.id
  );
  const goBack = useCallback(() => history.push("/app/invoice"), []);
  const hasPermission = useHasPermission();
  const isScf = hasPermission([ROLE_FLEXIPAY_CUSTOMER_PLUS]);
  const [docPopOpen, setDocPopOpen] = React.useState(false);
  const [selectedDoc, setSelectedDoc] = React.useState([]);

  return (
    <PageLayout
      insidePage
      pay
      titleMessage={{
        id: "pageTitle.invoiceDocuments",
        defaultMessage: "Számlához tartozó dokumentumok"
      }}
      loading={loading}
      backButton
      onBackButtonClick={goBack}
      backUrl={"/app/invoice"}
    >
      {Boolean(invoice?.documents.length) ? (
        <ErrorBoundary>
          <DocumentsByDate
            data={invoice && invoice.documents}
            onDownloadClick={document => {
              downloadDocument(document);
            }}
            onShowClick={doc => {
              setSelectedDoc([doc]);
              setDocPopOpen(true);
            }}
          />
          <FlexiDialog
            open={docPopOpen}
            title={selectedDoc.originalName}
            onClose={() => setDocPopOpen(false)}
          >
            <DocumentsCarousel
              documents={selectedDoc}
              getUrl={() =>
                selectedDoc &&
                getUrlWithCompanyId(
                  isScf
                    ? `/scfplus/invoice/${invoice?.id}/document-download/${selectedDoc[0].id}`
                    : `/invoice/document/${selectedDoc[0].id}`
                )
              }
            />
          </FlexiDialog>
        </ErrorBoundary>
      ) : (
        <Grid xs={12} lg={10} style={{ justifySelf: "center" }}>
          <Paper style={{ margin: "auto" }}>
            <Grid container direction={"row"}>
              <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
                <img src={placeholderBill} alt={placeholderBill} />
              </Grid>
              <Grid item xs={12} sm={10}>
                <Grid item>
                  <Typography variant={"h5"}>
                    Nincsenek számlához tartozó dokumentumok!
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: "12px" }}>
                  <Typography variant={"body2"}>
                    Itt megtekintheti és letöltheti a számlához feltöltött
                    számlaképet, valamint a teljesítés igazolást. Ehhez a
                    számlához nincsenek feltöltve dokumentumok.{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
    </PageLayout>
  );
};

export default InvoiceDocumentPage;
