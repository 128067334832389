import PropTypes from "prop-types";
import React from "react";
import { Bar } from "react-chartjs-2";
import Paper from "@material-ui/core/Paper";
import { Box, Grid, Typography } from "@material-ui/core";
import "./chartHelpers";
import withWidth from "@material-ui/core/withWidth";
import { compose } from "recompose";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

class FlexiBarChart extends React.PureComponent {
  render() {
    const { data, height, minHeight } = this.props;

    const statusLabelArray = data.map(item => item.label);
    const valuesArray = data.map(item => item.value);

    const config = {
      type: "bar",
      options: {
        cornerRadius: 5,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              ticks: {
                display: false
              },
              gridLines: {
                display: false,
                drawOnChartArea: false,
                color: "rgba(0, 0, 0, 0)"
              }
            }
          ],
          xAxes: [
            {
              display: false,
              gridLines: {
                display: false,
                color: "rgba(0, 0, 0, 0)"
              },
              ticks: {
                display: false
              }
            },
            {
              position: "top",
              ticks: {
                display: false
              },
              gridLines: {
                display: false,
                drawTicks: false,
                color: "rgba(0, 0, 0, 0)"
              }
            }
          ]
        }
      }
    };

    const colors = ["#D6BDF6", "#C59BFC", "#9747FF"];

    const getTotalChartData = canvas => {
      const ctx = canvas.getContext("2d");

      return {
        labels: statusLabelArray,
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 1,
            data: valuesArray
          }
        ]
      };
    };

    return (
      <Paper
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
          height: "calc(100% - 2rem)"
        }}
      >
        <Grid container spacing={2} style={{ marginBottom: 30 }}>
          <Grid item xs={12}>
            <Typography variant="h4" style={{ whiteSpace: "nowrap" }}>
              <FormattedMessage {...messages.flexiBarChartTitle} />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={8}>
            <div
              style={{
                minHeight: minHeight || 240,
                height: height || 240,
                borderBottom: "1px solid grey"
              }}
            >
              <Bar data={getTotalChartData} {...config} id={"bar-chart"} />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid container spacing={2}>
              {statusLabelArray.map((item, i) => (
                <Grid item key={`flexi-bar-chart-item-${i}`} xs={12}>
                  <Box display={"flex"} style={{ gap: "1rem" }}>
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "2px",
                        backgroundColor: colors[i]
                      }}
                    />
                    <Typography style={{ whiteSpace: "nowrap" }}>
                      {item}: {valuesArray[i]}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const enhance = compose(withWidth());

FlexiBarChart.propTypes = {
  data: PropTypes.array,
  onClick: PropTypes.func
};

export default enhance(FlexiBarChart);
