import FlexiProgressBar from "../../FlexiComponents/FlexiProgressBar";
import { useIntl } from "react-intl";
import React, { useMemo, useState } from "react";
import { makeCurrencyValueFormatter } from "../../utils/makeCurrencyValueFormatter";
import { defineMessages } from "react-intl.macro";
import IconButton from "@material-ui/core/IconButton";
import { Box } from "@material-ui/core";
import FlexiExpandableRemoteList from "../../FlexiComponents/FlexiExpandableRemoteList";
import { getFlexipayUrl } from "../../utils/urlHelpers";
import { useHistory } from "react-router-dom";

const columnMessages = defineMessages({
  supplierName: {
    id: "listColumnHeader.factoryContracts.supplierName",
    defaultMessage: "Szállító neve"
  },
  customerName: {
    id: "listColumnHeader.factoryContracts.customerName",
    defaultMessage: "Vevő neve"
  },
  limitAmount: {
    id: "listColumnHeader.factoryContracts.limitAmount",
    defaultMessage: "Limit"
  },
  status: {
    id: "listColumnHeader.factoryContracts.status",
    defaultMessage: "Státusz"
  },
  createdAt: {
    id: "listColumnHeader.factoryContracts.createdAt",
    defaultMessage: "Létrehozás dátuma"
  },
  modifiedAt: {
    id: "listColumnHeader.factoryContracts.modifiedAt",
    defaultMessage: "Módosítás dátuma"
  }
});

const makeUsageRenderer = intl => params => {
  const currencyFormatter = makeCurrencyValueFormatter(intl);
  const { push: redirect } = useHistory();
  return (
    <Box display={"flex"} mt={"8px"} width={"100%"}>
      <IconButton onClick={() => redirect("/app/limit-change-list")}>
        -
      </IconButton>
      <FlexiProgressBar
        tooltip={`${currencyFormatter(params)} a kihasználtság`}
        percentage={(params.data.limitUsed / params.data.limitAmount) * 100}
      />
      <IconButton onClick={() => redirect("/app/limit-change-list")}>
        +
      </IconButton>
    </Box>
  );
};

const InProgressList = ({}) => {
  const intl = useIntl();
  const columnDefs = useMemo(() => {
    const currencyFormatter = makeCurrencyValueFormatter(intl);

    return [
      {
        headerName: intl.formatMessage(columnMessages.supplierName),
        field: "supplierName",
        sortable: true,
        autoHeight: true,
        resizable: true,
        mobileCellXs: 12,
        cellClass: "cell-wrap-text",
        filterConfig: {
          type: "text",
          filterParam: "supplierName",

          collapsed: false,
          order: 1
        }
      },
      {
        headerName: intl.formatMessage(columnMessages.customerName),
        field: "customerName",
        sortable: true,
        autoHeight: true,
        resizable: true,
        mobileCellXs: 12
      },
      {
        headerName: intl.formatMessage(columnMessages.limitAmount),
        field: "limitAmount",
        valueFormatter: currencyFormatter,
        sortable: true,
        autoHeight: true,
        cellClass: "cell-wrap-text",
        mobileCellXs: 12
      },
      {
        headerName: intl.formatMessage(columnMessages.status),
        valueGetter: ({ data }) => data.limitAmount - data.limitUsed || null,
        valueFormatter: currencyFormatter,
        sortable: true,
        autoHeight: true,
        cellClass: "cell-wrap-text",
        mobileCellXs: 12,
        filterConfig: {
          type: "select",
          filterParam: "status",
          options: [{ value: "a", text: "a" }],
          collapsed: false,
          order: 3
        }
      },
      {
        headerName: intl.formatMessage(columnMessages.createdAt),
        valueGetter: ({ data }) => data.limitAmount - data.limitUsed || null,
        valueFormatter: currencyFormatter,
        sortable: true,
        autoHeight: true,
        cellClass: "cell-wrap-text",
        mobileCellXs: 12,
        filterConfig: {
          type: "text",
          filterParam: "createdAt",

          collapsed: false,
          order: 4
        }
      },
      {
        headerName: intl.formatMessage(columnMessages.modifiedAt),
        field: "updatedAt",
        cellRendererFramework: makeUsageRenderer(intl),
        sortable: true,
        autoHeight: true,
        mobileCellXs: 12,
        filterConfig: {
          type: "text",
          filterParam: "updatedAt",
          collapsed: false,
          order: 5
        }
      }
    ];
  }, []);

  return (
    <>
      <FlexiExpandableRemoteList
        title={"asd"}
        columnDefs={columnDefs}
        url={getFlexipayUrl("/sales/in-progress/contract-agreement-claim")}
        suppressExpand
        hideSearchBar={true}
        hidePager={false}
        advancedFilterEnabled
        rowsPerPageOptions={[50]}
        showMoreLimit={50}
      />
    </>
  );
};

export default InProgressList;
