import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Pagination from "@material-ui/lab/Pagination";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

const PDFDocumentWrapper = styled.div`
  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

class FlexiDocumentPreview extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
    imgSrcProp: null,
    pdfFileProp: null
  };

  componentDidMount() {
    // Ha csak siman jon egy url es nicns base64-ezes akkor ez most a legegyszerubb, remek mennyi fele keppen mukodunk.
    if (
      this.props.file &&
      this.props.file.path &&
      /\/media\//.test(this.props.file.path)
    ) {
      this.setState({
        imgSrcProp: this.props.file.path
      });
    } else if (this.props.file) {
      const fileType = this.props.file.type || this.props.file.mimeType;

      if (fileType === "application/pdf") {
        this.setState({ pdfFileProp: this.props.file });
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(this.props.file);
        reader.onload = () => {
          this.setState({
            imgSrcProp: reader.result
          });
        };
        reader.onerror = function(error) {
          console.log("Error: ", error);
        };
      }
    } else {
      const dataUrl = `data:${this.props.base64.mimeType};base64,${
        this.props.base64.image
      }`;

      if (this.props.base64.mimeType === "application/pdf") {
        this.setState({ pdfFileProp: dataUrl });
      } else {
        this.setState({ imgSrcProp: dataUrl });
      }
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    const { pageNumber, numPages, imgSrcProp, pdfFileProp } = this.state;
    const handleChange = (event, value) => {
      this.setState({ pageNumber: value });
    };

    return pdfFileProp ? (
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item>
          <PDFDocumentWrapper>
            <Document
              file={pdfFileProp}
              onLoadSuccess={this.onDocumentLoadSuccess}
              loading={<CircularProgress />}
            >
              <Page pageNumber={pageNumber} renderTextLayer={false} />
            </Document>
          </PDFDocumentWrapper>
        </Grid>
        <Grid item>
          <Pagination
            count={numPages}
            page={this.state.pageNumber}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    ) : (
      <img src={imgSrcProp} style={{ width: "100%", height: "auto" }} />
    );
  }
}
export default FlexiDocumentPreview;
