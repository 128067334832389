import React from "react";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import useFilterLargeFiles from "../../hooks/useFilterLargeFiles";
import { Field } from "react-final-form";
import { useAgreements } from "../../hooks/useApi";
import FlexiFileUploader from "../../FlexiComponents/FlexiFileUploader";
import Box from "@material-ui/core/Box";
import { getUrlWithCompanyId } from "../../utils/urlHelpers";

const InvoiceAttachmentUploadNewUpload = ({
  width,
  files,
  onlyButton,
  onlyDescription,
  onAddFile,
  onDeleteFile,
  fileCountLimit = null,
  fileTypes = [
    { value: "certificate_of_completion", text: "Teljesítési igazolás" },
    { value: "invoice", text: "Számla" },
    {
      value: "invoice_and_certificate_of_completion",
      text: "Számla és teljesítési igazolás"
    }
  ],
  description = "Itt töltheted fel a számlához tartozó számlaképet, valamint a teljesítés igazolást.",
  defaultType = "invoice",
  invoiceId,
  reloadInvoice
}) => {
  const inputEl = React.useRef(null);

  let buttonDisabled = false;

  if (fileCountLimit !== null && fileCountLimit <= files.length) {
    buttonDisabled = true;
  }

  const filterFiles = useFilterLargeFiles(files);
  const agreements = useAgreements();

  return (
    <Field name={"agreementId"} subscription={{ value: true }}>
      {({ input, ...rest }) => {
        const agreement = agreements.data?.find(
          item => item.agreementId === parseInt(input.value, 10)
        );

        return (
          <>
            {onlyButton && (
              <Grid container>
                <Grid item xs={12} md={"auto"}>
                  <Button
                    style={{ height: "32px" }}
                    outlined
                    fullWidth={isWidthDown("xs", width) && true}
                    disabled={buttonDisabled}
                    onClick={() => {
                      if (inputEl.current) {
                        inputEl.current.click();
                      }
                    }}
                  >
                    <Typography style={{ color: "#142595", fontSize: "12px" }}>
                      Fájl hozzáadása
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            )}
            {!onlyButton && (
              <Paper square style={{ padding: "32px 40px 40px" }}>
                <Grid container spacing={onlyButton ? 0 : 3}>
                  {!onlyButton && (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          variant={"h5"}
                          style={{ marginBottom: "2rem" }}
                        >
                          Fájl csatolása
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant={"body1"}>{description}</Typography>
                      </Grid>
                    </>
                  )}
                  {onlyDescription && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant={"body1"}>{description}</Typography>
                      </Grid>
                    </>
                  )}

                  {!agreement?.documentTypes?.length && (
                    <Box paddingLeft={"13px"}>
                      <Typography>Válassz vevőt!</Typography>
                    </Box>
                  )}
                  {agreement?.documentTypes?.map(item => (
                    <Grid item xs={12}>
                      <FlexiFileUploader
                        title={`${item.name} ${item.required ? "*" : ""}`}
                        files={files
                          .filter(file => {
                            if (item.id) {
                              if (file.type === "custom") {
                                if (file.customDocumentType) {
                                  return file.customDocumentType === item.id;
                                }
                                return file.file.customType.id === item.id;
                              }
                              return file.customDocumentType === item.id;
                            }

                            return file.type === item.type;
                          })
                          .map(({ file }) => file)}
                        onSubmit={values =>
                          onAddFile(
                            values.files.map(f => ({
                              file: f,
                              type: item.type,
                              customDocumentType: item.id
                            }))
                          )
                        }
                        getDeleteUrl={file =>
                          getUrlWithCompanyId(
                            `/invoice/${invoiceId}/document/${file.id}`
                          )
                        }
                        onDeleteFile={
                          file => onDeleteFile(file)
                          // deleteFunc().then(() => file.id && reloadInvoice())
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            )}
          </>
        );
      }}
    </Field>
  );
};

export default withWidth()(InvoiceAttachmentUploadNewUpload);
