import React, { useCallback, useContext, useMemo } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FlexiFormContext, useForm } from "../index";
import { useDebounce } from "@react-hook/debounce";
import FlexiRemotePager from "./../../FlexiRemotePager/index.js";
import BaseField from "./BaseField";

const RenderAutosuggest = ({
  url,
  label,
  input,
  meta,
  getOptions,
  renderOption,
  clearText = "Töröl",
  closeText = "Bezár",
  loadingText = "Betöltés",
  noOptionsText = "Nincs találat",
  emptyInputNoOptionsText = "Kezdj el gépelni...",
  placeholder,
  searchParam = "search",
  FormHelperTextProps,
  setValueTo,
  freeSolo = true,
  onSelect,
  resetAfterSelect = Boolean(onSelect),
  suppressValueSetting
}) => {
  const { token } = useContext(FlexiFormContext);
  const [searchText, setSearchText] = useDebounce("", 300);
  const searchQueryParams = useMemo(() => ({ [searchParam]: searchText }), [
    searchText
  ]);
  const onLoadingChange = useCallback(() => undefined, []);
  const form = useForm();

  return (
    <React.Fragment>
      <FlexiRemotePager
        url={url}
        token={token}
        extraQueryParams={searchQueryParams}
        onLoadingChange={onLoadingChange}
        onError={() => null}
      >
        {({ items, loading }) => {
          const options = getOptions(items);

          return (
            <Autocomplete
              freeSolo={freeSolo}
              clearText={clearText}
              closeText={closeText}
              loadingText={loadingText}
              noOptionsText={
                searchText.length ? noOptionsText : emptyInputNoOptionsText
              }
              getOptionSelected={(option, value) => option.text === value.text}
              getOptionLabel={option => option.text}
              inputValue={input.value}
              onInputChange={(e, value, reason) => {
                // todo kideriteni ez miert nullazza le az elejen
                if (reason !== "reset") {
                  if (reason === "clear") {
                    form.mutators.setValue(setValueTo, undefined);
                  }
                  setSearchText(value);
                  input.onChange(value);
                }
              }}
              onChange={(e, value) => {
                if (!suppressValueSetting) {
                  form.mutators.setValue(
                    setValueTo,
                    value ? value.value : undefined
                  );
                }
                if (typeof onSelect === "function") {
                  onSelect(value);
                  if (resetAfterSelect) {
                    form.mutators.setValue(setValueTo, undefined);
                    input.onChange("");
                    setSearchText("");
                  }
                }
              }}
              options={options}
              loading={loading}
              renderOption={renderOption}
              renderInput={params => (
                <TextField
                  {...params}
                  onBlur={input.onBlur}
                  onFocus={input.onFocus}
                  error={Boolean(
                    meta.touched && typeof meta.error === "string" && meta.error
                  )}
                  helperText={
                    meta.touched && typeof meta.error === "string" && meta.error
                  }
                  label={label}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                  placeholder={placeholder}
                  FormHelperTextProps={FormHelperTextProps}
                  name={input.name}
                />
              )}
            />
          );
        }}
      </FlexiRemotePager>
    </React.Fragment>
  );
};

const Autosuggest = props => {
  return <BaseField {...props} component={RenderAutosuggest} />;
};

export default Autosuggest;
