import React, { useMemo, useState } from "react";
import CheckboxField from "./CheckboxField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useField } from "react-final-form";
import FlexiDialog from "../../FlexiDialog/index.js";

const CheckboxFieldWithDialog = ({
  name,
  required,
  label,
  validate,
  fullWidth,
  disabled,
  url,
  title
}) => {
  const fullWidthProps = useMemo(
    () => {
      if (fullWidth) {
        return {
          formControlProps: {
            style: {
              width: "100%"
            }
          }
        };
      }

      return {};
    },
    [fullWidth]
  );

  const [open, setOpen] = useState(false);
  const field = useField(name);
  const onClose = () => setOpen(false);

  return (
    <div style={{ position: "relative" }}>
      <div
        onClick={e => {
          if (field.input.value) {
            field.input.onChange(false);
          } else {
            setOpen(true);
          }
        }}
        style={{ cursor: "pointer" }}
      >
        <span style={{ pointerEvents: "none" }}>
          <CheckboxField
            label={label}
            {...fullWidthProps}
            name={name}
            required={required}
            validate={validate}
            disabled={disabled}
          />
        </span>
      </div>
      <FlexiDialog
        size={"small"}
        title={title}
        onClose={onClose}
        open={open}
        dialogActions={
          <Button
            onClick={() => {
              field.input.onChange(true);
              onClose();
            }}
            style={{ margin: "auto" }}
          >
            Elfogadom
          </Button>
        }
      >
        <Grid>
          <iframe style={{ width: "100%", height: "488px" }} src={url} />
        </Grid>
      </FlexiDialog>
    </div>
  );
};

export default CheckboxFieldWithDialog;
