import React from "react";
import FlexiDialog from "../../../FlexiComponents/FlexiDialog";
import Grid from "@material-ui/core/Grid";
import acceptSvg from "../../../images/redesignImages/acceptSvg.svg";
import cancelSvg from "../../../images/redesignImages/cancelSvg.svg";
import pauseSvg from "../../../images/redesignImages/pauseSvg.svg";
import modifySvg from "../../../images/redesignImages/modifySvg.svg";
import { defineMessages } from "react-intl.macro";
import Typography from "@material-ui/core/Typography";
import { FormattedHTMLMessage, useIntl } from "react-intl";

const messages = defineMessages({
  confirmationDialogText: {
    id: "ConfirmationDialogText",
    defaultMessage: "A számlákat az alábbiak szerint igazolhatja vissza:"
  },
  confirmationDialogTitle: {
    id: "ConfirmationDialogTitle",
    defaultMessage: "Számlák visszaigazolása"
  },
  confirmationStatusTitle1: {
    id: "ConfirmationDialogTitle",
    defaultMessage: "Elfogadás"
  },
  confirmationStatusTitle2: {
    id: "confirmationStatusTitle2",
    defaultMessage: "Elutasítás"
  },
  confirmationStatusTitle3: {
    id: "confirmationStatusTitle3",
    defaultMessage: "Várakoztatás"
  },
  confirmationStatusTitle4: {
    id: "confirmationStatusTitle4",
    defaultMessage: "Módosítás"
  },
  confirmationStatusText1: {
    id: "confirmationStatusText1",
    defaultMessage:
      "A számla a valóságnak megfelel és minden rendben van vele. Kifizetését tervezi a vállalat."
  },
  confirmationStatusText2: {
    id: "confirmationStatusText2",
    defaultMessage:
      "A számlával valami probléma van, így ebben a formában nem tervezzük kifizetni."
  },
  confirmationStatusText3: {
    id: "confirmationStatusText3",
    defaultMessage:
      "A számlával kapcsolatban még nincs elég információnk a visszajelzéshez, így később szeretnénk válaszolni."
  },
  confirmationStatusText4: {
    id: "confirmationStatusText4",
    defaultMessage:
      "Itt lehet megadni, ha a számla összegéből szeretne beszámítást levonni, illetve megadhatja, hogy mikor várható a számla tényleges kifizetése."
  }
});

const ConfirmationHelpDialog = props => {
  const intl = useIntl();

  return (
    <FlexiDialog
      size={"small"}
      darkerBackdrop
      onClose={props.onClose}
      open={props.open}
      title={intl.formatMessage(messages.confirmationDialogTitle)}
    >
      <Grid container direction="column">
        <Grid item>
          <Grid container style={{ paddingBottom: "30px" }}>
            <Grid
              item
              xs={1}
              style={{ paddingRight: "20px", alignSelf: "center" }}
            >
              <img alt={"modifyConfirm"} src={modifySvg} />
            </Grid>
            <Grid item xs={11}>
              <Grid container direction="column">
                <Grid item style={{ paddingBottom: "4px" }}>
                  <Typography variant={"h6"}>
                    {intl.formatMessage(messages.confirmationStatusTitle4)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {intl.formatMessage(messages.confirmationStatusText4)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container style={{ paddingBottom: "30px" }}>
            <Grid
              item
              xs={1}
              style={{ paddingRight: "20px", alignSelf: "center" }}
            >
              <img alt={"okConfirm"} src={acceptSvg} />
            </Grid>
            <Grid item xs={11}>
              <Grid container direction="column">
                <Grid item style={{ paddingBottom: "4px" }}>
                  <Typography variant={"h6"}>
                    {intl.formatMessage(messages.confirmationStatusTitle1)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {intl.formatMessage(messages.confirmationStatusText1)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container style={{ paddingBottom: "30px" }}>
            <Grid
              item
              xs={1}
              style={{ paddingRight: "20px", alignSelf: "center" }}
            >
              <img alt={"notOkConfirm"} src={cancelSvg} />
            </Grid>
            <Grid item xs={11}>
              <Grid container direction="column">
                <Grid item style={{ paddingBottom: "4px" }}>
                  <Typography variant={"h6"}>
                    {intl.formatMessage(messages.confirmationStatusTitle2)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {intl.formatMessage(messages.confirmationStatusText2)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid
              item
              xs={1}
              style={{ paddingRight: "20px", alignSelf: "center" }}
            >
              <img alt={"pauseConfirm"} src={pauseSvg} />
            </Grid>
            <Grid item xs={11}>
              <Grid container direction="column">
                <Grid item style={{ paddingBottom: "4px" }}>
                  <Typography variant={"h6"}>
                    {intl.formatMessage(messages.confirmationStatusTitle3)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {intl.formatMessage(messages.confirmationStatusText3)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};

export default ConfirmationHelpDialog;
