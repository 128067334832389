import Box from "@material-ui/core/Box";
import React from "react";
import { FormHelperText } from "@material-ui/core";
import { Fields } from "../index";
import { makeStyles } from "@material-ui/core/styles";

const useBoldStyle = makeStyles({
  root: {
    fontFamily: "Nunito-Bold",
    color: "#4A4A4A"
  }
});

const InputGroup = ({ groupName, fieldsConfig, style }) => {
  const boldClasses = useBoldStyle();

  return (
    <Box style={style}>
      <FormHelperText classes={boldClasses}>{groupName}</FormHelperText>
      <Fields config={fieldsConfig} />
    </Box>
  );
};

export default InputGroup;
