import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { composeValidators, maxNum, number } from "../../utils/validators";
import FlexiForm, { Fields } from "../../FlexiComponents/FlexiForm";

const EditForm = ({ onSubmit, initialValues }) => {
  return (
    <FlexiForm
      FinalFormFormProps={{
        onSubmit,
        initialValues
      }}
      render={({ handleSubmit }) => {
        return (
          <form id={"CustomerConfirmationEditForm"} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  Módosítás - {initialValues?.invoiceId}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Fields
                  config={[
                    [
                      {
                        fieldType: "datePicker",
                        label: "Módosított fizetési határidő",
                        disableDayBeforeToday: true,
                        name: "modifiedDueDate",
                        muiPicker: true
                      }
                    ],
                    [
                      {
                        fieldType: "currency",
                        label: "Beszámítás összege",
                        name: "compensation",
                        validate: composeValidators(
                          number,
                          maxNum(initialValues?.maxCompensation)
                        )
                      }
                    ]
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type={"submit"} fullWidth>
                  Módosítás és elfogadás
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

export default EditForm;
