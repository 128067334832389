import FlexiBarChart from "../../FlexiComponents/FlexiBarChart";
import React from "react";
import { useHistory } from "react-router-dom";
import EmptySection from "../../components/EmptySection";
import emptyLogo from "../../images/redesignImages/345-loader-16 1.svg";
import uploadIcon from "../../images/redesignImages/upload-icon.svg";
import { useProfileData } from "../../hooks/useApi";

const DashboardStatusesChart = ({ statusCountSum, statusCountChartData }) => {
  const { push: redirect } = useHistory();
  const { szamlazzHu } = useProfileData();
  const { kulcsSoft } = useProfileData();

  return (
    <>
      {statusCountSum ? (
        <FlexiBarChart
          onButtonClick={() => redirect("/app/invoice")}
          data={statusCountChartData}
          onStatusClick={status => redirect(`/app/invoice/${status}`)}
          minHeight={185}
          height={185}
        />
      ) : (
        <EmptySection
          sectionTitle={"Számla státuszok"}
          sectionDescription={
            szamlazzHu
              ? "Itt jelennek meg számláid, miután azokat Számlázz.hu rendszerével szinkronizáltuk."
              : "Egyelőre nem töltöttél fel számlákat. Itt az idő! Kezdj faktorálni!"
          }
          imgSrc={emptyLogo}
          buttonText={!szamlazzHu || (!kulcsSoft && "Új számlák feltöltése")}
          buttonIcon={!szamlazzHu || (!kulcsSoft && uploadIcon)}
          onClick={() => redirect("/app/invoice")}
        />
      )}
    </>
  );
};

export default DashboardStatusesChart;
