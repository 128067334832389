import React from "react";
import FlexiDialog from "../../FlexiComponents/FlexiDialog";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import billAlert from "../../images/bill/bill_alert.svg";
import { defineMessages } from "react-intl.macro";
import { resetAuthData } from "../../utils/authentication";

const messages = defineMessages({
  otherInterfaceTitle: {
    id: "otherInterfaceTitle.accountMatchingDialog",
    defaultMessage: "Itt nincs még fiókod"
  },
  accountSuspendedTitle: {
    id: "accountSuspendedTitle.accountMatchingDialog",
    defaultMessage: "Nincs aktív hozzárendelésed!"
  },
  accountNotActiveTitle: {
    id: "accountNotActiveTitle.accountMatchingDialog",
    defaultMessage: "Még nem aktiváltad a fiókod!"
  },
  otherInterfaceDescription: {
    id: "otherInterfaceDescription.accountMatchingDialog",
    defaultMessage:
      "A fiókod a másik felületünkhöz tartozik! Kérlek, jelentkezz át az alábbi gombra kattintva."
  },
  accountSuspendedDescription: {
    id: "accountSuspendedDescription.accountMatchingDialog",
    defaultMessage:
      "Úgy látjuk, felfüggesztették vagy megszüntették a céghez való hozzáférésed. Ha kérdésed van, vedd fel a kapcsolatot a vállalkozás admin munkatársával!"
  },
  accountNotActiveDescription: {
    id: "accountNotActiveDescription.accountMatchingDialog",
    defaultMessage:
      "Ahhoz, hogy beléphess a fiókodba, aktiválnod kell, az e-mail címed megerősítésével. A regisztráció során megadott e-mail címre küldtünk egy linket, ahonnan ezt megteheted."
  },
  otherInterfaceButtonText: {
    id: "otherInterfaceButtonText.accountMatchingDialog",
    defaultMessage: "Tovább az online számlázáshoz"
  },
  accountSuspendedButtonText: {
    id: "accountSuspendedButtonText.accountMatchingDialog",
    defaultMessage: "Rendben"
  }
});

const AccountMatchingDialog = ({
  otherInterface,
  accountSuspended,
  noCompany,
  onClose
}) => {
  return (
    <FlexiDialog
      size={"small"}
      onClose={onClose}
      open={otherInterface || accountSuspended || noCompany}
      title={
        (accountSuspended &&
          !otherInterface &&
          "Nincs aktív hozzárendelésed!") ||
        (otherInterface && "Itt nincs még fiókod") ||
        (noCompany &&
          !accountSuspended &&
          !otherInterface &&
          "A fiókhoz nem tartozik vállalkozás")
      }
      imgSrc={billAlert}
      imgWidth={60}
      dialogActions={
        <div style={{ margin: "auto" }}>
          {otherInterface && (
            <Button
              href={"https://app.flexibill.hu/login"}
              onClick={() => resetAuthData()}
              style={{ margin: "auto" }}
            >
              Tovább az online számlázáshoz
            </Button>
          )}
        </div>
      }
    >
      {otherInterface && (
        <Typography fontSize={14}>
          A fiókod a másik felületünkhöz tartozik! Kérlek, jelentkezz át az
          alábbi gombra kattintva.
        </Typography>
      )}
      {!otherInterface && accountSuspended && (
        <Typography fontSize={14}>
          Úgy látjuk, felfüggesztették vagy megszüntették a céghez való
          hozzáférésed. Ha kérdésed van, vedd fel a kapcsolatot a vállalkozás
          admin munkatársával!
        </Typography>
      )}
      {!otherInterface && !accountSuspended && noCompany && (
        <Typography fontSize={14}>
          Ha kérdésed van, írj nekünk a{" "}
          <a
            style={{ color: "#394AB8", textDecoration: "none" }}
            href="mailto:support@flexibill.hu"
          >
            support@flexibill.hu
          </a>{" "}
          e-mail címre.
        </Typography>
      )}
    </FlexiDialog>
  );
};

export default AccountMatchingDialog;
