import React, { useMemo } from "react";
import PageLayout from "../../components/PageLayout";
import { Paper } from "@material-ui/core";
import FlexiForm, {
  Fields,
  makeExternalFormSubmit
} from "../../FlexiComponents/FlexiForm";
import Typography from "@material-ui/core/Typography";
import AgreementSelectField from "../../components/FormFields/AgreementSelectField";
import Grid from "@material-ui/core/Grid";
import { getFlexipayUrl, getUrlWithCompanyId } from "../../utils/urlHelpers";
import {
  useAgreements,
  useMainCompanyId,
  useMutator
} from "../../hooks/useApi";
import redirect from "react-router-dom/es/Redirect";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import {
  useErrorOccurredNotification,
  useSaveSuccessNotification
} from "../../hooks/useNotificationState";
import { useHistory } from "react-router-dom";
const formId = "limitChangeForm";

const submit = makeExternalFormSubmit(formId);

const limitChangeFields = [
  [
    {
      fieldType: "text",
      type: "number",
      name: `agreement.limitAmount`,
      label: "Limit",
      disabled: true
    },
    {
      fieldType: "text",
      type: "number",
      name: `modifiedLimit`,
      label: "Módosított limit",
      required: true
    }
  ]
];

const LimitChangePage = ({}) => {
  const [post, postLoading] = useMutator("POST");
  const { push: redirect } = useHistory();
  const notifyError = useErrorOccurredNotification();
  const notifySuccessSave = useSaveSuccessNotification();
  const { agreements } = useAgreements();
  return (
    <PageLayout
      insidePage
      pay
      loading={postLoading}
      suppressTourButton
      title="Limitmódosítás"
      rightSideComponents={[
        {
          props: {
            onClick: submit,
            children: "Mentés"
          },
          mobile: {
            placement: "toolbar"
          }
        }
      ]}
    >
      <Paper>
        <Typography variant={"h5"} style={{ marginBottom: "20px" }}>
          Új limit igénylése
        </Typography>
        <Typography variant={"body1"} style={{ marginBottom: "20px" }}>
          Válaszd ki a vevőd és add meg az összeget, amire módosítani szeretnéd
          a hozzá tartozó limitet, majd töltsd fel a szükséges dokumentumokat.
          Ezt követően továbbítjuk munkatársunk felé a kérelmedet. A folyamat
          állapotát digitálisan követheted a Szerződés igények listában.
        </Typography>
        <FlexiForm
          id={formId}
          FinalFormFormProps={{
            onSubmit: async ({ agreementId, ...values }) => {
              values.modifiedLimit = parseInt(values.modifiedLimit, 10);
              const { data, error } = await post(
                getUrlWithCompanyId(
                  `/agreement/${agreementId}/limit-change-claim`
                ),
                values
              );
              if (data) {
                notifySuccessSave();
                redirect("/app/limit-change-list");
              } else {
                notifyError(selectErrorNotificationMessage(error));
              }
            }
          }}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={6} style={{ paddingRight: "16px" }}>
                  <AgreementSelectField
                    onMenuItemClick={agreement =>
                      form.mutators.setValue("agreement", agreement)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Fields config={limitChangeFields} />
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Paper>
    </PageLayout>
  );
};

export default LimitChangePage;
