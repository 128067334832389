/**
 * @render react
 * @name FlexiDialog
 * @description Flexi dialog
 * @example
 * <FlexiDialog/>
 */

import Dialog from "@material-ui/core/Dialog";
import React from "react";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import {
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const FlexiDialog = ({
  open,
  onClose,
  title,
  children,
  dialogActions,
  imgSrc,
  imgWidth = "80px",
  size = "medium",
  width
}) => {
  let dialogWidth;
  switch (size) {
    case "small":
      dialogWidth = 500;
      break;
    case "medium":
      dialogWidth = 600;
      break;
    case "large":
      dialogWidth = 800;
      break;

    default:
      dialogWidth = size;
      break;
  }

  if (isWidthDown("sm", width)) {
    dialogWidth = "100%";
  }

  return (
    <Dialog
      PaperProps={{
        style: {
          width: dialogWidth,
          maxWidth: dialogWidth,
          padding: "32px 40px"
        }
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle style={{ padding: "0 0 24px 0" }} disableTypography>
        <Typography variant={"h5"}>{title}</Typography>
        {onClose && (
          <IconButton
            onClick={onClose}
            style={{ position: "absolute", top: 24, right: 24, padding: 0 }}
          >
            <CloseIcon color="action" />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent style={{ padding: 0, overflowX: "hidden" }}>
        {imgSrc &&
          !isWidthDown("sm", width) && (
            <Grid container>
              <Grid
                item
                xs={"auto"}
                style={{ paddingRight: "24px", alignSelf: "center" }}
              >
                <img src={imgSrc} style={{ width: imgWidth }} />
              </Grid>
              <Grid item xs style={{ flexGrow: 1 }}>
                {children}
              </Grid>
            </Grid>
          )}
        {imgSrc &&
          isWidthDown("sm", width) && (
            <Grid container direction={"column"}>
              <Grid
                item
                xs={"12"}
                style={{ paddingBottom: "24px", alignSelf: "center" }}
              >
                <img src={imgSrc} style={{ width: "100%" }} />
              </Grid>
              <Grid item xs={12}>
                {children}
              </Grid>
            </Grid>
          )}
        {!imgSrc && (
          <Grid container>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      {dialogActions && (
        <DialogActions disableSpacing={true}>{dialogActions}</DialogActions>
      )}
    </Dialog>
  );
};

FlexiDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  dialogActions: PropTypes.node,
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  size: PropTypes.string,
  imgSrc: PropTypes.string,
  imgWidth: PropTypes.string
};

export default withWidth()(FlexiDialog);
