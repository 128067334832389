import Grid from "@material-ui/core/Grid";
import MUIAppBar from "@material-ui/core/AppBar";
import FlexiCompanyChooser from "../FlexiCompanyChooser";
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButtonWrap } from "./IconButtonWrap";
import { LogoWrap } from "./LogoWrap";
import { Link } from "react-router-dom";
import { LogoImg } from "./LogoImage";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const DesktopLink = withStyles({
  root: {
    display: "block",
    backgroundColor: props =>
      props.appbarbgcolor ? props.appbarbgcolor : "inherit",
    padding: "5px 22px 6px 0",
    height: "50px",
    boxSizing: "border-box"
  }
})(Link);

const AppBar = ({
  beta,
  companyChooserProps,
  isMobile,
  onMenuClick,
  logoSrc,
  homeLogoUrl,
  appbarbgcolor,
  logoProps,
  renderAppBarComponents = () => undefined
}) => {
  const DesktopBar = (
    <Grid container>
      <Grid style={{ padding: "0 10px" }} item />
      <Grid
        item
        xs={12}
        lg={10}
        style={{
          display: "flex",
          width: "90%",
          maxWidth: "90%",
          flexBasis: "inherit",
          alignItems: "center"
        }}
      >
        <DesktopLink to={homeLogoUrl} appbarbgcolor={appbarbgcolor}>
          <div style={{ display: "inline-flex" }}>
            {" "}
            <LogoImg {...logoProps} />
            {beta && (
              <Typography style={{ color: "#fff", fontWeight: 800 }}>
                BETA
              </Typography>
            )}
          </div>
        </DesktopLink>
        <div style={{ flex: "1 1 auto" }} />

        <div
          style={{
            marginRight: 10,
            height: 60,
            display: "flex",
            alignItems: "center"
          }}
        >
          {renderAppBarComponents()}
        </div>
        <div
          style={{
            width: 240,
            height: 50,
            backgroundColor: appbarbgcolor
          }}
        >
          <FlexiCompanyChooser
            appbarbgcolor={appbarbgcolor}
            {...companyChooserProps}
          />
        </div>
        <Typography
          variant={"h6"}
          style={{ position: "absolute", right: "20px", color: "#fff" }}
        >
          HU
        </Typography>
      </Grid>
    </Grid>
  );

  const MobileBar = (
    <Grid container>
      <Toolbar style={{ padding: "0 12px" }}>
        <IconButtonWrap
          color="#fff"
          aria-label="Open drawer"
          onClick={onMenuClick}
          className="drawer-open-button-mobile"
        >
          <MenuIcon />
        </IconButtonWrap>
        <div style={{ flex: "1 1 auto" }} />
        {renderAppBarComponents()}
      </Toolbar>
    </Grid>
  );

  return (
    <MUIAppBar
      position={"fixed"}
      elevation={0}
      style={{
        backgroundColor: appbarbgcolor ? appbarbgcolor : "#424242",
        zIndex: "1199"
      }}
    >
      {isMobile ? MobileBar : DesktopBar}
    </MUIAppBar>
  );
};

export default AppBar;
