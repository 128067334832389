import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React, { Fragment, useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IconButton } from "@material-ui/core";

const KebabMenu = ({ items }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Fragment>
      <IconButton size={"small"} onClick={e => setAnchorEl(e.currentTarget)}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        {items.map(item => {
          return (
            <MenuItem
              {...item.props}
              children={item.mobile.children || item.props.children}
            />
          );
        })}
      </Menu>
    </Fragment>
  );
};

export default KebabMenu;
