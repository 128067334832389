import React, { useEffect, useMemo } from "react";
import { Fields, Field } from "../index";
import Grid from "@material-ui/core/Grid";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FlexiTypography from "../../FlexiTypography/index.js";
import { FormHelperText } from "@material-ui/core";
import VMasker from "vanilla-masker";
import bisnodeLogo from "./bisnode-logo.svg";
import { useField } from "react-final-form";

const makeTransformBisnodeToParty = (easyStructure) => (data) => {
  const companyLegalForm = (data && data.companyType) || "009";
  const city = data && data.city;
  const country = (data && data.country) || "HU";
  const zip = data && data.zipCode;
  const addressLine = data && data.address;
  const partyName = data && data.companyName;
  const taxNumber = data && VMasker.toPattern(data.taxNumber, "99999999-9-99");
  // todo honnan
  const selfEmployed = false;
  const kata = false;
  const email = "";
  const phone = "";

  if (!easyStructure) {
    const address = {
      cityName: city,
      country: country,
      postalZone: zip,
      addressLine: addressLine,
    };

    return {
      partyName: partyName,
      postalAddress: address,
      partyLegalEntity: {
        registrationAddress: address,
        registrationName: partyName,
        companyLegalForm,
        companyId: "",
      },
      contact: {
        telephone: "",
      },
      accounts: [],
      taxNumber: taxNumber,
      selfEmployed: selfEmployed,
      kata: kata,
    };
  } else {
    const easyAddress = {
      country: country,
      postalZone: zip,
      cityName: city,
      addressLine: addressLine,
    };

    return {
      partyName: partyName,
      companyLegalForm: companyLegalForm,
      taxNumber: taxNumber,
      email: email,
      telephone: phone,
      registrationAddress: easyAddress,
      postalAddress: easyAddress,
    };
  }
};

const BisnodeCompanySearch = ({
  name,
  url,
  label,
  easyStructure,
  showSummary,
  onChange,
  onSelect,
  freeSolo = false,
  suppressValueSetting,
  getOptions,
    getInfo,
  icon = <AccountCircleIcon style={{ color: "#646464" }} />,
}) => {
  const defaultGetOptions = (items) => {
    return items
      .map(makeTransformBisnodeToParty(easyStructure))
      .map((party) => ({
        text: party.partyName,
        value: party,
      }));
  };

  const autosuggestConfig = useMemo(
    () => [
      [
        {
          freeSolo,
          suppressValueSetting,
          fieldType: "autosuggest",
          name: `${name}.partyName`,
          setValueTo: name,
          label,
          url,
          onSelect,
          getOptions: getOptions || defaultGetOptions,
          renderOption: ({ value }) => (
            <Grid container>
              <Grid container>
                <Grid item xs={9} sm={10} style={{ whiteSpace: "initial" }}>
                  <Grid container direction="column">
                    {value.partyName}
                    <FormHelperText>
                      {easyStructure
                        ? value.registrationAddress.postalZone
                        : value.partyLegalEntity.registrationAddress
                            .postalZone}{" "}
                      {easyStructure
                        ? value.registrationAddress.cityName
                        : value.partyLegalEntity.registrationAddress.cityName}
                      {", "}
                      {easyStructure
                        ? value.registrationAddress.addressLine
                        : value.partyLegalEntity.registrationAddress
                            .addressLine}
                    </FormHelperText>
                    <FormHelperText>{value.taxNumber}</FormHelperText>
                  </Grid>
                </Grid>
                <Grid item xs={3} sm={2} style={{ textAlign: "right" }}>
                  <img
                    src={bisnodeLogo}
                    alt="bisnode-result"
                    style={{
                      width: "100%",
                      maxWidth: 60,
                      position: "relative",
                    }}
                  />
                </Grid>
              </Grid>
              {getInfo && getInfo(value)}
            </Grid>
          ),
          required: true,
        },
      ],
    ],
    [easyStructure, name]
  );

  const { input } = useField(name);

  useEffect(() => {
    if (onChange) {
      onChange(input.value);
    }
  }, [input.value]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={1} wrap={"nowrap"}>
          {icon && (
            <Grid item style={{ paddingTop: 22, paddingLeft: "2px" }}>
              {icon}
            </Grid>
          )}
          <Grid item style={{ flexGrow: 1 }}>
            <Fields config={autosuggestConfig} />
            {showSummary && (
              <Grid container>
                <Field name={name}>
                  {({ input }) => {
                    if (!easyStructure) {
                      if (
                        input.value &&
                        input.value.partyLegalEntity &&
                        input.value.partyLegalEntity.registrationAddress &&
                        input.value.taxNumber
                      ) {
                        return (
                          <React.Fragment>
                            <Grid
                              item
                              xs={12}
                              container
                              justify={"space-between"}
                              style={{ marginTop: 8 }}
                            >
                              <FlexiTypography bold>Székhely</FlexiTypography>
                              <FlexiTypography>
                                {
                                  input.value.partyLegalEntity
                                    .registrationAddress.postalZone
                                }{" "}
                                {
                                  input.value.partyLegalEntity
                                    .registrationAddress.cityName
                                }
                                {", "}
                                {
                                  input.value.partyLegalEntity
                                    .registrationAddress.addressLine
                                }
                              </FlexiTypography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              container
                              justify={"space-between"}
                              style={{ marginTop: 5 }}
                            >
                              <FlexiTypography bold>Adószám</FlexiTypography>
                              <FlexiTypography>
                                {input.value.taxNumber}
                              </FlexiTypography>
                            </Grid>
                          </React.Fragment>
                        );
                      }
                    } else {
                      if (
                        input.value &&
                        input.value.registrationAddress &&
                        input.value.taxNumber
                      ) {
                        return (
                          <React.Fragment>
                            <Grid
                              item
                              xs={12}
                              container
                              justify={"space-between"}
                              style={{ marginTop: 8 }}
                            >
                              <FlexiTypography bold>Székhely</FlexiTypography>
                              <FlexiTypography>
                                {input.value.registrationAddress.postalZone}{" "}
                                {input.value.registrationAddress.cityName}
                                {", "}
                                {input.value.registrationAddress.addressLine}
                              </FlexiTypography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              container
                              justify={"space-between"}
                              style={{ marginTop: 5 }}
                            >
                              <FlexiTypography bold>Adószám</FlexiTypography>
                              <FlexiTypography>
                                {input.value.taxNumber}
                              </FlexiTypography>
                            </Grid>
                          </React.Fragment>
                        );
                      }
                    }
                  }}
                </Field>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BisnodeCompanySearch;
