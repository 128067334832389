import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

const useStyles = makeStyles(theme => ({
  root: {
    height: 380,
    transform: "translateZ(0px)",
    flexGrow: 1
  },
  speedDial: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  fab: {
    backgroundColor: "#0045de",
    '&:hover': {
      backgroundColor: "#0045de",
    }
  }
}));

export default function OpenableFab({ actions }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Backdrop open={open} style={{ zIndex: 1 }} />
      <SpeedDial
        ariaLabel={"Új művelet gomb"}
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        FabProps={{
          className: classes.fab
        }}
      >
        {actions.map(({ mobile, props }) => (
          <SpeedDialAction
            title={mobile.tooltip}
            key={mobile.tooltip}
            icon={mobile.icon}
            tooltipTitle={mobile.tooltip}
            tooltipOpen
            onClick={props.onClick}
          />
        ))}
      </SpeedDial>
    </div>
  );
}
