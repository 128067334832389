import { TextField } from "final-form-material-ui";
import React, { useCallback } from "react";
import BaseField from "./BaseField";
import msk from "msk";

const SimpleTextField = ({ maskPattern, ...props }) => {
  const mask = useCallback(value => msk.fit(value, maskPattern), [maskPattern]);

  return (
    <BaseField
      component={TextField}
      parse={maskPattern ? mask : undefined}
      {...props}
    />
  );
};

export default SimpleTextField;
